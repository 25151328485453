<template>
  <AppForm v-bind="$props" v-on="$listeners">
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="item.payor_id"
          :error-messages="errorMessages.payor_id"
          cache-items
          label="Payor Name"
          :persistent-placeholder="true"
          :items="items"
          :loading="loading"
          @update:search-input="searchApplicant"
          :clearable="!$props.readonly"
          hide-no-data
          hide-details="auto"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="item.invoice_no"
          :error-messages="errorMessages.invoice_no"
          label="Invoice No"
          hide-details="auto"
          dense
          outlined
          clearable
          reverse
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="item.amount"
          :error-messages="errorMessages.amount"
          label="Amount"
          suffix="$"
          hide-details="auto"
          dense
          outlined
          clearable
          reverse
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-subheader class="text-caption">Service Product</v-subheader>
    </v-row>

    <v-row class="mt-n4">
      <v-col>
        <v-list>
          <v-list-item-group multiple v-model="item.fees">
            <v-list-item
              v-for="(fee, index) in serviceFees"
              active-class="primary--text text--accent-4"
              :key="index"
              :value="fee.id"
            >
              <template #default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"> </v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-row>
                    <v-col>
                      <v-subheader class="justify-space-between px-0"
                        >{{ fee.name.replace(/^AximA/i, "") }}
                        <span class="font-weight-bold">{{
                          formatAmount(fee.amount)
                        }}</span>
                      </v-subheader>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-textarea
          v-model="item.description"
          :error-messages="errorMessages.description"
          label="Description"
          :persistent-placeholder="true"
          counter="255"
          hide-details="auto"
          no-resize
          rows="2"
        >
        </v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="item.security_deposit_first_month"
          label="First Month"
          dense
          outlined
          persistent-placeholder
          suffix="$"
          hide-details="auto"
          clearable
          reverse
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          v-model="item.security_deposit_last_month"
          label="Last Month"
          dense
          outlined
          persistent-placeholder
          suffix="$"
          clearable
          hide-details="auto"
          reverse
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          v-model="item.type"
          :error-messages="errorMessages.type"
          :items="types"
          label="Payment Type"
          :persistent-placeholder="true"
          hide-details="auto"
        >
        </v-select>
      </v-col>

      <v-col cols="12" sm="6">
        <v-text-field
          v-model="item.paid_datetime"
          type="datetime-local"
          :error-messages="errorMessages.paid_datetime"
          label="Paid Date & Time"
          :persistent-placeholder="true"
          hide-details="auto"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </AppForm>
</template>

<script>
import AppForm from "../AppForm"
import ServiceFee from "../../api/serviceFee"

export default {
  name: "PaymentV2Form",
  extends: AppForm,
  components: {
    AppForm,
  },

  props: {
    errorMessages: Object,
  },

  data() {
    return {
      payFeeAmounts: [],
      serviceFees: [],
      repaymentOptionItems: ["10 months", "5 months"],
      loading: false,
      types: [
        {
          text: "Paypal",
          value: "Paypal",
        },
        {
          text: "Bank Transfer",
          value: "Bank Transfer",
        },
      ],
    }
  },

  computed: {
    items() {
      return this.$store.getters["applicants/getAll"].map(({ profile }) => {
        return {
          text: this.getProfileFullname(profile),
          value: profile.id,
        }
      })
    },
  },

  methods: {
    getProfileFullname(profile) {
      const { first_name, last_name } = profile
      return `${first_name} ${last_name}`
    },

    formatAmount(value) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      })

      return formatter.format(value)
    },

    searchApplicant(text) {
      const currentSelected = this.items.find(
        ({ value }) => value === this.item.profile_id
      )

      if (text && text === currentSelected?.text) return

      this.loading = true
      this.$store.dispatch("applicants/searchByName", text).finally(() => {
        this.loading = false
      })
    },
  },

  created() {
    ServiceFee.search().then((response) => {
      if (response.data) {
        this.serviceFees = response.data.items.map((item) => ({
          text: `${item.name} ($${item.amount})`,
          ...item,
        }))
      }
    })
  },
}
</script>
