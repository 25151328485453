<template>
    <div id="test" style="display: block; vertical-align: middle; text-align: center">
              <v-img
                :src="require('@/assets/Axima_logo-2048x843.png')"
                height="140"
                class="mb-6"
                transition="slide-y-transition"
                contain
                />
  <span class="display-2 blue--text">
  Oops! You don't have the authority to access this page.</span>
    <p>
        <a href="/">
            <v-icon color="green" x-large>mdi-keyboard-backspace</v-icon>
            go home
            </a>
    </p>
  </div>
</template>

<script>
export default {
    name: 'Unauthorized'
}
</script>
