import Api from './api'

const ENDPOINT = 'user'

export const key = 'id' 

export default {
    ENDPOINT,
    key,
    getPreferences() {
        return Api.get(`${ENDPOINT}/preferences`)
    },

    savePreferences(params) {
        return Api.patch(`${ENDPOINT}/preferences`, params)
    },

    me() {
        return Api.get(`${ENDPOINT}/me`)
    }
}
