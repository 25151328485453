import { actions, mapMethods } from './api'

const ENDPOINT = 'job-offers'

export const key = 'id'

export default {
    ...mapMethods(ENDPOINT, [
        'delete',
        'update',
        'create',
        'view'
    ]),
    search(params) {
        return actions(ENDPOINT).search({
            ...params,
            expand: 'job.employer,applicant',

        })
    },
}
