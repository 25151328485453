<template>
  <InitialInterview v-bind="$props" v-on="$listeners">
    <template #table.item.action="slotProps">
      <ProgressBackwardItemAction v-bind="slotProps" v-on="slotProps.on" />

      <ProgressForwardItemAction v-bind="slotProps" v-on="slotProps.on" />
    </template>
  </InitialInterview>
</template>

<script>
import InitialInterview from "./InitialInterview"
import moment from "moment"

export default {
  name: "FinalInterview",
  extends: InitialInterview,

  components: {
    InitialInterview,
  },

  props: {
    resourcePath: {
      type: String,
      default: "finalInterviewees",
    },
    title: {
      type: String,
      default: "Final Interviewees",
    },
    subject: {
      type: String,
      default: "Final Interviewee",
    },
    headers: {
      type: Array,
      default: function () {
        return [
          {
            text: "Id",
            value: this.$config("applicant.id"),
          },
          {
            text: "Name",
            value: "name",
            customValue: function (item) {
              return `${item.profile.last_name}, ${item.profile.first_name}`
            },
          },
          {
            text: "Email",
            value: "email",
          },
          {
            text: "Date Registered",
            value: "created_at",
            customValue: function (item) {
              return moment.unix(item.created_at).format("MMM. D, YYYY")
            },
          },
          /*{
            text: "Interview Status",
            value: "interview_status",
            customValue: function (item) {
              if (item.interviews.length > 0) return k
              return "No interviews"
            },
          },*/
          {
            text: "Desired Position",
            value: "profile.application.desired_position",
            align: "center",
          },
          {
            text: "Referrer",
            value: "profile.referrer",
            align: "center",
          },
          {
            text: "Submission(s)",
            value: "submissions",
            align: "center",
            filterable: false,
          },
          {
            text: "Status",
            value: "profile.status",
            align: "center",
          },
          {
            text: "Action",
            value: "action",
            align: "center",
            filterable: false,
          },
          {
            text: "Category",
            value: "category",
            align: "center",
          },
        ]
      },
    },
  },
}
</script>
