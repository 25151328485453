import User from '../../api/user'

export const state = {
    data: {}
}

export const getters = {
    data: state => state.data
}

export const actions = {
    me({ commit }) {
        return User.me().then(response => {
            if (response.status === 200) {
                commit('ME', response.data.results)
            }

            return response
        })
    }
}

export const mutations = {
    ME(state, payload) {
        state.data = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
