<template>
  <!--<v-row>
    <v-col>
      <InterviewDialog 
        v-model="showInterviewDialog" 
        :item="interviewItem"
        @click:save="onSaveInterview"
        close-on-save
        >
        <template #title>
          Schedule Interview for {{ intervieweeFullname }}
        </template>
      </InterviewDialog>

      <ApplicantDialog ref="applicantDetailsDialog"/>

      <IntervieweeTable
        ref="intervieweeTable"
        @view="onIntervieweeView"
        @toggleApproval="onApplicantItemApproval"
        @click:add-interview="onAddInterview"
      >
        <template #expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-container 
              fluid>
              <v-row
                  class="justify-center"
                >
                <v-col
                  v-for="interview, index in item.interviews"
                  :key="index"
                  cols="12"
                  md="6">
                  <InterviewCard
                    :item="interview"
                    @click:edit="onEditInterviewCard"
                    @click:delete="onDeleteInterviewCard"
                    />
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </IntervieweeTable>
    </v-col>
  </v-row> -->

  <Registrants v-bind="$props" v-on="$listeners"> </Registrants>
</template>

<script>
/* import ApplicantDialog from './ApplicantDialog.vue' */
/* import InterviewDialog from './InterviewDialog.vue' */
/* import IntervieweeTable from './IntervieweeTable.vue' */
/* import InterviewCard from './InterviewCard.vue' */

import Registrants from "./Registrants"

import moment from "moment"

export default {
  name: "InitialInterview",

  components: {
    Registrants,
  },

  data() {
    return {
      showInterviewDialog: false,
      loading: false,
      interviewItem: {},
      interviewee: null,
    }
  },

  props: {
    resourcePath: {
      type: String,
      default: "interviewees",
    },
    title: {
      type: String,
      default: "Initial Interviewees",
    },
    subject: {
      type: String,
      default: "Initial Interviewee",
    },
    refs: Object,
    notification: Object,
    headers: Array,
  },

  computed: {
    intervieweeFullname() {
      const name =
        this.$config("applicant.firstName", this.interviewee) +
        " " +
        this.$config("applicant.lastName", this.interviewee)
      return name
    },
  },

  methods: {
    onEditInterviewCard(item) {
      this.interviewItem = JSON.parse(JSON.stringify(item))
      this.showInterviewDialog = true
    },

    onDeleteInterviewCard(item) {
      // TODO: Add confirmation prompt
      const index = this.interviewee.interviews.findIndex(
        (interview) => interview.id === item.id
      )

      this.interviewee.interviews.splice(index, 1)
    },

    getApplicantDetails(item) {
      const data = {}

      for (let key in this.$config("applicant")) {
        data[key] = this.$config(`applicant.${key}`, item)
      }

      data["createdAt"] = moment
        .unix(this.$config("applicant.createdAt", item))
        .format("YYYY-MM-DD")
      data["updatedAt"] = moment
        .unix(this.$config("applicant.updatedAt", item))
        .format("YYYY-MM-DD")

      return data
    },

    onIntervieweeView(item) {
      const id = item.id

      this.$axios
        .get(`registrant/${id}`, {
          params: {
            expand:
              "profile.rating,profile.education,profile.ielts,profile.application,profile.address",
          },
        })
        .then(({ data }) => {
          const options = {
            async onAffirm() {
              //            try {
              //              return await this.axios.put('w')
              //            }
              //            catch(e) {
              //              return true
              //            }
            },
            icon: "mdi-file-eye-outline",
            readonly: true,
          }

          this.$refs.applicantDetailsDialog.open(
            this.getApplicantDetails(data),
            options
          )
          console.log(data)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    onApplicantItemApproval(data) {
      const currentStatus = this.$config("applicant.status", data)
      const status =
        currentStatus === "For Final Interview"
          ? "Approved"
          : "For Final Interview"

      const options = {
        message: `Change status to "${status}"?`,
        onAffirm() {
          return this.$axios.put(`registrant/${data.id}/status`, {
            name: status,
          })
        },
      }

      this.refs.confirmDialog
        .open(options)
        .then((response) => {
          if (response.status === 204) {
            // NOTE: should we use this.$config to set this?
            data.profile.status = status
            if (status === "Approved") {
              const index = this.$refs.intervieweeTable.items.indexOf(data)
              this.$refs.intervieweeTable.items.splice(index, 1)
            }
            this.$emit("notify", "Interviewee for final interview")
          }
        })
        .catch((error) => {
          console.log(error)
          this.$emit("notify", this.$strings.serverError, false)
        })
    },

    // NOTE: man these method names are ambiguous
    onAddInterview(item) {
      this.interviewee = item
      this.interviewItem = {}
      this.showInterviewDialog = true
    },

    onSaveInterview(details) {
      if (
        this.interviewee.interviews === null ||
        typeof this.interviewee.interviews === "undefined"
      ) {
        this.$set(this.interviewee, "interviews", [])
      }

      // TODO: Post request to /api/interviews
      if (details.id) {
        const index = this.interviewee.interviews.findIndex(
          (interview) => interview.id === details.id
        )

        this.$set(this.interviewee.interviews, index, details)
      } else {
        // TEMP: Remove this
        details.id = Math.floor(Math.random() * 20)
        this.interviewee.interviews.push(details)
      }

      this.interviewItem = {}
    },

    onCloseInterview() {
      console.log("asd")
    },
  },
}
</script>
