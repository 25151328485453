var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AppContent',_vm._g(_vm._b({attrs:{"item-key":"name","form-dialog-props":{ maxWidth: 700 },"table-props":{
    headers: _vm.headers,
  }},on:{"error-response":function($event){_vm.errorMessages = $event}},scopedSlots:_vm._u([{key:"table",fn:function({ attrs, on }){return [_c('AppTable',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"item.action",fn:function(slotProps){return [_vm._t("table.item.action",function(){return [_c('DeleteItemAction',_vm._g(_vm._b({},'DeleteItemAction',slotProps,false),slotProps.on))]},null,{
            ...slotProps,
            on: {
              ...slotProps.on,
              ..._vm.tableItemSlotProps.on,
            },
          })]}}],null,true)},'AppTable',{ ...attrs, ..._vm.$props },false),on))]}},{key:"formDialog",fn:function(formDialogSlotProps){return [_c('AppFormDialog',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"form",fn:function(slotProps){return [_c('LoanEligibleForm',_vm._b({},'LoanEligibleForm',slotProps,false))]}}],null,true)},'AppFormDialog',formDialogSlotProps.attrs,false),formDialogSlotProps.on))]}}])},'AppContent',_vm.$props,false),_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }