import Api, { mapMethods } from './api'

const ENDPOINT = 'profile-notes' // TEMP: 

export const key = 'id' 

export default {
    ENDPOINT,
    key,
    ...mapMethods(ENDPOINT, [
        'delete',
        'create',
    ]),
    search(payload) {
        // TODO: Refactor
        return Api.get(`all-applicants/${payload.id}/notes`)
    }
}
