<template>
  <AppForm
    v-bind="$props"
    v-on="$listeners"
  >
    <v-row>
      <v-col>
        <v-text-field
          :value="item.profile?.ielts?.test_type"
          label="Test Type"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6">
        <v-text-field
          :value="item.profile?.ielts?.test_format"
          label="Test Type Format"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        sm="6">
        <v-text-field
          :value="item.profile?.ielts?.test_date"
          label="Test Type Date"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          :value="item.profile?.ielts?.reference_id"
          label="Reference ID"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
      <v-spacer class="d-none d-sm-flex"/>
    </v-row>

    <v-row>
      <v-col>
        <span class="title">Test Results</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="3">
        <v-text-field
          :value="item.profile?.ielts?.listening_result"
          label="Listening"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        sm="3">
        <v-text-field
          :value="item.profile?.ielts?.reading_result"
          label="Reading"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        sm="3">
        <v-text-field
          :value="item.profile?.ielts?.writing_result"
          label="Writing"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        sm="3">
        <v-text-field
          :value="item.profile?.ielts?.speaking_result"
          label="Speaking"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>
  </AppForm>
</template>

<script>
import AppForm from '../AppForm'

export default {
  name: 'EducationForm',
  extends: AppForm,
  components: {
    AppForm
  }
}
</script>

