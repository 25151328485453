<template>
  <AppContent
    v-bind="$props"
    v-on="$listeners"
    item-key="name"
    :form-dialog-props="{ maxWidth: 700 }"
    :table-props="{ 
      headers
      }"
    @error-response="errorMessages = $event"
    >

    <template #formDialog="formDialogSlotProps">
        <AppFormDialog
            v-bind="formDialogSlotProps.attrs"
            v-on="formDialogSlotProps.on"
            @update:mode="onAppFormDialogModeChanged">

          <template #form="slotProps">
            <v-tabs
              v-model="tab"
              v-show="tabItems.length > 1"
              active-class="white black--text"
              fixed-tabs
            >
              <v-tab
                v-for="tab in tabItems"
                :key="tab.name"
              >
                {{ tab.name }}
              </v-tab>
            </v-tabs>

            <v-tabs-items 
               v-model="tab"
            >

              <v-tab-item v-for="tab in tabItems"
                :key="tab.name">
                <v-card
                  flat>
                  <v-card-text>
                    <component 
                      :is="tab.component"
                      v-bind="slotProps"
                      :error-messages="errorMessages"
                    >
                    </component>
                  </v-card-text>
                </v-card>
              </v-tab-item>

            </v-tabs-items>
          </template>

        </AppFormDialog>
    </template>
  </AppContent>
</template>

<script>
import AppContent from './AppContent'
import AppFormDialog, { Mode } from './AppFormDialog'
import EmployerForm from './employer/EmployerForm'
/* import LoginDetailsForm from './employer/LoginDetailsForm' */
/* import JobBankForm from './employer/JobBankForm' */
import JobBankTable from './employer/JobBankTable'
/* import ReportsTable from './employer/ReportsTable' */

export default {
  name: 'JobOffers',

  components: {
    AppContent,
    AppFormDialog
  },

  props: {
    resourcePath: {
      type: String,
      default: 'jobOffers'
    },
    title: {
      type: String,
      default: 'Job Offers'
    },
    subject: {
      type: String,
      default: 'Employer'
    },
    headers: {
      type: Array,
      default: function() {
        return [
          {
            text: 'Id',
            value: this.$config('applicant.id'),
          },
            {
                text: 'Job Title',
                value: 'job.title'
            },
            {
                text: 'Date Offered',
                value: 'created_at'
            },
            {
                text: 'Date Accepted',
                value: 'acceptance_date'
            },
          {
            text: 'Status',
            value: 'status',
            align: 'center'
          },
          {
            text: 'Action',
            value: 'action',
            align: 'center',
            sortable: false,
            filterable: false
          }
        ]
      }
    }
  },

  data() {
    return {
      tab: null,
        errorMessages: {},
    items: [
        {
          name: 'Details',
          component: EmployerForm
        },
        /* { */
        /*   name: 'Login', */
        /*   component: LoginDetailsForm */
        /* }, */
        /* { */
        /*   name: 'Job Bank Details', */
        /*   component: JobBankForm, */
        /* }, */
        {
          name: 'Jobs',
          component: JobBankTable,
          edit: false,
          create: false,
        },
        /* { */
        /*   name: 'Reports', */
        /*   component: ReportsTable */
        /* }, */
      ],
      mode: 0,
    }
  },

  methods: {
      onAppFormDialogModeChanged(mode) {
          this.mode = mode
      }
  },

  computed: {
      tabItems() {

         return this.items.filter(item => {
             switch (this.mode) {
                 case Mode.VIEW:
                    return typeof item.view === 'undefined'
                     || item.view
                 case Mode.EDIT:
                    return typeof item.edit === 'undefined'
                     || item.edit
                 case Mode.CREATE:
                    return typeof item.create === 'undefined'
                     || item.create
                default:
                    
             }

             return false
        })
      }
  }
}
</script>
