import { mapMethods } from './api'

const ENDPOINT = 'user-accounts'

export const key = 'id' 

export default {
    ...mapMethods(ENDPOINT, [
        'search',
        'delete',
        'update',
        'create',
        'view'
    ]),
    // getPermissions(id) {
    //     return Api.get(`${ENDPOINT}/${id}/permissions`)
    // }
}
