import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../Login'
import Home from '../components/Home'
// import HomeBase from '../components/HomeBase'
import Dashboard from '../components/Dashboard'
import Users from '../components/Users'
import Roles from '../components/Roles'
import Permissions from '../components/Permissions'
import Applicants from '../components/Applicants'
import Registrants from '../components/Registrants'
import LoanRequest from '../components/LoanRequest'
import LoanEligible from '../components/LoanEligible'
import LoanAgreement from '../components/LoanAgreement'
import Employers from '../components/Employers'
import Interviewers from '../components/Interviewers'
import InitialInterview from '../components/InitialInterview'
import FinalInterview from '../components/FinalInterview'
import Approved from '../components/Approved'
import Queue from '../components/Queue'
import Processed from '../components/Processed'
import LegalProcess from '../components/LegalProcess'
import ArchivedApplicants from '../components/ArchivedApplicants'
import DeniedApplicants from '../components/DeniedApplicants'
import DomesticApplicants from '../components/DomesticApplicants'
import Opportunities from '../components/Opportunities'
import Interview from '../components/Interview'
import Meetings from '../components/Meetings'
import Jobs from '../components/Jobs'
import JobOffer from '../components/JobOffer'
import Referral from '../components/Referral'
// import Payments from '../components/Payments'
import PaymentsV2 from '../components/PaymentsV2'
import Reports from '../components/Reports'
// import PageNotFound from '../components/PageNotFound'
import Unauthorized from '../components/Unauthorized'

Vue.use(VueRouter)

const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: 'history',
    routes: [
        {
            path: '/', 
            component: Login,
            name: 'Login',
        },
        {
            path: '/home', 
            component: Home,
            name: 'Home',
            redirect: '/dashboard',
            children: [
                // {
                //     path: '/base',
                //     component: HomeBase,
                //     name: 'HomeBase',
                // },
                {
                    path: '/dashboard',
                    component: Dashboard,
                    name: 'Dashboard',
                    // meta: { can: 'viewDashboard' }
                },
                {
                    path: '/account-management/users',
                    component: Users,
                    name: 'Users',
                    meta: { can: 'viewAccountManagement' }
                },
                {
                    path: '/account-management/roles',
                    component: Roles,
                    name: 'Roles',
                    meta: { can: 'viewRole' }
                },
                {
                    path: '/account-management/permissions',
                    component: Permissions,
                    name: 'Permissions',
                    meta: { can: 'viewPermission' }
                },
                {
                    path: '/applicants/all',
                    component: Applicants,
                    name: 'Applicants',
                    props: true,
                    meta: { can: 'viewApplicant' }
                },
                {
                    path: '/applicants/registrants',
                    component: Registrants,
                    name: 'Registrants',
                    meta: { can: 'viewRegistrant' }
                },
                {
                    path: '/applicants/initial-interview',
                    component: InitialInterview,
                    name: 'InitialInterview',
                    meta: { can: 'viewInitialInterview' }
                },
                {
                    path: '/applicants/final-interview',
                    component: FinalInterview,
                    name: 'FinalInterview',
                    meta: { can: 'viewFinalInterview' }
                },
                {
                    path: '/applicants/approved',
                    component: Approved,
                    name: 'Approved',
                    meta: { can: 'viewApproved' }
                },
                {
                    path: '/applicants/processed',
                    component: Processed,
                    name: 'Processed',
                    meta: { can: 'viewProcessed' }
                },
                {
                    path: '/applicants/legal-process',
                    component: LegalProcess,
                    name: 'LegalProcess',
                    meta: { can: 'viewLegalProcess' }
                },
                {
                    path: '/applicants/archived',
                    component: ArchivedApplicants,
                    name: 'ArchivedApplicants',
                    meta: { can: 'viewArchivedApplicants' }
                },
                {
                    path: '/applicants/denied',
                    component: DeniedApplicants,
                    name: 'DeniedApplicants',
                    meta: { can: 'viewArchivedApplicants' }
                },
                {
                    path: '/applicants/domestic',
                    component: DomesticApplicants,
                    name: 'DomesticApplicants',
                    meta: { can: 'viewDomesticApplicants' }
                },
                {
                    path: '/opportunities',
                    component: Opportunities,
                    name: 'OpportunitiesApplicants',
                    meta: { can: 'viewOpportunitiesApplicants' }
                },
                {
                    path: '/job-offers',
                    component: JobOffer,
                    name: 'JobOffer',
                    meta: { can: 'viewJob' }
                },
                {
                    path: '/jobs',
                    component: Jobs,
                    name: 'Jobs',
                    meta: { can: 'viewJob' }
                },
                {
                    path: '/company/loan-eligible',
                    component: LoanEligible,
                    name: 'Loan Eligible',
                    meta: { can: 'viewEmployer' }
                },
                {
                    path: '/company/loan-requests',
                    component: LoanRequest,
                    name: 'Loan Requests',
                    meta: { can: 'viewEmployer' }
                },
                {
                    path: '/company/loan-agreements',
                    component: LoanAgreement,
                    name: 'Loan Agreements',
                    meta: { can: 'viewEmployer' }
                },
                {
                    path: '/company/employers',
                    component: Employers,
                    name: 'Employers',
                    meta: { can: 'viewEmployer' }
                },
                {
                    path: '/interviewers',
                    component: Interviewers,
                    name: 'Interviewers',
                    meta: { can: 'viewInterviewer' }
                },
                {
                    path: '/my-interviews',
                    component: Interview,
                    name: 'Interview',
                    meta: { can: 'viewInterview' }
                },
                {
                    path: '/meetings',
                    component: Meetings,
                    name: 'Meetings',
                    meta: { can: 'viewMeeting' }
                },
                {
                    path: '/queue',
                    component: Queue,
                    name: 'Queue',
                    meta: { can: 'viewQueue' }
                },
                {
                    path: '/referral',
                    component: Referral,
                    name: 'Referral',
                    meta: { can: 'viewReferral' }
                },
                // {
                //     path: '/payments',
                //     component: Payments,
                //     name: 'Payments',
                //     meta: { can: 'viewPayment' }
                // },
                {
                    path: '/payments',
                    component: PaymentsV2,
                    name: 'Payments',
                    meta: { can: 'viewPayment' }
                },
                {
                    path: '/reports',
                    component: Reports,
                    name: 'Reports',
                    meta: { can: 'viewReport' }
                }
            ]
        },
        {
            path: '/unauthorized', 
            component: Unauthorized,
            name: 'Unauthorized',
        },
        // {
        //     path: '*', 
        //     component: PageNotFound,
        //     name: 'PageNotFound',
        // },
        {
            path: '*',
            redirect: '/',
        }
    ]
})

export default router
