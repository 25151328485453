import Applicants from './applicants'

const status = 'new'

export const state = {
    ...Applicants.state,
}

export const getters = {
    ...Applicants.getters,
    getAll: state => state.all.filter(registrant => registrant.profile?.status.toLowerCase() === status)
}

export const actions = {
    ...Applicants.actions,

    fetchPagination(context, payload) {
        return Applicants.actions['fetchPagination'](context, {
            ...payload,
            'filter[and][][status][eq]': status
        })
    }
}

export default {
    ...Applicants,
    state,
    actions,
    getters
}
