<template>
  <AppForm
    v-bind="$props"
    v-on="$listeners"
  >
    <v-row>
      <v-col>
        <v-text-field
          :value="item.profile?.application?.how_did_you_heard_about_us"
          label="How did you heard about us?"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          :value="item.profile?.application?.desired_position"
          label="Desired position"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="4">
        <v-text-field
          :value="item.profile?.application?.available_start_date"
          label="Available Start Date"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>
  </AppForm>
</template>

<script>
import AppForm from '../AppForm'

export default {
  name: 'ApplicationForm',
  extends: AppForm,
  components: {
    AppForm
  }
}
</script>

