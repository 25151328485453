<template>
  <AppForm v-bind="$props" v-on="$listeners">
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="item.profile_id"
          :error-messages="errorMessages.profile_id"
          cache-items
          label="Name"
          :persistent-placeholder="true"
          :items="items"
          :loading="loading"
          @update:search-input="searchApplicant"
          :clearable="!$props.readonly"
          hide-no-data
          hide-details="auto"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <v-text-field
          v-model="item.currency"
          :error-messages="errorMessages.currency"
          label="Currency"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="4">
        <v-text-field
          v-model="item.interest"
          :error-messages="errorMessages.interest"
          label="Interest"
          hide-details="auto"
          :persistent-placeholder="true"
          append-icon="mdi-percent-outline"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="item.amount_proposed"
          :error-messages="errorMessages.amount_proposed"
          label="Amount proposed"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="item.amount_approved"
          :error-messages="errorMessages.amount_approved"
          label="Amount approved"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="item.repayment_option"
          :items="repaymentOptionItems"
          :error-messages="errorMessages.repayment_option"
          label="Repayment Option"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="item.repayment_option_approved"
          :items="repaymentOptionItems"
          :error-messages="errorMessages.repayment_option_approved"
          label="Repayment Option Approved"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="5">
        <v-select
          v-model="item.status"
          :error-messages="errorMessages.status"
          :items="['approved', 'denied', 'pending']"
          label="Status"
          :persistent-placeholder="true"
          hide-details="auto"
        >
        </v-select>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="item.date_lender_signed"
          :error-messages="errorMessages.date_lender_signed"
          label="Date lender signed (yyyy-mm-dd)"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="item.date_loanee_signed"
          :error-messages="errorMessages.date_loanee_signed"
          label="Date loanee signed (yyyy-mm-dd)"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </AppForm>
</template>

<script>
import AppForm from "../AppForm"

export default {
  name: "LoanAgreementForm",
  extends: AppForm,
  components: {
    AppForm,
  },

  data() {
    return {
      repaymentOptionItems: ["10 months", "5 months"],
      loading: false,
    }
  },

  computed: {
    items() {
      return this.$store.getters["loanEligibles/getAll"].map(({ profile }) => {
        return {
          text: this.getProfileFullname(profile),
          value: profile.id,
        }
      })
    },
  },

  methods: {
    getProfileFullname(profile) {
      const { first_name, last_name } = profile
      return `${first_name} ${last_name}`
    },

    searchApplicant(text) {
      const currentSelected = this.items.find(
        ({ value }) => value === this.item.profile_id
      )

      if (text && text === currentSelected?.text) return

      this.loading = true
      this.$store.dispatch("loanEligibles/searchByName", text).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
