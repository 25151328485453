<template>
  <AppForm
    v-bind="$props"
    v-on="$listeners"
  >

    <v-row>

      <v-col
        cols="12"
      >
        <v-text-field
          v-model="item.name"
          :error-messages="errorMessages.name"
          :readonly="readonly"
          label="Role name"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>

      </v-col>


    </v-row>

    <v-row>
      <v-col
        cols="12"
      >
        <v-textarea
          v-model="item.description"
          :error-messages="errorMessages.description"
          label="Description"
          hide-details="auto"
          :persistent-placeholder="true"
          rows="2"
          >
        </v-textarea>
      </v-col>

    </v-row>

    <v-row>
        <v-col>
            <label class="text-caption">Permissions</label>
            <v-row no-gutters>
                <v-col
                    v-for="permission in permissions"
                    :key="permission.value"
                cols="6">
                    <v-checkbox
                        v-model="item.permissions"
                        :label="permission.text"
                        :value="permission.value"
                        multiple
                        hide-details="auto"
                    >
                    </v-checkbox>
                </v-col>
            </v-row>
        </v-col>
    </v-row>

  </AppForm>

</template>

<script>
import AppForm from './AppForm'

export default {
  name: 'RoleForm',
  extends: AppForm,
  components: {
    AppForm
  },

  data() {
    return {
    }
  },

  computed: {
      permissions() {
          return this.$store.getters['permissions/getAll'].map(permission => ({
            text: permission.name,
            value: permission.name
          }))
      }
  },
  mounted() {
      this.$store.dispatch('permissions/fetchPagination', { 'per-page': 50})
  }


}
</script>
