import '@mdi/font/css/materialdesignicons.css'

/*
 * Imitates Vuetify's page default font weight
 * See: (https://www.williamdasilva.com/blog/til-2021-01-18)
 */
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'

import Vue from 'vue'
import Vuetify from 'vuetify'


// Fixes missing style on button groups in production.
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
})
