import UserAccount, { key } from '../../api/userAccount'
import { AppString } from '../../strings'
import { Pagination } from '../common.js'

const pagination  = new Pagination(UserAccount)

const state = {
    ...pagination.state,
}

const getters = {
    ...pagination.getters
}

const actions = {
    ...pagination.actions,

    update({ commit, dispatch }, payload) {
        return UserAccount.update(payload[key], payload.params).then(response => {
            if (response.status === 200) {
                commit('UPDATE_USER_ACCOUNT', {
                    [key]: payload[key],
                    role: response.data
                })
                dispatch('notifications/addSuccessNotification', AppString.userAccountUpdated, { root: true })
            }

            return response
        })
    },

    save({ commit, dispatch }, payload) {
        return UserAccount.create(payload).then(response => {
            
            if (response.status === 201) {
                commit('ADD_USER_ACCOUNT', response.data)
                dispatch('notifications/addSuccessNotification', AppString.userAccountCreated, { root: true })
            }

            return response
        })
    },

    delete({ commit, dispatch }, role) {
        return UserAccount.delete(role[key]).then(response => {
            if (response.status === 204) {
                commit('DELETE_USER_ACCOUNT', role)
                dispatch('notifications/addSuccessNotification', AppString.userAccountDeleted, { root: true })
            }

            return response
        })
    },

    view(_, userAccount) {
        // REVIEW: should this have have a VIEW mutation?
        return UserAccount.view(userAccount[key]).then(response => {
            if (response.status === 200) {
                return response.data
            }
        })
    }
}

const mutations = {
    SET_PAGINATION(state, pagination)  {
        state.pagination = pagination
    },

    SET_ALL(state, items) { state.all = items },

    ADD_USER_ACCOUNT(state, role) { state.all.unshift(role) },

    UPDATE_USER_ACCOUNT: (state, payload) => {
        const role = state.all.find(role => 
            role[key] === payload[key]
        )

        if (role)
            Object.assign(role, payload.role)
    },

    DELETE_USER_ACCOUNT: (state, role) => {
        const index = state.all.findIndex(item => item === role)

        if (index >= 0)
            state.all.splice(index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
