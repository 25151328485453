<template>
  <AppContent
    :resourcePath="resourcePath"
    :headers="headers"
    :title="title"
    :subject="subject"
    :table-props="{ downloadable: false, headers }"
    >

      <template #form="slotProps">
        <UserAccountForm
          v-bind="slotProps"
        /> 
      </template>

      <template #table="{ attrs, on }">
        <AppTable 
          v-bind="attrs"
          v-on="on"
        >
          <template #item.role="{ value }">
            <v-chip 
              v-if="value"
              color="blue"
              class="white--text"
            >
            {{ value }}
            </v-chip>
          </template>
        </AppTable>
      </template> 
  </AppContent>
</template>

<script>
import AppContent from './AppContent'
import AppTable from './AppTable'
import UserAccountForm from './UserAccountForm'

export default {
  name: 'Users',
  components: {
    AppContent,
    AppTable,
    UserAccountForm
  },
  data() {
    return {
      resourcePath: 'userAccounts',
      title: 'User Accounts',
      subject: 'User Account',
      headers: [
        {
          text: 'Name',
          value: 'name',
          customValue: function (item) {
            return `${item.last_name}, ${item.first_name}`
          },
          filterable: false
        },
        {
          text: 'Username',
          value: 'username',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Role',
          value: 'role',
          align: 'center'
        },
        {
          text: 'Action',
          value: 'action',
          align: 'center',
          filterable: false
        }
      ],
    }
  }
}
</script>
