import { mapMethods } from './api'

const ENDPOINT = 'service-fees'

export const key = 'id' 

export default {
    ...mapMethods(ENDPOINT, [
        'search',
    ])
}
