import JobOffer, { key } from '../../api/jobOffer'
import { AppString } from '../../strings'
import { Pagination } from '../common.js'

const pagination  = new Pagination(JobOffer)

const state = {
    ...pagination.state,
}

const getters = {
    ...pagination.getters
}

const actions = {
    ...pagination.actions,

    update({ commit, dispatch }, payload) {
        return JobOffer.update(payload[key], payload.params).then(response => {
            if (response.status === 200) {
                commit('UPDATE_JOB_OFFER', {
                    [key]: payload[key],
                    jobOffer: response.data
                })
                dispatch('notifications/addSuccessNotification', AppString.jobOfferUpdated, { root: true })
            }

            return response
        })
    },

    save({ commit, dispatch }, payload) {
        return JobOffer.create(payload).then(response => {
            
            if (response.status === 201) {
                commit('ADD_JOB_OFFER', response.data)
                dispatch('notifications/addSuccessNotification', AppString.jobOfferCreated, { root: true })
            }

            return response
        })
    },

    delete({ commit, dispatch }, jobOffer) {
        return JobOffer.delete(jobOffer[key]).then(response => {
            if (response.status === 204) {
                commit('DELETE_JOB_OFFER', jobOffer)
                dispatch('notifications/addSuccessNotification', AppString.jobOfferDeleted, { root: true })
            }

            return response
        })
    },

    view(_, jobOffer) {
        // REVIEW: should this have have a VIEW mutation?
        return JobOffer.view(jobOffer[key]).then(response => {
            if (response.status === 200) {
                return response.data
            }
        })
    }
}

const mutations = {
    ...pagination.mutations,

    ADD_JOB_OFFER(state, jobOffer) { 
        state.all.unshift(jobOffer) 
        ++state.pagination.itemsLength
    },

    UPDATE_JOB_OFFER: (state, payload) => {
        const jobOffer = state.all.find(jobOffer => 
            jobOffer[key]=== payload[key])

        if (jobOffer)
            Object.assign(jobOffer, payload.jobOffer)
    },

    DELETE_JOB_OFFER: (state, jobOffer) => {
        const index = state.all.findIndex(item => item === jobOffer)

        if (index >= 0)
            state.all.splice(index, 1)
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
