<template>
  <v-snackbar
    :value="item.show"
    :timeout="timeout"
    :color="item.color"
    @input="close"
    top>
    <v-icon left>
      {{ icon }}
    </v-icon>
    {{ item.message }}

    <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        v-bind="attrs"
        @click="close"
        text>
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { Type } from '../store/modules/notifications'

export default {
  name: 'TheNotification',
  
  props: {
    item: {
      type: Object,
      required: true,
      default: function() {
        return {
          show: false,
          message: '',
          color: '',
          icon: '',
        }
      }
    },

    timeout: {
      type: Number,
      default: 3000
    },
  },

  computed: {
    icon() {
      if (this.item.icon)
        return this.item.icon

      switch (this.item.type) {
        case Type.SUCCESS:
          return this.$icons.success
        case Type.WARNING:
          return this.$icons.warning
        default:
          return ''
      }
    }
  },

  methods: {

    close() {
      this.$store.dispatch('notifications/removeNotification', this.item)
    }

  }
}
</script>

