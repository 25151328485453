<template>
  <div>
    Are you sure you want to 

    <span class="red--text">
    <v-icon color="red">{{ this.$icons.delete }}</v-icon>
    <strong>delete </strong> 
    </span>
    role
    <!-- REVIEW: props causes vuex mutation error -->
    <v-chip 
      color="primary"
      pill
      small><strong><em>` {{ $attrs.name }} `</em></strong>
    </v-chip> 
    <v-icon small>mdi-help</v-icon>
  </div>
</template>

<script>
export default {
  name: 'DeleteRoleMessage',
}
</script>

