<template>
    <div>
        <v-row no-gutters>
            <AppAvatar
                class="mr-4"
                size="40">
            </AppAvatar>

            <v-col>
                <v-row>
                    <v-col>
                        <v-textarea
                                v-model="message"
                                placeholder="Add a note..."
                                auto-grow
                                dense
                                rows="1"
                                hide-details="auto"
                                persistent-placeholder
                                @focus="noteFocus = true"
                                >
                        </v-textarea>
                    </v-col>
                </v-row>
                <v-row 
                    v-if="noteFocus"
                    dense
                    >
                    <v-col>
                        <div class="float-right">
                            <v-btn
                                    color="primary"
                                    @click="onCancel"
                                    class="mr-1">
                                Clear
                            </v-btn>

                            <v-btn
                                    color="primary"
                                    @click="onAddNote">
                                Add
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-col>

        </v-row>

        <v-row
                dense
                >
                <v-col>
                    <v-list subheader>
                        <v-hover
                                v-for="(item, index) in items"
                                :key="index"
                                v-slot="{ hover }"
                                >
                                <v-list-item
                                        class="px-0">
                                    <v-list-item-avatar
                                            :color="item.author.avatarPreference.avatarBackgroundColor">
                                        <span :style="{ color: item.author.avatarPreference.avatarTextColor }">{{ shortenName(item.author.name) }}</span>
                                    </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title class="text-subtitle-2">
                                    {{ item.author.name }}
                                    <span class="text-caption text--disabled">
                                        {{ formatNoteRelativeFromNow(item.created_at) }}
                                    </span>
                                </v-list-item-title>

                                <v-list-item-subtitle
                                        class="text-body-2 font-weight-medium">
                                    {{ item.message }}
                                </v-list-item-subtitle>

                                    <!--<v-col v-else>
                                        <v-row>
                                            <v-col>
                                                <v-textarea
                                                        v-model="item.message"
                                                        auto-grow
                                                        dense
                                                        rows="1"
                                                        hide-details="auto"
                                                        persistent-placeholder
                                                        >
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                        <v-row 
                                            dense
                                            >
                                            <v-col>
                                                <div class="float-right">
                                                    <v-btn
                                                            color="primary"
                                                            @click="item.editing = false"
                                                            class="mr-1">
                                                        Cancel
                                                    </v-btn>

                                                    <v-btn
                                                            color="primary"
                                                            @click="onAddNote(item)">
                                                        Save
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>-->
                            </v-list-item-content>

                            <v-list-item-action
                                v-show="hover">
                                <v-menu
                                        v-if="canInteractNote(item)"
                                        offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                class="mr-n7"
                                                >
                                                <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list dense>
                                        <!--<v-list-item
                                                @click="onEditNoteItem(item)">
                                            <v-list-item-icon>
                                                <v-icon>mdi-pencil</v-icon>
                                            </v-list-item-icon>

                                            <v-list-item-content>
                                                <v-list-item-title>Edit</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item> -->

                                        <v-list-item
                                                @click="onDeleteNoteItem(item)">
                                            <v-list-item-icon>
                                                <v-icon>mdi-trash-can</v-icon>
                                            </v-list-item-icon>

                                            <v-list-item-content>
                                                <v-list-item-title>Delete</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-list-item-action>
                                </v-list-item>
                        </v-hover>
                    </v-list>
                </v-col>
        </v-row>


    </div>
</template>

<script>
import moment from 'moment'
import AppAvatar from '../AppAvatar'

export default {
    name: 'NoteForm',

    props: {
        item: Object
    },

    components: {
        AppAvatar
    },

    data() {
        return {
            message: '',
            noteFocus: false,
        }
    },

    computed: {
        currentUsername() {
            const {first_name, last_name} = this.$store.getters['user/data']

            return `${first_name} ${last_name}`
        },

        items() {
            return this.$store.getters['applicantNotes/getAll']
        },
    },

    methods: {
        /*onEditNoteItem(note) {
            note.editing = true
        },*/
        canInteractNote(note) {
             return this.$store.getters['user/data'].id === note.author.id 
        },
        onDeleteNoteItem(note) {
          this.$store.dispatch('prompt/confirm', {
            title: `Delete confirmation`,
            message: 'Are you sure you want to delete this note?'
          }).then(response => {
            if (response)
                this.$store.dispatch('applicantNotes/delete', note)
          })
           //this.$axios.delete(`profile-notes/${note.id}`).then(() => {
           //    this.$store.dispatch('notifications/addSuccessNotification', 'Note deleted')
           //    this.items.splice(this.items.indexOf(note), 1)
           // }).catch(error => {
           //     console.log(error)
           //this.$store.dispatch('notifications/addWarningNotification', 'Deleting note failed')
           // })
        },

        shortenName(name) {
            const words = name.split(' ').filter(e => e)
            const shortened = words.length > 1 ? words[0][0] + words[1][0] : name[0] + name[1]
            return shortened.toUpperCase()
        },

        onCancel() {
            this.message = ''
            this.noteFocus = false
        },


        refreshItems() {
                this.$store.dispatch('applicantNotes/fetchAll', {
                        id: this.item.profile.id
                    })
//            this.$axios.get(`all-applicants/${this.item.profile.id}/notes`).then(response => {
//                    console.log(response.data)
//                this.items = response.data
//            }).catch(error => {
//                console.log(error)
//            })
        },

        onAddNote() {
            this.addNote(this.message, this.item.profile.id)
            this.onCancel()
        },

        addNote(message, profileId) {
            this.$store.dispatch('applicantNotes/save', {
                message,
                profile_id: profileId
            })
          //  this.$axios.post('profile-notes', {
          //      message,
          //      profile_id: profile.id
          //  }).then(response => {
          //      this.items.unshift(response.data)
          // this.$store.dispatch('notifications/addSuccessNotification', 'Note added')
          //  }).catch(error => {
          //      console.log(error)
          // this.$store.dispatch('notifications/addWarningNotification', 'Adding note failed')
          //  })
        },

        formatNoteRelativeFromNow(value) {
            return moment.unix(value).fromNow()
        }
    },
}
</script>
