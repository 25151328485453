<template>
  <AppContent
    :resourcePath="resourcePath"
    :headers="headers"
    :title="title"
    :subject="subject"
    :table-props="{
      downloadable: false,
      itemKey: 'id',
      headers,
      itemActions: ['edit', 'delete'],
    }"
  >
    <template #form="slotProps">
      <OpportunityForm ref="form" v-bind="slotProps" />
    </template>
  </AppContent>
</template>

<script>
import AppContent from "./AppContent"
import OpportunityForm from "./opportunity/OpportunityForm"

export default {
  name: "Opportunities",
  components: {
    AppContent,
    OpportunityForm,
  },

  data() {
    return {
      resourcePath: "opportunities",
      title: "Opportunities",
      subject: "Opportunity",
      headers: [
        {
          text: "Id",
          value: "id",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          filterable: false,
        },
      ],
    }
  },
}
</script>
