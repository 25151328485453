<template>
  <AppContent
    :resourcePath="resourcePath"
    :headers="headers"
    :title="title"
    :subject="subject"
    item-key="name"
    @table-item-delete="onTableItemDelete"
    :table-props="{ 
      downloadable: false, 
      itemKey: 'name', headers,
      itemActions: ['edit', 'delete']
      }"
    >
      <template #form="slotProps">
        <RoleForm
          ref="form"
          v-bind="slotProps"
        /> 
      </template>
  </AppContent>
</template>

<script>
import AppContent from './AppContent'
import DeleteRoleMessage from './role/DeleteRoleMessage'
import RoleForm from './RoleForm'

export default {
  name: 'Roles',
  components: {
    AppContent,
    RoleForm
  },

  data() {
    return {
      resourcePath: 'roles',
      title: 'Roles',
      subject: 'Role',
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Action',
          value: 'action',
          align: 'center',
          filterable: false,
          sortable: false
        }
      ],
    }
  },

  methods: {

    onTableItemDelete(item) {
      this.$store.dispatch('prompt/confirm', {
        title: 'Delete confirmation',
        component: DeleteRoleMessage,
        data: item
      }).then(response => {
        if (response)
          this.$store.dispatch(`${this.resourcePath}/delete`, item)
      })
    }

  }

}
</script>
