<template>
  <Applicants
    v-bind="$props"
    v-on="$listeners"
    title="Denied Applicants"
    subject="Denied Applicant"
    hide-append-buttons
    :csv-downloadable="false"
  >
    <template #table.item.action="slotProps">
      <UndenyItemAction
        v-bind="slotProps"
        v-on="{
          'click:undeny-item': onUndenyItem,
        }"
      />
    </template>
  </Applicants>
</template>

<script>
import Applicants from "./Applicants"
import { UndenyItemAction } from "./base/table"

export default {
  name: "DeniedApplicants",
  components: {
    Applicants,
    UndenyItemAction,
  },
  props: {
    resourcePath: {
      type: String,
      default: "deniedApplicants",
    },
    headers: {
      type: Array,
      default: function () {
        return [
          {
            text: "Id",
            value: this.$config("applicant.id"),
          },
          {
            text: "Name",
            value: "name",
            customValue: function (item) {
              return `${item.profile.last_name}, ${item.profile.first_name}`
            },
          },
          {
            text: "Email",
            value: "email",
          },
          {
            text: "Phone",
            value: "profile.phone",
            sortable: false,
          },
          {
            text: "Status",
            value: "profile.status",
            align: "center",
          },
          {
            text: "Date Denied",
            value: "denied_at",
            align: "center",
            filterable: false,
          },
          {
            text: "Action",
            value: "action",
            align: "center",
            sortable: false,
            filterable: false,
          },
          {
            text: "Category",
            value: "category",
            align: "center",
          },
        ]
      },
    },
  },
  methods: {
    onUndenyItem(item) {
      const event = "click:undeny-item"

      if (event in this.$listeners) {
        this.$emit(event, item)

        return
      }

      this.$store
        .dispatch("prompt/confirm", {
          title: "Restore denied",
          data: item,
        })
        .then((response) => {
          if (response) {
            this.$store.dispatch(`${this.resourcePath}/restore`, item)
          }
        })
    },
  },
}
</script>
