<template>
    <v-avatar
        v-bind="$props"
        :color="backgroundColor"
        >
        <slot>
            <span :style="textStyle" class="font-weight-medium">{{ shortenName(currentUsername) }}</span>
        </slot>
    </v-avatar>
</template>

<script>
import { VAvatar } from 'vuetify/lib'
import User from '@/api/user'

export default {
    name: 'AppAvatar',

    extends: {
        VAvatar
    },

    data() {
        return {
            textStyle: {
                'font-size': 'large'
            },
            backgroundColor: ''
        }
    },

    computed: {
        currentUsername() {
            const {first_name, last_name} = this.$store.getters['user/data']

            return `${first_name} ${last_name}`
        },

        /* textStyle() { */
        /*     return { */
        /*         'color': 'white' */
        /*     } */
        /* }, */

        /* backgroundColor() { */
        /*     return 'purple' */
        /* } */
    },

    methods: {
        shortenName(name) {
            const words = name.split(' ').filter(e => e)
            const shortened = words.length > 1 ? words[0][0] + words[1][0] : name[0] + name[1]
            return shortened.toUpperCase()
        },
    },

    mounted() {
        User.getPreferences().then(response => {
            const data = response.data
            this.textStyle.color = data.avatarTextColor
            this.backgroundColor = data.avatarBackgroundColor
            console.log(response)
        }).catch(error => {
            console.log(error)
        })
    }
}
</script>
