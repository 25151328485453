<template>
  <v-btn
    v-bind="$props"
    v-on="$listeners"
    icon
    plain
    @click="$emit('click:undeny-item', $attrs.item)"
  >
    <slot>
      <v-icon color="blue">mdi-undo-variant</v-icon>
    </slot>
  </v-btn>
</template>

<script>
import { VBtn } from "vuetify/lib"

export default {
  name: "UndenyItemAction",
  extends: VBtn,
}
</script>
