/**
 * @param api Object of {api/api.js}
 */
export function Pagination (api) {
    this.state = {
        all: [],
        pagination: {},
        filter: {}
    }

    this.getters = {
        getAll: state => state.all,
        getPagination: state => state.pagination,
        getFilter: state => state.filter,
    }

    this.actions = {
        setPagination({ commit }, payload) {
            commit('SET_PAGINATION', payload)
        },

        fetchPagination({ commit }, payload) {
            return api.search(payload).then(response => {
                let data = response.data
                const pagination = {
                    page: data._meta.currentPage,
                    pageCount: data._meta.pageCount,
                    itemsLength: data._meta.totalCount,
                    itemsPerPage: data._meta.perPage,
                }

                commit('SET_ALL', data.items)
                commit('SET_PAGINATION', pagination)
                commit('SET_FILTER', payload)

                return response
            }).catch(error => {
                if (error.response.status === 422)
                    // TODO: Refactor
                    commit('SET_PAGINATION', {
                        page: 0,
                        pageCount: 0,
                        itemsLength: 0,
                        itemsPerPage: 0
                    })

                return error
            })
        },

        save({ commit }, payload) {
            return api.create(payload).then(response => {
                if (response.status === 201) 
                    commit('ADD_ITEM', response.data)

                return response
            })
        },

        view(_, payload) {
            // REVIEW: should this have have a VIEW mutation?
            return api.view(payload[api.key]).then(response => {
                if (response.status === 200) {
                    return response.data
                }
            })
        },

        update({ commit }, payload) {
            return api.update(payload[api.key], payload.params).then(response => {
                if (response.status === 200) 
                    commit('UPDATE_ITEM', {
                        [api.key]: payload[api.key],
                        item: response.data
                    })

                return response
            })
        },

        delete({ commit }, payload) {
            return api.delete(payload[api.key]).then(response => {
                if (response.status === 204) {
                    commit('DELETE_ITEM', payload)
                }

                return response
            })
        }
    }

    this.mutations = {
        SET_FILTER(state, filter)  {
            state.filter = filter
        },
        
        SET_PAGINATION(state, pagination)  {
            state.pagination = pagination
        },

        SET_ALL(state, items) { state.all = items },

        ADD_ITEM(state, item) { 
            state.all.unshift(item) 
            ++state.pagination.itemsLength
        },

        UPDATE_ITEM: (state, payload) => {
            const item = state.all.find(item => {
                const itemKeyValue = item[api.key]
                    .toString()
                    .toLowerCase()

                const payloadKeyValue = payload[api.key]
                    .toString()
                    .toLowerCase()

                return itemKeyValue === payloadKeyValue
            })

            if (item) {
                Object.assign(item, payload.item)
            }
        },

        DELETE_ITEM: (state, item) => {
            const index = state.all.findIndex(thisItem => thisItem === item)

            if (index >= 0) {
                state.all.splice(index, 1)
                --state.pagination.itemsLength
            }
        }

    }
}

export default {
    Pagination,
}
