<template>
  <div>
    Are you sure you want to

    <span class="red--text">
      <v-icon color="red">mdi-deny-variant</v-icon>
      <strong>deny </strong>
    </span>
    applicant
    <!-- REVIEW: props causes vuex mutation error -->
    <v-chip color="primary" pill small
      ><strong
        ><em>` {{ $attrs.profile.full_name }} `</em></strong
      >
    </v-chip>
    <v-icon small>mdi-help</v-icon>

    <v-select
      class="mt-6"
      label="Reason"
      v-model="reason"
      persistent-placeholder
      hide-details="auto"
      dense
      rounded
      outlined
      mandatory
      :items="reasonTypes"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  name: "DenyApplicantMessage",
  data() {
    return {
      reason: "",
      reasonTypes: [
        {
          text: "No Response",
          value: "No Response",
        },
        {
          text: "Qualifications",
          value: "Qualifications",
        },
        {
          text: "Interview Result",
          value: "Interview Result",
        },
      ],
    }
  },

  watch: {
    reason(value, oldValue) {
      if (value != oldValue) {
        this.$emit("change:extra", { denyReason: value })
      }
    },
  },

  mounted() {
    this.reason = this.reasonTypes[0].value
  },
}
</script>
