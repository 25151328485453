<template>
  <AppForm v-bind="$props" v-on="$listeners">
    <v-row>
      <v-col>
        <v-data-table :items="jobsOffered" :headers="jobOfferHeaders">
          <template #item.created_at="{ value }">
            {{ formatDate(value) }}
          </template>

          <template #item.action="slotProps">
            <DeleteItemAction
              v-bind="slotProps"
              v-on="slotProps.on"
              @click:delete-item="onDelete"
            />

            <EditItemAction
              v-bind="slotProps"
              v-on="slotProps.on"
              @click:edit-item="onEdit"
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="9">
        <v-autocomplete
          label="Offer Job"
          v-model="jobOfferSelected"
          :items="availableJobOffers"
          hide-selected
          persistent-placeholder
          hide-details="auto"
          clearable
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="3">
        <v-btn
          color="green"
          class="mt-3 white--text"
          :disabled="
            typeof jobOfferSelected === 'undefined' || jobOfferSelected === null
          "
          @click="sendOffer"
        >
          <v-icon left size="24">mdi-offer</v-icon>
          Send Offer
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="showJobOfferDialog" width="400">
      <v-card>
        <v-card-title>Edit Job Offer </v-card-title>

        <v-card-text>
          <v-date-picker
            :value="formatDate(jobOffer.created_at, 'YYYY-MM-DD')"
            @input="jobOffer.created_at = formatToUnix($event)"
            full-width
            :show-current="false"
            :max="today"
          >
          </v-date-picker>

          <v-text-field label="Position" :value="jobOffer?.job?.title" readonly>
          </v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showJobOfferDialog = false"> Close </v-btn>
          <v-btn text @click="onClickJobOfferUpdate"> Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </AppForm>
</template>

<script>
import AppForm from "../AppForm"
import DeleteItemAction from "../base/table/DeleteItemAction"
import EditItemAction from "../base/table/EditItemAction"

import moment from "moment"

export default {
  name: "JobOfferForm",

  components: {
    AppForm,
    DeleteItemAction,
    EditItemAction,
  },

  props: {
    item: Object,
  },

  data() {
    return {
      showJobOfferDialog: false,
      jobOfferSelected: null,
      jobOffer: {},
      jobOfferHeaders: [
        {
          text: "Date Offered",
          value: "created_at",
        },
        {
          text: "Company",
          value: "job.employer.company",
        },
        {
          text: "Contact #",
          value: "job.employer.contact_number",
        },
        {
          text: "Job Title",
          value: "job.title",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
    }
  },

  computed: {
    today() {
      return moment().format("YYYY-MM-DD")
    },

    jobs() {
      return this.$store.getters["jobs/getAll"].filter(
        (job) =>
          job.viewable &&
          !(
            this.jobsOffered.filter(
              (offeredJob) => offeredJob.job_id === job.id
            ).length > 0
          )
      )
    },

    jobsOffered() {
      return this.$store.getters["jobOffers/getAll"]
    },

    availableJobOffers() {
      return this.jobs.map((item) => ({
        text: item.title,
        value: item.id,
      }))
    },
  },

  methods: {
    formatDate(unix, format = "MMM. D, YYYY") {
      return moment.unix(unix).format(format)
    },

    formatToUnix(date) {
      return moment(date).unix()
    },

    sendOffer() {
      this.$store
        .dispatch("prompt/confirm", {
          title: `Job offer confirmation`,
          message: "Are you sure you want to offer this job?",
        })
        .then((response) => {
          if (response) {
            this.$store
              .dispatch("jobOffers/save", {
                profile_id: this.item.profile.id,
                job_id: this.jobOfferSelected,
              })
              .catch((error) => {
                console.log(error)
                this.$store.dispatch(
                  "notifications/addWarningNotification",
                  "Job offer failed"
                )
              })
          }
        })
    },

    onClickJobOfferUpdate() {
      this.$store
        .dispatch("prompt/confirm", {
          title: `Update offer confirmation`,
          message: "Are you sure you want to update this offer?",
        })
        .then((response) => {
          if (response) {
            this.$store
              .dispatch("jobOffers/update", {
                id: this.jobOffer.id,
                params: {
                  created_at: this.jobOffer.created_at,
                },
              })
              .catch((error) => {
                console.log(error)
                this.$store.dispatch(
                  "notifications/addWarningNotification",
                  "Updating Job offer failed"
                )
              })
          }
        })
    },

    onDelete(item) {
      this.$store
        .dispatch("prompt/confirm", {
          title: `Revoke offer confirmation`,
          message: "Are you sure you want to revoke this offer?",
        })
        .then((response) => {
          if (response) this.$store.dispatch(`jobOffers/delete`, item)
        })
    },

    onEdit(item) {
      this.jobOffer = JSON.parse(JSON.stringify(item))
      this.showJobOfferDialog = true
    },
  },

  mounted() {
    this.$store.dispatch("jobs/fetchPagination")
  },
}
</script>
