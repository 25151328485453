import Country, { key } from '../../api/country'
import { AppString } from '../../strings'

export default {
    namespaced: true,

    state: () => ({
        all: [],
        pagination: {},
    }),

    getters: {
        getAll: state => state.all,
        getPagination: state => state.pagination
    },

    actions: {
        setPagination({ commit }, value) {
            commit('SET_PAGINATION', value)
        },

        fetchPagination({ commit }, params) {
            return Country.search(params).then(response => {
                let data = response.data
                const pagination = {
                    page: data._meta.currentPage,
                    pageCount: data._meta.pageCount,
                    itemsLength: data._meta.totalCount,
                    perPage: data._meta.perPage
                }

                commit('SET_ALL', data.items)
                commit('SET_PAGINATION', pagination)

                return response
            }).catch(error => {
                if (error.response.status === 422)
                    commit('SET_PAGINATION')

                return error
            })
        },

        update({ commit, dispatch }, payload) {
            return Country.update(payload[key], payload.params).then(response => {
                if (response.status === 200) {
                    commit('UPDATE_USER_ACCOUNT', {
                        [key]: payload[key],
                        role: response.data
                    })
                    dispatch('notifications/addSuccessNotification', AppString.countryUpdated, { root: true })
                }

                return response
            })
        },

        save({ commit, dispatch }, payload) {
            return Country.create(payload).then(response => {
                
                if (response.status === 201) {
                    commit('ADD_USER_ACCOUNT', response.data)
                    dispatch('notifications/addSuccessNotification', AppString.countryCreated, { root: true })
                }

                return response
            })
        },

        delete({ commit, dispatch }, role) {
            return Country.delete(role[key]).then(response => {
                if (response.status === 204) {
                    commit('DELETE_USER_ACCOUNT', role)
                    dispatch('notifications/addSuccessNotification', AppString.countryDeleted, { root: true })
                }

                return response
            })
        },

        view(_, country) {
            // REVIEW: should this have have a VIEW mutation?
            return Country.view(country[key])
        }
    },

    mutations: {
        SET_PAGINATION(state, pagination)  {
            state.pagination = pagination
        },

        SET_ALL(state, items) { state.all = items },

        ADD_USER_ACCOUNT(state, role) { state.all.unshift(role) },

        UPDATE_USER_ACCOUNT: (state, payload) => {
            const role = state.all.find(role => 
                role[key] === payload[key]
            )

            if (role)
                Object.assign(role, payload.role)
        },

        DELETE_USER_ACCOUNT: (state, role) => {
            const index = state.all.findIndex(item => item === role)

            if (index >= 0)
                state.all.splice(index, 1)
        }

    },

}
