<template>
    <AppForm
        v-bind="$props"
        v-on="$listeners"
        >
        <v-row>
            <v-col
                cols="12">
                <v-text-field
                  v-model="item.title"
                  :error-messages="errorMessages.title"
                  :readonly="readonly"
                  label="Title"
                  hide-details="auto"
                  persistent-placeholder
                  >
                </v-text-field>
            </v-col>
        </v-row>

        <v-row>
              <v-col>
                <v-combobox
                  v-model="item.interviewers"
                  item-text="fullName"
                  item-value="email"
                  hide-details="auto"
                  label="Interviewers"
                  prepend-icon="mdi-filter-variant"
                  chips
                  clearable
                  multiple
                  persistent-placeholder
                  >
                  <template v-slot:selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click="select"
                      @click:close="removeInterviewer(item)"
                      >
                      <strong>{{ item.fullName }}</strong>&nbsp;
                      <span>&lt;{{ item.email }}&gt;</span>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>

        <v-row>
            <v-col
                cols="12"
                md="6">
                <v-menu
                  ref="startTime"
                  v-model="startTimeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="item.startTime"
                  transition="slide-y-transition"
                  max-width="290px"
                  min-width="290px"
                  offset-y

                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.startTime"
                      label="Start Time"
                      prepend-icon="mdi-clock-start"
                      v-bind="attrs"
                      v-on="on"
                      hide-details="auto"
                      persistent-placeholder
                      readonly
                      ></v-text-field>
                  </template>

                  <AppTimePicker
                    v-if="startTimeMenu"
                    v-model="item.startTime"
                    @click:cancel="startTimeMenu = false"
                    @click:ok="$refs.startTime.save(item.startTime)"
                    />

                </v-menu>
            </v-col>

            <v-col
                cols="12"
                md="6">
                <v-menu
                  ref="endTime"
                  v-model="endTimeMenu"
                  :close-on-content-click="false"
                  :return-value.sync="item.endTime"
                  transition="slide-y-transition"
                  max-width="290px"
                  min-width="290px"
                  offset-y

                  >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="item.endTime"
                      label="End Time"
                      prepend-icon="mdi-clock-end"
                      v-bind="attrs"
                      v-on="on"
                      hide-details="auto"
                      persistent-placeholder
                      readonly
                      ></v-text-field>
                  </template>

                  <AppTimePicker
                    v-if="endTimeMenu"
                    v-model="item.endTime"
                    @click:cancel="endTimeMenu = false"
                    @click:ok="$refs.endTime.save(item.endTime)"
                    />

                </v-menu>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12">
                <v-textarea
                    v-model="item.description"
                    label="Description"
                    :error-messages="errorMessages.description"
                    :readonly="readonly"
                    hide-details="auto"
                    persistent-placeholder
                    no-resize
                    rows="3"
                >
            </v-textarea>
            </v-col>
        </v-row>
    </AppForm>
</template>

<script>
import AppForm from '../AppForm'
import AppTimePicker from '../AppTimePicker'

export default {
  name: 'CalendarEventForm',
  extends: AppForm,
  components: {
    AppForm,
    AppTimePicker
  },
  data() {
      return {
        startTimeMenu: false,
        endTimeMenu: false
      }
  }

}
</script>

