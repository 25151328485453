<template>
  <v-row>
    <v-col>
      <AppFormDialog
        v-model="showInterviewerDialog"
        title="Interviewer Form"
        :loading="loading"
        @clear="clearForm"
        @save="onSave"
        hide-reset
        >

        <template #form>

          <InterviewerForm
            ref="form"
            :error-messages="errorMessages"
            :readonly="readonly"
          />

        </template>

      </AppFormDialog>

      <v-row>
        <v-col
          md="12">

          <AppTable
            :headers="headers"
            title="Interviewers"
            resourcePath="interviewer"
            >
          </AppTable>

          <v-fab-transition>
            <v-btn
              color="green"
              @click="create"
              bottom
              dark
              fab
              fixed
              large
              right
              >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AppTable from './AppTable'
import AppFormDialog from './AppFormDialog.vue'
import InterviewerForm from './InterviewerForm.vue'

export default {
  name: 'Interviewers',
  components: {
    AppTable,
    AppFormDialog,
    InterviewerForm,
  },
  data() {
    return {
      headers: [
        {
          text: 'Id',
          value: 'id',
        },
        {
          text: 'First Name',
          value: 'first_name',
        },
        {
          text: 'Last Name',
          value: 'last_name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Phone Number',
          value: 'phone',
        },
        {
          text: 'Action',
          value: 'action',
          filterable: false
        }
      ],

      showInterviewerDialog: false,
      item: {},
      errorMessages: {},
      readonly: false,
      updateMode: false,
      loading: false,
      items: []
    }
  },

  computed: {
    saveButtonText() {
      return this.updateMode ? "Update" : "Save"
    }
  },

  methods: {

    onSave(item) {
      const params = {
        first_name: item.firstName,
        last_name: item.lastName,
        email: item.email,
        phone: item.phone
      }

      this.$axios.post('interviewers', params).then(response => {
        console.log(response)
      }).catch(error => {
        console.log(error)
      })
    },

    clearForm() {
      this.$refs.form.clearForm()
    },

    tableLoaded(items) {
      this.items = items
    },
    openDialog() {
      this.showInterviewerDialog = true
    },
    closeDialog() {
      this.showInterviewerDialog = false
    },
    view(data) {
      const {
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        gender,
        email,
        company,
        position,
        address
      } = data

      this.item = {
        firstName,
        lastName,
        gender,
        email,
        company,
        position,
        phoneNumber,
        address
      }

      this.readonly = true
      this.openDialog()
    },
    edit(data) {
      const {
        id,
        first_name: firstName,
        last_name: lastName,
        phone_number: phoneNumber,
        gender,
        email,
        company,
        position,
        address
      } = data

      this.item = {
        id,
        firstName,
        lastName,
        gender,
        email,
        company,
        position,
        phoneNumber,
        address
      }
      this.readonly = false
      this.updateMode = true
      this.openDialog()
    },

    create() {
      this.readonly = false
      this.updateMode = false
      this.clearForm()
      this.openDialog()
    },

    save() {
      const data = this.item
      const params = {
        first_name:      data.firstName,
        last_name:       data.lastName,
        gender:          data.gender,
        email:           data.email,
        company:         data.company,
        position:        data.position,
        phone_number:    data.phoneNumber,
        address:         data.address
      }

      this.errorMessages = {}
      this.$refs.employerForm.validate()
      this.loading = true

      let request
      if (this.updateMode) {
        const { id } = data

        // TODO: only patch properties that values are changed
        request = this.$axios.patch(`employer/${id}`, params).then(({ data }) => {
          this.$emit('notify', this.$strings.employerUpdated)
          const item = this.items.find(item => item.id === data.id)
          Object.assign(item, data)
        })
      }
      else {
        request = this.$axios.post('employers', params).then(({ data }) => {
          this.$emit('notify', this.$strings.employerCreated)
          this.items.unshift(data)
        })
      }

      request.catch(error => {
        const errors = error.response.data?.errors

        this.errorMessages = {
          firstName:      errors?.first_name,
          lastName:       errors?.last_name,
          gender:         errors?.gender,
          email:          errors?.email,
          company:        errors?.company,
          position:       errors?.position,
          contactNumber:  errors?.contact_number,
          address:        errors?.address
        }

        if (error.response.status !== 422) {
          this.$emit('notify', this.$strings.serverError, false)
        }
      }).finally(() => {
        this.loading = false
      })

    }
  }
}
</script>
