<template>
    <v-row>
        <v-col>

        </v-col>
    </v-row>
</template>

<script>

export default {
    name: 'Interview',
}
</script>
