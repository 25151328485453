<template>
  <v-dialog
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    transition="dialog-top-transition"
    scrollable
    persistent
  >
    <slot v-bind="$props">
      <v-card :loading="loading" tile>
        <v-toolbar color="teal darken-2" dark>
          <slot name="toolbar" :item="item" :title="title">
            <v-toolbar-title>
              <slot name="title" :item="item" :text="title">
                {{ title }}
              </slot>
            </v-toolbar-title>
          </slot>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <slot
              name="form"
              :readonly="readonly"
              :item="item"
              :mode="mode"
              v-on="$listeners"
            />
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            v-show="!hideClear"
            v-if="clearable"
            @click="clear"
            color="black"
            text
          >
            {{ $strings.clearButtonText }}
          </v-btn>

          <v-btn
            v-show="!hideReset"
            v-if="resetable"
            color="grey"
            @click="resetToDefault"
            text
          >
            {{ $strings.resetToDefaultButtonText }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            :loading="loading"
            v-if="!readonly"
            color="primary"
            @click="onSave"
            text
          >
            {{ saveButtonText }}
          </v-btn>

          <v-btn color="red" @click="onClose" text> Close </v-btn>
        </v-card-actions>
      </v-card>
    </slot>
  </v-dialog>
</template>

<script>
/**
 * REVIEW: Should be using vuex store. Importantly for 'item', 'mode', and 'value'?
 */

const State = {
  VISIBLE: 2,
  UPDATE_MODE: 4,
  READONLY: 8,
  CLEARABLE: 16,
  RESETABLE: 32,
}

export const Mode = {
  VIEW: 1,
  EDIT: 2,
  CREATE: 3,
}

export default {
  name: "AppFormDialog",

  props: {
    subject: {
      type: String,
      required: true,
      default: "",
    },
    loading: Boolean,
    hideClear: Boolean,
    hideReset: {
      type: Boolean,
      default: true,
    },
    item: {
      type: Object,
      default: function () {
        return {}
      },
    },
    editedItem: {
      type: Object,
      default: function () {
        return {}
      },
    },
    mode: {
      type: Number,
      default: 0,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      updateMode: false,
      readonly: false,
      clearable: true,
      resetable: !this.hideReset,
      /* item: {}, */
      /* mode: 0, */
    }
  },

  computed: {
    title() {
      switch (this.getMode()) {
        case Mode.VIEW:
          return `${this.subject} Details`
        case Mode.EDIT:
          return `${this.subject} Edit`
        case Mode.CREATE:
          return `${this.subject} Creation`
        default:
          return this.subject || ""
      }
    },

    saveButtonText() {
      return this.updateMode ? "Update" : "Save"
    },
  },

  watch: {
    value: function (value) {
      if (value) this.$emit("update:mode", this.mode)
    },

    mode: function (value) {
      this.setMode(value)
    },
  },

  methods: {
    setMode(mode = Mode.VIEW) {
      this.mode = mode

      switch (mode) {
        case Mode.CREATE:
          this.setState(State.VISIBLE | State.CLEARABLE | State.RESETABLE)
          break
        case Mode.EDIT:
          this.setState(State.VISIBLE | State.UPDATE_MODE | State.CLEARABLE)
          break
        case Mode.VIEW:
          this.setState(State.VISIBLE | State.READONLY)
          break
        default:
          this.setState()
      }
    },

    getMode() {
      return this.mode
    },

    setState(state) {
      this.updateMode = (state & State.UPDATE_MODE) === State.UPDATE_MODE
      this.readonly = (state & State.READONLY) === State.READONLY
      this.clearable = (state & State.CLEARABLE) === State.CLEARABLE
      this.resetable = (state & State.RESETABLE) === State.RESETABLE
    },

    getState() {
      return this.value | this.updateMode | this.readonly
    },

    clear() {
      this.$emit("clear")
    },

    resetToDefault() {
      this.$emit("resetToDefault")
    },

    onClose() {
      this.$emit("close", false) //this.show)
    },

    onSave() {
      this.$emit("save", this.item, this.editedItem)
    },
  },
}
</script>
