<template>
  <AppForm
    v-bind="$props"
    v-on="$listeners"
  >
    <v-row>
      <v-col
        cols="12"
        md="5">
        <v-text-field
          v-model="item.first_name"
          :error-messages="errorMessages.first_name"
          :readonly="readonly"
          label="First name"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="2">
        <v-text-field
          v-model="item.middle_initial"
          :error-messages="errorMessages.middle_initial"
          label="Initial"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="5">
        <v-text-field
          v-model="item.last_name"
          :error-messages="errorMessages.last_name"
          label="Last name"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="2">
        <v-text-field
          v-model="item.suffix"
          :error-messages="errorMessages.suffix"
          label="Suffix"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="4">
        <v-select
          v-model="item.gender"
          :items="genderItems"
          :error-messages="errorMessages.gender"
          label="Gender"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-select>
      </v-col>

      <v-col
        cols="12"
        md="6">
        <v-text-field
          v-model="item.email"
          :error-messages="errorMessages.email"
          label="Email"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>

    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="item.company"
          :error-messages="errorMessages.company"
          label="Company"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6">
        <v-text-field
          v-model="item.position"
          :error-messages="errorMessages.position"
          label="Position"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        sm="6">
        <v-text-field
          v-model="item.contact_number"
          :error-messages="errorMessages.contact_number"
          label="Contact Number"
          hide-details="auto"
          :persistent-placeholder="true">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="item.address"
          :error-messages="errorMessages.address"
          label="Address"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>
  </AppForm>
</template>

<script>
import AppForm from '../AppForm'

export default {
  name: 'EmployerForm',
  extends: AppForm,
  components: {
    AppForm
  },

  data() {
    return {
      genderItems: [
        "Male", "Female", "Rather not say"
      ],
      firstNameRule: [
        v => !!v || 'First name is required',
        v => /^[a-zA-Z\s]+$/.test(v) || 'Must only contain letters(a-Z) and spaces.'
      ],
      middleInitialRule: [
        v => /^[a-zA-Z\s]{0,1}$/.test(v) || 'Can only contain 1 letter.'
      ],
      lastNameRule: [
        v => !!v || 'Last name is required',
        v => /^[a-zA-Z\s]+$/.test(v) || 'Must only contain letters(a-Z) and spaces.'
      ],
      suffixRule: [
        v => v?.length <= 10 || 'Should contain at most 10 characters',
        v => v?.length >= 1 || 'Should contain at least 1 character',
      ],
      emailRule: [
        v => !!v || 'Email is required',
        v => /^.+@.+\..+/.test(v) || 'Email must be valid'
      ],
      companyRule: [
        v => !!v || 'Company is required',
        v => v?.length <= 30 || 'Should contain at most 30 characters',
        v => v?.length >= 2 || 'Should contain at least 2 characters',
      ],
      positionRule: [
        v => !!v || 'Position is required',
        v => v?.length <= 30 || 'Should contain at most 30 characters',
        v => v?.length >= 4 || 'Should contain at least 4 characters',
      ],
      contactNumberRule: [
        v => !!v || 'Contact Number is required',
        // See: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s03.html
     //   v => /^\+(?:[0-9] ?){6,14}[0-9]$/.test(v) || 'Must be a valid contact number',
      ],
      addressRule: [
        v => !!v || 'Address is required',
        v => v?.length <= 100 || 'Should contain at most 100 characters',
      ]
    }
  },
}
</script>

