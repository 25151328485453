// REVIEW: Should this be fetch from the server?
export const AppString = {
    clearButtonText: "Clear",
    downloadCsvHint: "Download CSV",

    roleCreated: "New role created",
    roleUpdated: "Existing role updated",
    roleDeleted: "Role deleted",

    loanRequestCreated: "New Loan Request created",
    loanRequestUpdated: "Existing Loan Request updated",
    loanRequestDeleted: "Loan request deleted",

    loanAgreementCreated: "New Loan Agreement created",
    loanAgreementUpdated: "Existing Loan Agreement updated",
    loanAgreementDeleted: "Loan request deleted",

    permissionCreated: "New permission created",
    permissionUpdated: "Existing permission updated",
    permissionDeleted: "Permission deleted",
    
    applicantMoved: "Applicant moved to",
    applicantMovedFailed: "Unable to progress further",

    registrantCreated: "New registrant created",
    registrantUpdated: "Existing registrant updated",
    registrantDeleted: "Registrant deleted",
    registrantArchived: "Registrant archived",
    registrantDenied: "Registrant denied",
    registrantRestored: "Registrant restored",

    userAccountCreated: "New account created",
    userAccountUpdated: "Existing user account updated",
    userAccountDeleted: "Account deleted",
    
    employerCreated: "New employer created",
    employerUpdated: "Existing employer updated",
    employerDeleted: "Employer deleted",

    interviewCreated: "New interview scheduled",
    interviewUpdated: "Existing interview updated",
    interviewDeleted: "Interview deleted",

    jobCreated: "New job added",
    jobUpdated: "Existing job updated",
    jobDeleted: "Job deleted",

    opportunityCreated: "New opportunity added",
    opportunityUpdated: "Existing opportunity updated",
    opportunityDeleted: "Opportunity deleted",


    jobOfferCreated: "Job offer created",
    jobOfferUpdated: "Existing job offer updated",
    jobOfferDeleted: "Job offer deleted",

    resetToDefaultButtonText: "Reset to default",
    searchLabel: "Quick Search ( e.g \"Bobby\" ) | Advanced Filter ( e.g \"name:John Doe status:new\" )",
    serverError: "Oops, something went wrong",
    tableLoadingText: "Please wait while fetching data from the server...",
    reloadTableTooltip: "Refresh",
    showHideHeaders: "Show/hide column(s)"
}

export default AppString

