import Payment from '../../api/payment'
import { Pagination } from '../common.js'
import moment from 'moment'

const pagination  = new Pagination(Payment)

function saveMetadata(context, payload) {
    return pagination.actions.save(context, payload).then(response => {
        context.dispatch('notifications/addSuccessNotification', 'Payment has been recorded', { root: true })
        return response
    })
}

function updateMetadata(context, payload) {
    return pagination.actions.update(context, payload).then(response => {
        context.dispatch('notifications/addSuccessNotification', 'Payment has been updated', { root: true })
        return response
    })
}

export const state = {
    ...pagination.state
}

export const getters = {
    ...pagination.getters
}

export const actions = {
    ...pagination.actions,

    update(context, payload) {
        if (payload.params.screenshot) {
            // Upload the screenshot first then the metadata of the payment.
            return Payment.uploadScreenshot(payload.params.screenshot).then(response => {
                if (response.status === 201) {
                    payload.params.screenshot_path = response.data?.screenshot_path
                    updateMetadata(context, payload)
                }

                return response
            })
        }

        return updateMetadata(context, payload)
    },

    save(context, payload) {
        if (payload.screenshot) {
            // Upload the screenshot first then the metadata of the payment.
            return Payment.uploadScreenshot(payload.screenshot).then(response => {
                if (response.status === 201) {
                    payload.screenshot_path = response.data?.screenshot_path
                    saveMetadata(context, payload)
                }

                return response
            })
        }
        
        return saveMetadata(context, payload)

    },

    delete(context, payload) {
        return pagination.actions.delete(context, payload).then(response => {
            context.dispatch('notifications/addSuccessNotification', 'Payment has been deleted', { root: true })
            return response
        })
    },

    view(_, payload) {
        return Payment.view(payload[Payment.key]).then(response => {
            if (response.status === 200) {
                const data = response.data

                return {
                    ...data,
                    paid_datetime: moment.parseZone(data.paid_datetime).format(moment.HTML5_FMT.DATETIME_LOCAL)
                }
            }
        })
    }
}

export const mutations = {
    ...pagination.mutations
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
