<template>
  <Registrants
    v-bind="$props"
    v-on="$listeners"
    >
  </Registrants>
</template>

<script>
import Registrants from './Registrants'

export default {
  name: 'Approved',
  components: {
    Registrants,
  },
  props: { 
    resourcePath: {
      type: String,
      default: "approved"
    },
    title: {
        type: String,
        default: "Approved Applicants"
    },
    subject: {
        type: String,
        default: "Approved Applicant"
    }
  }
}

</script>

