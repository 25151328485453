import Opportunity, { key } from '../../api/opportunity'
import { AppString } from '../../strings'
import { Pagination } from '../common.js'

const pagination  = new Pagination(Opportunity)

export const state = {
    ...pagination.state,
}

export const getters = {
    ...pagination.getters
}

export const actions = {
    ...pagination.actions,

    fetchPagination(context, payload) {
        return pagination.actions.fetchPagination(context, {
            ...payload
        })
    },
    update({ commit, dispatch }, payload) {
        // TODO: add failed notification on all api request
        return Opportunity.update(payload[key], payload.params).then(response => {
            if (response.status === 200) {
                commit('UPDATE_OPPORTUNITY', {
                    [key]: payload[key],
                    opportunity: response.data
                })
                dispatch('notifications/addSuccessNotification', AppString.opportunityUpdated, { root: true })
            }

            return response
        })
    },

    save({ commit, dispatch }, payload) {
        return Opportunity.create(payload).then(response => {
            
            if (response.status === 201) {
                commit('ADD_OPPORTUNITY', response.data)
                dispatch('notifications/addSuccessNotification', AppString.opportunityCreated, { root: true })
            }

            return response
        })
    },

    delete({ commit, dispatch }, opportunity) {
        return Opportunity.delete(opportunity[key]).then(response => {
            if (response.status === 204) {
                commit('DELETE_OPPORTUNITY', opportunity)
                dispatch('notifications/addSuccessNotification', AppString.opportunityDeleted, { root: true })
            }

            return response
        })
    },

    view(_, opportunity) {
        return Opportunity.view(opportunity[key]).then(response => {
            if (response.status === 200) {
                const data = response.data
                return data

                // return {
                //     id: data?.id,
                //     title: lodash.startCase(lodash.toLower(data?.title)),
                //     opportunityBankId: data?.bank_id,
                //     postedDate: data?.posted,
                //     nocCode: data?.noc_code,
                //     validThrough: data?.valid_through,
                //     vacancies: data?.vacancies,
                //     medianWage: data?.median_wage,
                //     location: data?.location,
                //     educations: data?.educations?.trim(),
                //     experience: data?.experience,
                //     credentials: data?.credentials,
                //     specific_skills: data?.specific_skills,
                //     target_audience: data?.target_audience,
                //     security_and_safety: data?.security_and_safety,
                //     work_location_information: data?.work_location_information,
                //     personal_suitability:  data?.personal_suitability,
                //     work_conditions_and_physical_capabilities: data?.work_conditions_and_physical_capabilities,
                //     languages: data?.languages,
                // } 
            }
        })
    },
    searchByTitle({ dispatch }, payload) {
        return dispatch('fetchPagination', {
            'filter[or][][title][like]': payload,
        })
    },
}

export const mutations = {
    ...pagination.mutations,
    CHANGE_STATUS(state, { applicant, status }) {
        applicant.profile.status = status
    },

    ADD_OPPORTUNITY(state, opportunity) { state.all.unshift(opportunity) },

    UPDATE_OPPORTUNITY(state, payload) {
        const opportunity = state.all.find(opportunity => 
            opportunity[key].toString().toLowerCase() === payload[key].toString().toLowerCase())

        if (opportunity)
            Object.assign(opportunity, payload.opportunity)
    },
    
    VIEWABLE_OPPORTUNITY(state, { opportunity, value }) {
        opportunity.viewable = value
        // Object.assign(opportunity, { viewable: value })
    },

    DELETE_OPPORTUNITY(state, opportunity) {
        const index = state.all.findIndex(item => item === opportunity)

        if (index >= 0) {
            state.all.splice(index, 1)
            // update pagination items length
            // --state.pagination.itemsLength
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
