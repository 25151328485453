import Vue from 'vue'

import vuetify from './plugins/vuetify'
import router from './plugins/router'
import axios from './plugins/axios' // TODO: Remove this after implementing vuex
// import zoom from './plugins/zoom'

import moment from 'moment'
import config from './config.js'
import strings from './strings.js'
import icons from './icons.js'
import store from './store'


// TODO: separate file
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import App from './App.vue'

Vue.use(VueViewer)
//

Vue.prototype.$axios = axios // TODO: Remove this after implementing vuex
Vue.prototype.$config = config
Vue.prototype.$strings = strings
Vue.prototype.$icons = icons

Vue.config.productionTip = false

function validateToken() {
    // const expiresAt = localStorage.getItem('expiresAt')
    console.log(moment().format('YYYY-MM-DD HH:mm:ss'))
}

validateToken()


// SEE: (https://stackoverflow.com/questions/52096412/vue-js-and-axios-redirect-on-401)
axios.interceptors.response.use(response => response, error => {

    switch (error.response.status) {
        case 400:
            break

        case 401:
            if (localStorage.getItem('accessToken')) {
                window.alert('Session has timed out. Try logging in again.')
                localStorage.removeItem('accessToken')
                router.push({ name: 'Login' })
            }

            break

        default:
            break

    }
    return Promise.reject(error)
})

router.beforeEach(async (to, from, next) => {
    let isLoggedIn = false;

    // TODO: Check for token expiration
    if (localStorage.getItem('accessToken')) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
        isLoggedIn = true;
    }

    if (isLoggedIn) {
        await store.dispatch('user/me').then(response => {
            localStorage.setItem('permissions', JSON.stringify(response.data.results.permissions))
        }).catch(error => {
          console.log(error)
        })
    }

    const permissions = JSON.parse(localStorage.getItem('permissions'))
    const hasPermission = permissions?.includes(to.meta.can) || false || !('can' in to.meta)

    if (!isLoggedIn && to.name !== 'Login')
    {
        next({
            name: 'Login'
        })
    }
    else if (isLoggedIn && to.name === 'Login') {
        next({
            name: 'Home'
        })
    }
    else {
        if (!hasPermission) {
            next({
                name: 'Unauthorized'
            })
        }
        else {
            next()
        }
    }

})

new Vue({
    vuetify,
    router,
    // zoom,
    store,
    render: function (h) { return h(App) }
}).$mount('#app')
