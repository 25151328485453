<template>
  <div>
    Are you sure you want to 

    <span class="red--text">
    <v-icon color="red">mdi-archive</v-icon>
    <strong>archive </strong> 
    </span>
    applicant 
    <!-- REVIEW: props causes vuex mutation error -->
    <v-chip 
      color="primary"
      pill
      small><strong><em>` {{ $attrs.profile.full_name }} `</em></strong>
    </v-chip> 
    <v-icon small>mdi-help</v-icon>
  </div>
</template>

<script>
export default {
  name: 'ArchiveApplicantMessage',
}
</script>

