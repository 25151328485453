<template>
  <Applicants
    v-bind="$props"
    v-on="$listeners"
    >
  </Applicants>
</template>

<script>
import Applicants from './Applicants'

export default {
  name: 'Registrants',
  extends: Applicants,

  props: {
    resourcePath: {
      type: String,
      default: "registrants"
    },
    title: {
        type: String,
        default: "New Registrants"
    },
    subject: {
        type: String,
        default: "New Registrant"
    }
  },
  components: {
    Applicants,
  },
}
</script>

