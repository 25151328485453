// NOTE: I think this is too slow, needs refactoring or don't use it at all
// TODO: use export default
const data = {
    interviewTypes: [
        {
            name: 'Initial',
            duration: 15
        },
        {
            name: 'Final',
            duration: 30
        }
    ],
    timeFormat: 'h:mma',
    dateFormat: 'YYYY-MM-DD',
    applicantStatuses: [
        'Profile Completion Pending',
        'Application in Progress',
        'Candidate for Interview',
        'Job Offered',
        'Position Withdrawn',
        'Vacancy Filled'
    ],
    passingGrade: 50,
    employer: {
        id: 'id',
        firstName: 'first_name',
        lastName: 'last_name',
        name: 'name',
        gender: 'gender',
        position: 'position',
        company: 'company',
        address: 'address',
        email: 'email',
        contactNumber: 'contact_number',
        status: 'status'
    },
    // NOTE: object values must be string
    applicant: {
        id: 'id',
        profileId: 'profile.id',
        firstName: 'profile.first_name',
        middleName: 'profile.middle_name',
        lastName: 'profile.last_name',
        email: 'email',
        gender: 'profile.gender',
        code: 'profile.code',
        phoneNumber: 'profile.phone',
        dateOfBirth: 'profile.birthdate',
        status: 'profile.status',
        createdAt: 'profile.created_at',
        updatedAt: 'profile.updated_at',

        street: 'profile.address.street',
        city: 'profile.address.city',
        state: 'profile.address.state',
        zip: 'profile.address.zip',
        country: 'profile.address.country',

        desiredPosition: 'profile.application.desired_position',
        heardAboutUs: 'profile.application.how_did_you_heard_about_us',
        startDate: 'profile.application.available_start_date',
        resume: 'profile.application.resume',
        transcriptOfRecords: 'profile.application.transcript_of_record',
        license: 'profile.application.license',

        ieltsTestType: 'profile.ielts.test_type',
        ieltsTestFormat: 'profile.ielts.test_format',
        ieltsTestDate: 'profile.ielts.test_date',
        ieltsTestReferenceId: 'profile.ielts.reference_id',
        ieltsListening: 'profile.ielts.listening_result',
        ieltsReading: 'profile.ielts.reading_result',
        ieltsWriting: 'profile.ielts.writing_result',
        ieltsSpeaking: 'profile.ielts.speaking_result',

        institution: 'profile.education.institution',
        diplomaCertificate: 'profile.education.diploma',
        diplomaFirstName: 'profile.education.first_name',
        diplomaMiddleName: 'profile.education.middle_name',
        diplomaLastName: 'profile.education.last_name',
        fieldOfStudy: 'profile.education.field_of_study',
        yearAwarded: 'profile.education.year_awarded',
        yearAttendedFrom: 'profile.education.year_attended_from',
        yearAttendedTo: 'profile.education.year_attended_to',
        trainingsCertificates: 'profile.education.trainings_or_certificates',

        ratingScore: 'profile.rating.score',
    },
    referrer: {
        firstName: 'first_name',
        middleName: 'middle_name',
        lastName: 'last_name',
        email: 'email',
        address: 'address',
        code: 'code',
        country: 'country',
        registrants: 'registrants',
        refereesCount: 'referees_count',
        status: 'status',
    },
}

const get = (p, o) => p.split('.').reduce((xs, x) => (xs && xs[x]), o)

export default (p, o) => { 
    const value = get(p, data)
    return (o === null || typeof o === 'undefined') 
        ? value
        : get(value, o)
}

