import axios from '../plugins/axios'

export function actions(endpoint) {
    return {
        search(params) {
            return axios.get(endpoint, { params })
        },

        delete(id) {
            return axios.delete(`${endpoint}/${id}`)
        },

        update(id, params) {
            return axios.patch(`${endpoint}/${id}`, params)
        },

        create(params) {
            return axios.post(`${endpoint}`, params)
        },

        view(id, params) {
            return axios.get(`${endpoint}/${id}`, { params })
        }
    }
}

export function mapMethods(endpoint, map) {
    return Object.fromEntries(
        Object.entries(actions(endpoint)).filter(([key]) => map.includes(key))
    )
}

export default axios
