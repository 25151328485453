<script>
import AppTable from '../AppTable'
export default {
    name: 'JobBankTable',
    extends: AppTable,

    components: {
        AppTable
    },

    props: {
        resourcePath: {
            type: String,
            default: 'jobs'
        },

        headers: {
            type: Array,
            default: function() {
                return [
                    {
                        text: 'Title',
                        value: 'title'
                    },
                    {
                        text: 'Posted',
                        value: 'posted'
                    },
                    {
                        text: 'Vacancies',
                        value: 'vacancies'
                    },
                    {
                        text: 'Valid Through',
                        value: 'valid_through'
                    },
                    {
                        text: 'Action',
                        value: 'action',
                        align: 'center',
                        filterable: false
                    }
                ]
            }
        }
    }
}
</script>
