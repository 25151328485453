<template>
  <AppContent
    v-bind="$props"
    v-on="$listeners"
    item-key="name"
    :form-dialog-props="{ maxWidth: 700 }"
    :table-props="{
      headers,
    }"
    hide-create-button
    @error-response="errorMessages = $event"
  >
    <template #table="{ attrs, on }">
      <AppTable v-bind="{ ...attrs, ...$props }" v-on="on">
        <template
          #item.action="slotProps"
          v-bind="{
            ...slotProps,
            on: {
              ...slotProps.on,
              ...tableItemSlotProps.on,
            },
          }"
        >
          <slot
            name="table.item.action"
            v-bind="{
              ...slotProps,
              on: {
                ...slotProps.on,
                ...tableItemSlotProps.on,
              },
            }"
          >
            <ViewItemAction v-bind="slotProps" v-on="slotProps.on" />
          </slot>
        </template>
      </AppTable>
    </template>

    <template #formDialog="formDialogSlotProps">
      <AppFormDialog
        v-bind="formDialogSlotProps.attrs"
        v-on="formDialogSlotProps.on"
      >
        <template #form="slotProps">
          <LoanAgreementForm v-bind="slotProps"> </LoanAgreementForm>
        </template>
      </AppFormDialog>
    </template>
  </AppContent>
</template>

<script>
import AppContent from "./AppContent"
import AppTable from "./AppTable"
import AppFormDialog from "./AppFormDialog"
import LoanAgreementForm from "./employer/LoanAgreementForm"

import { ViewItemAction } from "./base/table"

export default {
  name: "LoanAgreement",

  components: {
    AppContent,
    AppTable,
    AppFormDialog,
    LoanAgreementForm,
    ViewItemAction,
  },

  props: {
    resourcePath: {
      type: String,
      default: "loanAgreements",
    },
    title: {
      type: String,
      default: "Loan Agreements",
    },
    subject: {
      type: String,
      default: "Loan Agreement",
    },
    headers: {
      type: Array,
      default: function () {
        return [
          {
            text: "Id",
            value: "id",
          },
          {
            text: "Name",
            value: "profile.full_name",
          },
          {
            text: "Repayment Option",
            value: "repayment_option",
          },
          {
            text: "Interest",
            value: "interest",
          },
          {
            text: "Status",
            value: "status",
            align: "center",
          },
          {
            text: "Action",
            value: "action",
            align: "center",
            sortable: false,
            filterable: false,
          },
        ]
      },
    },
  },

  watch: {
    search(value) {
      console.log(value)
    },
  },

  data() {
    return {
      tableItemSlotProps: {
        on: {},
      },
    }
  },

  computed: {
    permissions() {
      return JSON.parse(localStorage.getItem("permissions"))
    },
  },

  methods: {
    onApproveLoanAgreementItem(item) {
      console.log(item)
      this.$store
        .dispatch("prompt/confirm", {
          title: "Loan Approval",
          data: item,
        })
        .then((response) => {
          if (response) {
            this.$store.dispatch(`${this.resourcePath}/approve`, item)
          }
        })
    },

    onDenyLoanAgreementItem(item) {
      console.log(item)
      this.$store
        .dispatch("prompt/confirm", {
          title: "Loan Agreement Deny",
          data: item,
        })
        .then((response) => {
          if (response) {
            this.$store.dispatch(`${this.resourcePath}/deny`, item)
          }
        })
    },
  },
}
</script>
