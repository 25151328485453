import Applicants from './applicants'

const status = 'for initial interview'


export const getters = {
    ...Applicants.getters,
    getAll: state => state.all.filter(registrant => status.includes(registrant.profile?.status.toLowerCase()))
}

export const actions = {
    ...Applicants.actions,

    fetchPagination(context, payload) {
        return Applicants.actions['fetchPagination'](context, {
            ...payload,
            'filter[and][][status][eq]': status,
        })
    }
}

export default {
    ...Applicants,
    actions,
    getters
}
