<template>
  <AppForm v-bind="$props" v-on="$listeners">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="item.title"
          :error-messages="errorMessages.title"
          :rules="titleRule"
          :readonly="readonly"
          label="Title"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>
      </v-col>
    </v-row>
  </AppForm>
</template>

<script>
import AppForm from "../AppForm"

export default {
  name: "OpportunityForm",
  extends: AppForm,
  components: {
    AppForm,
  },

  data() {
    return {
      titleRule: [(v) => !!v || "Opportunity title is required"],
    }
  },
}
</script>
