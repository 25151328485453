<template>
  <AppForm
    v-bind="$props"
    v-on="$listeners"
  >
    <v-row>
      <v-col>
        <v-text-field
          :value="item.profile?.education?.institution"
          label="Name of Institution"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          :value="item.profile?.education?.diploma"
          label="Diploma / Certificate"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="4">
        <v-text-field
          :value="item.profile?.education?.first_name"
          label="First (Given) Name"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="4">
        <v-text-field
          :value="item.profile?.education?.middle_name"
          label="Second (Middle) Name"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        sm="4">
        <v-text-field
          :value="item.profile?.education?.last_name"
          label="Last (Family) Name"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6">
        <v-text-field
          :value="item.profile?.education?.field_of_study"
          label="Field of Study"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        sm="6">
        <v-text-field
          :value="item.profile?.education?.year_awarded"
          label="Year Awarded"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        sm="6">
        <v-text-field
          :value="item.profile?.education?.year_attended_from"
          label="Year Attended From"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        sm="6">
        <v-text-field
          :value="item.profile?.education?.year_attended_to"
          label="Year Attended To"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-textarea
          :value="item.profile?.education?.trainings_or_certificates"
          label="Trainings / Certificates"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-textarea>
      </v-col>
    </v-row>
  </AppForm>
</template>

<script>
import AppForm from '../AppForm'

export default {
  name: 'EducationForm',
  extends: AppForm,
  components: {
    AppForm
  }
}
</script>

