<template>
  <AppForm v-bind="$props" v-on="$listeners">
    <v-row>
      <v-col>
        <v-autocomplete
          v-model="item.profile_id"
          :error-messages="errorMessages.profile_id"
          cache-items
          label="Name"
          :persistent-placeholder="true"
          :items="items"
          :loading="loading"
          @update:search-input="searchApplicant"
          :clearable="!$props.readonly"
          hide-no-data
          hide-details="auto"
        >
        </v-autocomplete>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-textarea
          v-model="item.description"
          :error-messages="errorMessages.description"
          :persistent-placeholder="true"
          label="Description"
          rows="3"
          hide-details="auto"
        >
        </v-textarea>
      </v-col>
    </v-row>
  </AppForm>
</template>

<script>
import AppForm from "../AppForm"

export default {
  name: "LoanEligibleForm",
  extends: AppForm,
  components: {
    AppForm,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    items() {
      return this.$store.getters["applicants/getAll"].map(({ profile }) => {
        return {
          text: this.getProfileFullname(profile),
          value: profile.id,
        }
      })
    },
  },

  methods: {
    getProfileFullname(profile) {
      const { first_name, last_name } = profile
      return `${first_name} ${last_name}`
    },

    searchApplicant(text) {
      const currentSelected = this.items.find(
        ({ value }) => value === this.item.profile_id
      )

      if (text && text === currentSelected?.text) return

      this.loading = true
      this.$store.dispatch("applicants/searchByName", text).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
