<template>
  <v-dialog
    v-model="show"
    persistent>
      <h4 style="display: inline; margin-right: -210px;">Getting applicant data...</h4>
      <v-progress-circular 
        indeterminate
          size="240">
      </v-progress-circular>
  </v-dialog>
</template>

<script>
export default {
  name: 'TheLoadingDialog',
  data() {
    return {
      title: '',
      show: false,
    }
  }
}
</script>
