const icons = {
    search: 'mdi-magnify',
    downloadCsv: 'mdi-download',
    qualitfication: 'mdi-card-account-details-star',
    noResponse: 'mdi-message-bulleted-off',
    interviewResult: 'mdi-forum-remove',
    license: 'mdi-license',
    resume: 'mdi-file-account',
    transcriptOfRecords: 'mdi-school',
    notificationSuccess: 'mdi-check-circle',
    notificationFailed: 'mdi-alert-circle',
    applicantsPortal: 'mdi-account-group',
    registrants: 'mdi-account-multiple',
    interview: 'mdi-forum-outline',
    initialInterview: 'mdi-account-tie-voice',
    finalInterview: 'mdi-account-tie-voice-outline',
    approvedApplicants: 'mdi-account-multiple-check',
    processedApplicants: 'mdi-file-check',
    timePickerOk: 'mdi-check',
    timePickerCancel: 'mdi-close',
    success: 'mdi-check-circle-outline',
    warning: 'mdi-information-outline',
    delete: 'mdi-delete',
    edit: 'mdi-lead-pencil',
    view: 'mdi-file-eye',
    reload: 'mdi-reload',
    headerSelection: 'mdi-dots-vertical',
    all: 'mdi-circle-outline',
    filter: 'mdi-filter-menu',
    applicantProgressForward: 'mdi-file-move',
    applicantProgressBackward: 'mdi-file-undo',
    applicantArchive: 'mdi-archive',
    applicantDeny: 'mdi-account-cancel'
}

export default icons
