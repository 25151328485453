import Applicants from './applicants'

export const getters = {
    ...Applicants.getters,
}

export const actions = {
    ...Applicants.actions,

    fetchPagination(context, payload) {
        return Applicants.actions['fetchPagination'](context, {
            ...payload,
            'filter[and][][country][eq]': 'canada',
        })
    }
}

export default {
    ...Applicants,
    actions,
    getters
}
