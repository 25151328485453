import { mapMethods } from './api'

const ENDPOINT = 'opportunities' 

export const key = 'id' 

export default {
    ENDPOINT,
    key,
    ...mapMethods(ENDPOINT, [
        'search',
        'delete',
        'update',
        'create',
        'view'
    ]),
}
