import LoanAgreement, { key } from '../../api/loanAgreement'
import { AppString } from '../../strings'
import { Pagination } from '../common.js'

const pagination  = new Pagination(LoanAgreement)

export const state = {
    ...pagination.state,
}

export const getters = {
    ...pagination.getters
}

export const actions = {
    ...pagination.actions,

    update({ commit, dispatch }, payload) {
        // TODO: add failed notification on all api request
        return LoanAgreement.update(payload[key], payload.params).then(response => {
            if (response.status === 200) {
                commit('UPDATE_LOAN_AGREEMENT', {
                    [key]: payload[key],
                    loanAgreement: response.data
                })
                dispatch('notifications/addSuccessNotification', AppString.loanAgreementUpdated, { root: true })
            }

            return response
        })
    },

    save({ commit, dispatch }, payload) {
        return LoanAgreement.create(payload).then(response => {
            
            if (response.status === 201) {
                commit('ADD_LOAN_AGREEMENT', response.data)
                dispatch('notifications/addSuccessNotification', AppString.loanAgreementCreated, { root: true })
            }

            return response
        })
    },

    delete({ commit, dispatch }, loanAgreement) {
        return LoanAgreement.delete(loanAgreement[key]).then(response => {
            if (response.status === 204) {
                commit('DELETE_LOAN_AGREEMENT', loanAgreement)
                dispatch('notifications/addSuccessNotification', AppString.loanAgreementDeleted, { root: true })
            }

            return response
        })
    },

    view(_, loanAgreement) {
        return LoanAgreement.view(loanAgreement[key]).then(response => {
            if (response.status === 200) {
                return response.data 
            }
        })
    },

    // REVIEW: is this still used?
    searchByName({ dispatch }, payload) {
        return dispatch('fetchPagination', {
            'filter[or][][name][like]': payload,
            expand: 'profile'
        })
    },

    searchByCompany({ dispatch }, payload) {
        return dispatch('fetchPagination', {
            'filter[or][][company][like]': payload
        })
    }
}

export const mutations = {
    ...pagination.mutations,
    CHANGE_STATUS(state, { applicant, status }) {
        applicant.profile.status = status
    },

    ADD_LOAN_AGREEMENT(state, loanAgreement) { state.all.unshift(loanAgreement) },

    UPDATE_LOAN_AGREEMENT(state, payload) {
        const loanAgreement = state.all.find(loanAgreement => 
            loanAgreement[key].toString().toLowerCase() === payload[key].toString().toLowerCase())

        if (loanAgreement)
            Object.assign(loanAgreement, payload.loanAgreement)
    },

    DELETE_LOAN_AGREEMENT(state, loanAgreement) {
        const index = state.all.findIndex(item => item === loanAgreement)

        if (index >= 0) {
            state.all.splice(index, 1)
            // update pagination items length
            // --state.pagination.itemsLength
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
