<template>
  <AppForm
    v-bind="$props"
    v-on="$listeners"
  >
    <v-container>
        <v-row v-if="isModeCreate">
            <v-col
                cols="12"
                md="5">
                <v-switch
                    v-model="customJob"
                    label="New Custom Job Posting"
                    hide-details="auto">

                </v-switch>
            </v-col>

            <v-col 
                v-if="!customJob"
                cols="12"
                md="7">
              <v-text-field
                v-model="item.bank_id" 
                label="Job Bank Id"
                :error="jobBankIdErrorMessage.length > 0"
                :error-messages="jobBankIdErrorMessage || errorMessages.bank_id"
                :readonly="readonly"
                :loading="jobBankSearchLoading"
                :disabled="jobBankSearchLoading" 
                hide-details="auto"
                persistent-placeholder
                @click:clear="clearJobBankSearch"
                @keydown.enter.prevent="scrapeJob"
                >
                <template #append>
                    <v-btn
                        icon
                        @click="scrapeJob"
                            small
                        >
                        <v-icon>{{ $icons.search }}</v-icon>
                    </v-btn>
                </template>
              </v-text-field>
            </v-col>
        </v-row>

        <div v-if="hasResults || customJob || isModeView || isModeEdit">
            <v-row>
                <v-col>
                    <v-autocomplete
                        v-model="item.employer_id"
                        :error-messages="errorMessages.employer_id"
                        cache-items
                        label="Company"
                        persistent-placeholder
                        :items="items"
                        :loading="employerLoading"
                        @update:search-input="searchCompany"
                        :clearable="!$props.readonly"
                        hide-no-data
                        hide-details="auto"
                        >
                    </v-autocomplete>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                            v-model="item.title"
                            label="Title"
                            :error-messages="errorMessages.title"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-textarea
                            v-model="item.description"
                            label="Description"
                            :error-messages="errorMessages.description"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            no-resize
                            rows="4"
                            >
                    </v-textarea>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-menu
                        v-model="postedDateMenu"
                        :close-on-content-click="false"
                        :disabled="readonly"
                        offset-y
                    >
                        <template #activator="{ on, attrs }">
                            <v-text-field
                                :value="postedDateFormatted"
                                v-bind="attrs"
                                v-on="on"
                                label="Date Posted"
                                :error-messages="errorMessages.posted"
                                prepend-inner-icon="mdi-calendar-start"
                                persistent-placeholder
                                hide-details="auto"
                                readonly
                                >
                            </v-text-field>
                        </template>

                        <v-date-picker
                            v-model="item.posted"
                            @input="postedDateMenu = false">
                        </v-date-picker>
                    </v-menu>

                   <!-- <v-text-field
                            v-model="item.posted"
                            label="Post Date"
                            :error-messages="errorMessages.posted"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>-->
                </v-col>

                <v-col>
                    <v-menu
                        v-model="validThroughMenu"
                        :close-on-content-click="false"
                        :disabled="readonly"
                        offset-y
                    >
                        <template #activator="{ on, attrs }">
                            <v-text-field
                                :value="validThroughFormatted"
                                v-bind="attrs"
                                v-on="on"
                                label="Valid Through"
                                :error-messages="errorMessages.valid_through"
                                prepend-inner-icon="mdi-calendar-end"
                                persistent-placeholder
                                hide-details="auto"
                                readonly
                                >
                            </v-text-field>
                        </template>

                        <v-date-picker
                            v-model="item.valid_through"
                            @input="validThroughMenu = false">
                        </v-date-picker>
                    </v-menu>

                    <!--<v-text-field
                            v-model="item.valid_through"
                            label="Valid Through"
                            :error-messages="errorMessages.valid_through"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>-->
                </v-col>

            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                            v-model="item.noc_code"
                            label="Noc Code"
                            :error-messages="errorMessages.noc_code"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>
                </v-col>

                <v-col>
                    <v-text-field
                            v-model="item.vacancies"
                            label="Vacancies"
                            :error-messages="errorMessages.vacancies"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>
                </v-col>

                <v-col>
                    <v-text-field
                            v-model="item.median_wage"
                            label="Median Wage"
                            :error-messages="errorMessages.median_wage"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>
                </v-col>

                <v-col>
                    <v-text-field
                            v-model="item.location"
                            label="Location"
                            :error-messages="errorMessages.location"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>
                </v-col>

            </v-row>

            <v-row>
                <v-col>
                    <v-textarea
                            v-model="item.educations"
                            label="Educations"
                            :error-messages="errorMessages.educations"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            rows="1"
                            >
                    </v-textarea>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-textarea
                            v-model="item.experience"
                            label="Experience"
                            :error-messages="errorMessages.experience"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            rows="1"
                            >
                    </v-textarea>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                            v-model="item.credentials"
                            label="Credentials"
                            :error-messages="errorMessages.credentials"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-textarea
                            v-model="item.specific_skills"
                            label="Specific Skills"
                            :error-messages="errorMessages.specific_skills"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            rows="2"
                            >
                    </v-textarea>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                            v-model="item.target_audience"
                            label="Target Audience"
                            :error-messages="errorMessages.target_audience"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>
                </v-col>

                <v-col>
                    <v-text-field
                            v-model="item.security_and_safety"
                            label="Security and Safety"
                            :error-messages="errorMessages.security_and_safety"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-text-field
                            v-model="item.work_location_information"
                            label="Work Location & Information"
                            :error-messages="errorMessages.work_location_information"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>
                </v-col>

                <v-col>
                    <v-text-field
                            v-model="item.personal_suitability"
                            label="Personal Suitability"
                            :error-messages="errorMessages.personal_suitability"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>
                </v-col>

                <v-col>
                    <v-text-field
                            v-model="item.work_conditions_and_physical_capabilities"
                            label="Work Conditions"
                            :error-messages="errorMessages.work_conditions_and_physical_capabilities"
                            :readonly="readonly"
                            hide-details="auto"
                            persistent-placeholder
                            >
                    </v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-autocomplete
                        :value="item.languages ? item.languages.split(',') : ''"
                        @input="item.languages = $event.join(',')"
                        :items="languages"
                        label="Languages"
                        :error-messages="errorMessages.languages"
                        :readonly="readonly"
                        hide-details="auto"
                        persistent-placeholder
                        chips
                        small-chips
                        deletable-chips
                        multiple
                        >
                        <template #item="props">
                            {{ props.item }}
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>

            <v-row>
                <v-col
                    cols="12">
                    <v-checkbox
                        v-model="item.viewable"
                        label="Viewable"
                        hide-details="auto">

                    </v-checkbox>
                </v-col>
            </v-row>
        </div>
    </v-container>
  </AppForm>
</template>

<script>
import AppForm from '../AppForm'
import api from '@/api/api'
import moment from 'moment'

export default {
  name: 'JobBankForm',
  extends: AppForm,
  components: {
    AppForm
  },

  data() {
      return {
          postedDateMenu: false,
          validThroughMenu: false,
          employerLoading: false,
          customJob: false,
          hasResults: false,
          jobBankIdErrorMessage: '',
          jobBankSearchLoading: false,
          languages: [
              'Arabic',
              'Bengali',
              'Chinese',
              'English',
              'French',
              'German',
              'Hindi',
              'Italian',
              'Japanese',
              'Korean',
              'Lahnda',
              'Malay',
              'Persian',
              'Portuguese',
              'Russian',
              'Spanish',
              'Tamil',
              'Telugu',
              'Turkish',
              'Urdu',
              'Vietnamese',
          ]
      }
  },

  watch: {
      mode: function() {
          this.employerLoading = false
          this.customJob = false
          this.hasResults = false
          this.jobBankIdErrorMessage = ''
          this.jobBankSearchLoading = false
             
      }
  },
  
  computed: {
      postedDateFormatted() {
          return this.formatDate(this.item.posted)
      },

      validThroughFormatted() {
          return this.formatDate(this.item.valid_through)
      },

      items() {
          return this.$store.getters['employers/getAll']
            .map(employer => {
              return {
                text: employer.company,
                value: employer.id
              }
            })
      }
  },

  methods: {
    formatDate(value) {
        return value ? moment(value).format('MMMM D, YYYY') : ''
    },

    searchCompany(text) {
      const currentSelected = this.items.find(({ value }) => 
          value === this.item.payor_id
      )

      if (text && text === currentSelected?.text)
        return 

      this.employerLoading = true
      this.$store.dispatch('employers/searchByCompany', text ?? this.item.payor_name).finally(() => {
        this.employerLoading = false
      })
    },

    clearJobBankSearch() {
      this.hasResults = false
      this.jobBankIdErrorMessage = ''
    },


    scrapeJob() {
      this.clearJobBankSearch()

      if (!this.item.bank_id)
          return

      this.jobBankSearchLoading = true
        console.log(this.item.bank_id)

      api.get(`job-bank-scraper/jobs/${this.item.bank_id}`).then(({ data }) => {
        this.item.title = data.title
        this.item.posted = data.postedDate
        this.item.noc_code = data.noc_code
        this.item.valid_through = data.validThrough
        this.item.vacancies = data.vacancies
        this.item.median_wage = data.median_wage
        this.item.location = data.location
        this.item.educations = data.educations.trim()
        this.item.experience = data.experience
        this.item.credentials = data.credentials
        this.item.specific_skills = data.specific_skills
        this.item.target_audience = data.target_audience
        this.item.security_and_safety = data.security_and_safety
        this.item.work_location_information = data.work_location_information
        this.item.personal_suitability =  data.personal_suitability
        this.item.work_conditions_and_physical_capabilities = data.work_conditions_and_physical_capabilities
        this.item.languages = data.languages

        this.hasResults = true
        this.jobBankIdErrorMessage = ''

      }).catch(error => {
          console.log(error)
          this.jobBankIdErrorMessage = 'No match found.'

          this.hasResults = false

      }).finally(() => {
        this.jobBankSearchLoading = false

      }) 
    }

  }
}
</script>

