import Api, { mapMethods } from "./api";

const ENDPOINT = 'queues'

export const key = 'step'

export default {
    ENDPOINT,
    key,
    ...mapMethods(ENDPOINT, [
        'search'
    ]),

    enqueue(step, registrantId) {
        return Api.post(`${ENDPOINT}/${step}/enqueue`, {
            registrant_id: registrantId
        })
    },

    dequeue(step) {
        return Api.post(`${ENDPOINT}/${step}/dequeue`)
    }
}
