<template>
  <v-container class="pa-0">
    <v-row>
      <v-col xl="3">
        <v-card
          :height="cardHeight"
          :to="{ name: 'Applicants' }"
          min-width="380"
          color="blue darken-2"
        >
          <v-card-text class="white--text">
            <v-container>
              <v-row>
                <v-col sm="12">
                  <v-row>
                    <v-col cols="3">
                      <v-icon size="44" color="white">
                        {{ this.$icons.applicantsPortal }}
                      </v-icon>
                    </v-col>

                    <v-col cols="9">
                      <div class="text-overline">Total no. of Applicants</div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col class="text-h1 text-center">
                      {{ applicantsCount }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xl="3">
        <v-card
          min-width="380"
          :height="cardHeight"
          :to="{
            name: 'Applicants',
            params: { customSearch: 'submissions:filled status:new' },
          }"
          color="orange"
          dark
        >
          <v-card-text class="white--text">
            <v-container
              ><v-row>
                <v-col sm="12">
                  <v-row>
                    <v-col cols="3">
                      <v-icon size="44">
                        {{ this.$icons.applicantsPortal }}
                      </v-icon>
                    </v-col>

                    <v-col cols="9">
                      <div class="text-overline">
                        Submitted Application Forms
                      </div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col class="text-h1 text-center">
                      {{ completedCredentialApplicants }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row></v-container
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xl="3">
        <v-card
          min-width="380"
          :height="cardHeight"
          :to="{ name: 'InitialInterview' }"
          color="teal"
          dark
        >
          <v-card-text class="white--text">
            <v-container>
              <v-row>
                <v-col sm="12">
                  <v-row>
                    <v-col cols="3">
                      <v-icon size="44">
                        {{ this.$icons.interview }}
                      </v-icon>
                    </v-col>

                    <v-col cols="9">
                      <div class="text-overline">Interviewees</div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col class="text-h1 text-center">
                      {{ intervieweesCount }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xl="3">
        <v-card
          min-width="380"
          :height="cardHeight"
          :to="{ name: 'Approved' }"
          color="purple lighten-1"
          dark
        >
          <v-card-text class="white--text">
            <v-container
              ><v-row>
                <v-col sm="12">
                  <v-row>
                    <v-col cols="3">
                      <v-icon size="44">
                        {{ this.$icons.approvedApplicants }}
                      </v-icon>
                    </v-col>

                    <v-col cols="9">
                      <div class="text-overline">Approved Applicants</div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col class="text-h1 text-center">
                      {{ approvedCount }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row></v-container
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xl="6">
        <v-card
          min-width="380"
          :height="cardHeight"
          :to="{ name: 'DomesticApplicants' }"
          color="red lighten-1"
          dark
        >
          <v-card-text class="white--text">
            <v-container
              ><v-row>
                <v-col>
                  <v-row>
                    <v-col cols="3">
                      <v-icon size="44"> mdi-leaf-maple </v-icon>
                    </v-col>

                    <v-col cols="9">
                      <div class="text-overline">Domestic</div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col class="text-h1 text-center">
                      {{ domesticCount }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row></v-container
            >
          </v-card-text>
        </v-card>
      </v-col>

      <v-col xl="6">
        <v-card
          min-width="380"
          :height="cardHeight"
          color="cyan lighten-1"
          :to="{
            name: 'Applicants',
            params: {
              customSearch: 'country:empty country!=canada',
            },
          }"
          dark
        >
          <v-card-text class="white--text">
            <v-container>
              <v-row>
                <v-col sm="12">
                  <v-row>
                    <v-col cols="3">
                      <v-icon size="44"> mdi-earth </v-icon>
                    </v-col>

                    <v-col cols="9">
                      <div class="text-overline">International</div>
                    </v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col class="text-h1 text-center">
                      {{ internationalCount }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!--<v-row>
      <v-col>
        <v-card>
          <v-card-title>
            New Registrants in the last {{ value.length }} Days
          </v-card-title>

          <v-sparkline
            v-model="value"
            :labels="labels"
            :gradient="['green', 'lightgreen', 'yellow', 'orange']"
            line-width="1"
            stroke-linecap="round"
            label-size="2"
            smooth="5"
            auto-draw
          >
          </v-sparkline>
        </v-card>
      </v-col>
    </v-row>-->

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <LineChart
              :options="chartOptions"
              :chart-data="chartData"
              chart-id="line-chart"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row> -->
    <!--   <v-col> -->
    <!--     <v-card> -->
    <!--       <v-card-title> -->
    <!--         Interviewees in the last month -->
    <!--       </v-card-title> -->

    <!--       <v-sparkline -->
    <!--         v-model="intervieweesLastMonth" -->
    <!--         :labels="intervieweesLastMonthLabels" -->
    <!--         line-width="1" -->
    <!--         stroke-linecap="round" -->
    <!--         label-size="2" -->
    <!--         smooth="5" -->
    <!--         auto-draw -->
    <!--         > -->
    <!--       </v-sparkline> -->
    <!--     </v-card> -->
    <!--   </v-col> -->
    <!-- </v-row> -->
  </v-container>
</template>

<script>
import { Line as LineChart } from "vue-chartjs"
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js"
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
)
// TODO: Refactor this mess of a code
export default {
  name: "Dashboard",
  components: {
    LineChart,
  },

  data() {
    return {
      // sparkline options
      value: [],
      labels: [],

      chartData: {
        labels: [
          "2015-01",
          "2015-02",
          "2015-03",
          "2015-04",
          "2015-05",
          "2015-06",
          "2015-07",
          "2015-08",
          "2015-09",
          "2015-10",
          "2015-11",
          "2015-12",
        ],
        datasets: [
          {
            label: "Daily Registrants",
            borderWidth: 1,
            pointBorderColor: "#2554ff",
            pointBackgroundColor: "#2554ff",
            backgroundColor: "#2554ff",
            borderColor: "#2554ff",
            data: [12, 19, 3, 5, 2, 3, 20, 3, 5, 6, 2, 1],
          },
        ],
      },
      chartOptions: {
        responsive: true,
      },

      intervieweesLastMonth: [],
      intervieweesLastMonthLabels: [],
      //

      cardHeight: 190,
      applicantsCount: 0,
      intervieweesCount: 0,
      approvedCount: 0,
      completedCredentialApplicants: 0,
      profileCompletedApplicants: 0,
      domesticCount: 0,
      internationalCount: 0,
    }
  },

  mounted() {
    this.$axios
      .get("dashboard/index")
      .then(({ data }) => {
        this.applicantsCount = data.no_of_applicants
        this.completedCredentialApplicants =
          data.no_of_applicants_completed_application
        this.intervieweesCount = data.interviews_count
        this.approvedCount = data.approved_count
        this.domesticCount = data.domestic
        this.internationalCount = data.international
        this.labels = data.sparkline.map((i) =>
          i.date.substr(-5).replace("-", "/")
        )
        this.value = data.sparkline.map((i) => parseInt(i.count))

        this.chartData.datasets[0].data = this.value
        this.chartData.labels = this.labels
        console.log(this.value, this.labels)

        this.intervieweesLastMonthLabels = data.interviewees_lastmonth.map(
          (i) => i.date.substr(-5).replace("-", "/")
        )
        this.intervieweesLastMonth = data.interviewees_lastmonth.map((i) =>
          parseInt(i.count)
        )
      })
      .catch((error) => {
        console.log(error)
      })

    // console.log(Chart)
    // const ctx = document.getElementById("chart")

    // new Chart(ctx, {
    //   type: "bar",
    //   data: {
    //     labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    //     datasets: [
    //       {
    //         label: "# of Votes",
    //         data: [12, 19, 3, 5, 2, 3],
    //         borderWidth: 1,
    //       },
    //     ],
    //   },
    //   options: {
    //     scales: {
    //       y: {
    //         beginAtZero: true,
    //       },
    //     },
    //   },
    // })
  },
}
</script>
