import { actions, mapMethods } from './api'

const ENDPOINT = 'job-bank' // TEMP: 

export const key = 'id' 

export default {
    ENDPOINT,
    key,
    ...mapMethods(ENDPOINT, [
        'search',
        'delete',
        'update',
        'create'
    ]),
    view(id) {
        return actions(ENDPOINT).view(id, {
            expand: 'employer'
        
        })
    },
    // setViewability(value) {
    //     return Api.put(`${ENDPOINT}/${id}/viewable`)
    // }
}
