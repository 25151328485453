<template>
  <v-row>
    <v-col cols="12">
      <MeetingsCalendar />
      <!-- <v-btn color="blue" class="white--text" large rounded @click="zoomAuth" -->
      <!--   ><v-icon>mdi-video</v-icon> Link Zoom Account -->
      <!-- </v-btn> -->
    </v-col>

    <AppFormDialog subject="Meeting" :value="showDialog" width="800">
      <template #form>
        <CalendarEventForm />
      </template>

      <template #activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn
            color="green"
            v-bind="attrs"
            v-on="on"
            @click="onCreate"
            bottom
            dark
            fab
            fixed
            large
            right
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </template>
    </AppFormDialog>
  </v-row>
</template>

<script>
import AppFormDialog from "./AppFormDialog"
import MeetingsCalendar from "./MeetingsCalendar"
import CalendarEventForm from "./meeting/CalendarEventForm"

let authWindow, timer

export default {
  name: "Meetings",
  components: {
    AppFormDialog,
    MeetingsCalendar,
    CalendarEventForm,
  },

  data() {
    return {
      showDialog: false,
    }
  },

  methods: {
    zoomAuth() {
      const height = 640
      const width = 480
      const top = (window.innerHeight - height) / 2
      const left = (window.innerWidth - width) / 2

      authWindow = window.open(
        "https://zoom.us/oauth/authorize?response_type=code&client_id=PVdK3KRiSAWC60usdTkjhQ&redirect_uri=http://aximahealth.lan/zoom/redirect",
        "joom",
        `popup=true,width=${width},height=${height},top=${top},left=${left}`
      )
      authWindow.focus()

      if (!timer) {
        timer = setInterval(function () {
          if (authWindow && authWindow.closed) {
            clearInterval(timer)
          }
        }, 1000)
      }
    },
    onCreate() {
      this.showDialog = true
    },
  },
}
</script>
