import Job, { key } from '../../api/job'
import { AppString } from '../../strings'
import { Pagination } from '../common.js'
// import lodash from 'lodash'

const pagination  = new Pagination(Job)

export const state = {
    ...pagination.state,
}

export const getters = {
    ...pagination.getters
}

export const actions = {
    ...pagination.actions,

    fetchPagination(context, payload) {
        return pagination.actions.fetchPagination(context, {
            ...payload,
            expand: 'employer'
        })
    },
    update({ commit, dispatch }, payload) {
        // TODO: add failed notification on all api request
        return Job.update(payload[key], payload.params).then(response => {
            if (response.status === 200) {
                commit('UPDATE_JOB', {
                    [key]: payload[key],
                    job: response.data
                })
                dispatch('notifications/addSuccessNotification', AppString.jobUpdated, { root: true })
            }

            return response
        })
    },

    save({ commit, dispatch }, payload) {
        return Job.create(payload).then(response => {
            
            if (response.status === 201) {
                commit('ADD_JOB', response.data)
                dispatch('notifications/addSuccessNotification', AppString.jobCreated, { root: true })
            }

            return response
        })
    },

    delete({ commit, dispatch }, job) {
        return Job.delete(job[key]).then(response => {
            if (response.status === 204) {
                commit('DELETE_JOB', job)
                dispatch('notifications/addSuccessNotification', AppString.jobDeleted, { root: true })
            }

            return response
        })
    },

    view(_, job) {
        return Job.view(job[key]).then(response => {
            if (response.status === 200) {
                const data = response.data
                return data

                // return {
                //     id: data?.id,
                //     title: lodash.startCase(lodash.toLower(data?.title)),
                //     jobBankId: data?.bank_id,
                //     postedDate: data?.posted,
                //     nocCode: data?.noc_code,
                //     validThrough: data?.valid_through,
                //     vacancies: data?.vacancies,
                //     medianWage: data?.median_wage,
                //     location: data?.location,
                //     educations: data?.educations?.trim(),
                //     experience: data?.experience,
                //     credentials: data?.credentials,
                //     specific_skills: data?.specific_skills,
                //     target_audience: data?.target_audience,
                //     security_and_safety: data?.security_and_safety,
                //     work_location_information: data?.work_location_information,
                //     personal_suitability:  data?.personal_suitability,
                //     work_conditions_and_physical_capabilities: data?.work_conditions_and_physical_capabilities,
                //     languages: data?.languages,
                // } 
            }
        })
    },
    searchByTitle({ dispatch }, payload) {
        return dispatch('fetchPagination', {
            'filter[or][][title][like]': payload,
        })
    },
}

export const mutations = {
    ...pagination.mutations,
    CHANGE_STATUS(state, { applicant, status }) {
        applicant.profile.status = status
    },

    ADD_JOB(state, job) { state.all.unshift(job) },

    UPDATE_JOB(state, payload) {
        const job = state.all.find(job => 
            job[key].toString().toLowerCase() === payload[key].toString().toLowerCase())

        if (job)
            Object.assign(job, payload.job)
    },
    
    VIEWABLE_JOB(state, { job, value }) {
        job.viewable = value
        // Object.assign(job, { viewable: value })
    },

    DELETE_JOB(state, job) {
        const index = state.all.findIndex(item => item === job)

        if (index >= 0) {
            state.all.splice(index, 1)
            // update pagination items length
            // --state.pagination.itemsLength
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
