<template>
  <v-card>
    <v-card-text>
      <v-data-table
        :headers="filteredHeaders"
        :items="items"
        :loading-text="$strings.tableLoadingText"
        :loading="loading"
        :server-items-length="pagination.itemsLength"
        @update:sort-by="options.sortBy = $event"
        @update:sort-desc="options.sortDesc = $event"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="mr-4">{{ title }}</v-toolbar-title>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :loading="loading"
                  @click="refresh"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                  icon
                  plain
                  small
                >
                  <v-icon color="blue">{{ $icons.reload }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $strings.reloadTableTooltip }}</span>
            </v-tooltip>

            <v-tooltip v-if="downloadable" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  plain
                  @click="downloadCsv"
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <v-icon color="blue">{{ $icons.downloadCsv }}</v-icon>
                </v-btn>
              </template>
              <span>{{ $strings.downloadCsvHint }}</span>
            </v-tooltip>

            <v-menu
              transition="slide-y-transition"
              offset-x
              bottom
              :close-on-content-click="false"
            >
              <template #activator="{ on: onMenu, attrs: attrsMenu }">
                <v-tooltip bottom>
                  <template
                    v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }"
                  >
                    <v-btn
                      icon
                      plain
                      v-bind="{ ...attrsMenu, ...attrsTooltip }"
                      v-on="{ ...onMenu, ...onTooltip }"
                      small
                    >
                      <v-icon color="blue">{{ $icons.headerSelection }}</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $strings.showHideHeaders }}</span>
                </v-tooltip>
              </template>

              <v-card>
                <v-subheader class="text-overline">
                  {{ $strings.showHideHeaders }}
                </v-subheader>
                <v-list subheader dense flat>
                  <v-list-item-group v-model="selectedHeaderIndices" multiple>
                    <v-list-item
                      v-for="(header, index) in headers"
                      :key="index"
                    >
                      <template #default="{ active }">
                        <v-list-item-action>
                          <v-checkbox :input-value="active"> </v-checkbox>
                        </v-list-item-action>
                        <v-list-item-title>
                          {{ header.text }}
                        </v-list-item-title>
                      </template>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-menu>

            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              :prepend-inner-icon="$icons.search"
              :disabled="loading"
              clearable
              solo
              dense
              hide-details
              @click:clear="clearSearch($event.target.value)"
              @keyup.enter="onSearch"
            >
              <template #label>
                <span class="font-italic subtitle-2">{{
                  $strings.searchLabel
                }}</span>
              </template>
            </v-text-field>

            <slot name="append-buttons"> </slot>
          </v-toolbar>
        </template>

        <template
          v-for="header in headers"
          #[`header.${header.value}`]="slotProps"
        >
          <slot :name="`header.${header.value}`" v-bind="slotProps">
            <span :key="header.value">{{ header.text }}</span>
          </slot>
        </template>

        <template
          v-for="(header, index) in headers"
          #[`item.${header.value}`]="slotProps"
        >
          <!-- TODO: move on to data -->
          <slot
            :name="`item.${header.value}`"
            v-bind="slotProps"
            :on="{
              'click:edit-item': onEdit,
              'click:view-item': onView,
              'click:delete-item': onDelete,
            }"
          >
            <span
              v-if="
                header.customValue && typeof header.customValue === 'function'
              "
              :key="index"
            >
              {{ header.customValue(slotProps.item) }}
            </span>

            <div
              v-else-if="header.value === 'action' && !hideItemActions"
              :key="index"
            >
              <v-btn
                v-if="itemActions.includes('view')"
                icon
                plain
                @click="onView(slotProps.item)"
              >
                <v-icon color="blue">{{ $icons.view }}</v-icon>
              </v-btn>

              <v-btn
                v-if="itemActions.includes('edit')"
                icon
                plain
                @click="onEdit(slotProps.item)"
              >
                <v-icon color="blue">{{ $icons.edit }}</v-icon>
              </v-btn>

              <v-btn
                v-if="itemActions.includes('delete')"
                icon
                plain
                @click="onDelete(slotProps.item)"
              >
                <v-icon color="blue">{{ $icons.delete }}</v-icon>
              </v-btn>
            </div>

            <span v-else :key="index">
              {{ slotProps.value }}
            </span>
          </slot>
        </template>

        <template #footer>
          <AppTableFooter
            :loading="loading"
            :options.sync="options"
            :pagination="pagination"
          />
        </template>

        <slot></slot>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import AppTableFooter from "./AppTableFooter.vue"

export default {
  name: "AppTable",

  components: {
    AppTableFooter,
  },

  props: {
    headers: Array,
    title: String,
    params: Object,
    customSearch: String,
    resourcePath: String,
    csvDownloadUrl: String,
    hideItemActions: Boolean,
    downloadable: Boolean,

    itemKey: {
      type: String,
      default: "id",
    },

    itemActions: {
      type: Array,
      default: function () {
        return ["view", "edit", "delete"]
      },
    },
  },

  data() {
    return {
      selectedHeaderIndices: [],
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      loading: false,
      search: "",
    }
  },

  watch: {
    options: {
      handler() {
        this.refresh()
      },
      deep: true,
    },
  },

  computed: {
    filteredHeaders() {
      return this.headers.filter((_, index) =>
        this.selectedHeaderIndices.includes(index)
      )
    },

    pagination() {
      return this.$store.getters[`${this.resourcePath}/getPagination`]
    },

    items() {
      return this.$store.getters[`${this.resourcePath}/getAll`]
    },
  },

  methods: {
    clearSearch(value) {
      this.search = value

      if (window.location.search) this.$router.replace({ query: null })

      this.refresh()
    },

    toggleHeaderVisibility(header) {
      header.visible = !header.visible
    },

    onSearch() {
      this.refresh()
    },

    onView(item) {
      const event = "click:view-item"

      if (event in this.$listeners) {
        this.$emit(event, item)

        return
      }

      this.view(item)
    },

    view(item) {
      const event = "click:view-item"

      return this.$axios
        .get(`${this.resourcePath}/${item[this.itemKey]}`)
        .then((response) => {
          if (response.status === 200) {
            this.$emit(event, item)
          }
        })
    },

    onEdit(item) {
      this.$emit("click:edit-item", item)
    },

    onDelete(item) {
      const event = "click:delete-item"

      if (event in this.$listeners) {
        this.$emit(event, item)

        return
      }

      this.delete(item)
    },

    delete(item) {
      return this.$store.dispatch(`${this.resourcePath}/delete`, item)
    },

    refresh() {
      const customFilter = this.normalizeSearchQuery()
      this.loading = true

      // TODO: Encapsulate
      let filter = this.search
        ? this.filterable.reduce(
            (obj, field) =>
              Object.assign(obj, {
                [`filter[or][][${field}][like]`]: this.search.trim(),
              }),
            {}
          )
        : ""

      if (
        typeof customFilter !== "undefined" &&
        Object.keys(customFilter).length > 0
      ) {
        filter = customFilter
      }

      const sortColumn = Array.isArray(this.options.sortBy)
        ? this.options.sortBy[0]
        : this.options.sortBy

      const sort =
        (this.options?.sortDesc ? "" : "-") + this.childKey(sortColumn)

      this.$store
        .dispatch(`${this.resourcePath}/fetchPagination`, {
          sort,
          page: this.options.page,
          "per-page": this.options.itemsPerPage,
          ...filter,
          ...this.params,
        })
        .finally(() => {
          this.loading = false
        })
    },

    downloadCsv() {
      this.$axios
        .get(this.csvUrl, {
          responseType: "blob",
        })
        .then((response) => {
          const { data } = response
          const url = window.URL.createObjectURL(data)
          const link = document.createElement("a")

          link.href = url
          link.setAttribute("download", `${this.title.toLowerCase()}.csv`)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },

    /*
     * Parse search query
     *
     * Parse example:
     * search text: "email:sample@email.com country:Philippines"
     * {
     *    email: "sample@email.com",
     *    country: "Philippines"
     * }
     */
    normalizeSearchQuery() {
      if (!this.search) return

      const search = this.search.toLowerCase()
      /* const matches = search.trim().match(/\w+(:|!=).+?(?=\s*\w+:|$)/g) */
      const matches = search
        .trim()
        .matchAll(/(\w+)(:|!=)(.+?)(?=\s*\w+(?=:|!=)|$)/g)

      const entries = Array.from(matches).reduce((prev, match) => {
        if (typeof prev[match[1]] === "undefined") prev[match[1]] = []
        prev[match[1]].push([match[2], match[3]])
        return prev
      }, {})

      const filter = {}

      for (let key in entries) {
        let operator = entries[key].length > 1 ? "or" : "and"
        for (let i = 0; i < entries[key].length; ++i) {
          let clause = entries[key][i]
          let equalityOp = this.getEqualityOperator(clause[0])
          filter[`filter[${operator}][][${key}][${equalityOp}]`] = clause[1]
        }
      }

      return filter

      // const entries = matches?.map(match => match.split(':'))

      // if (entries)
      //   return Object.fromEntries(entries)
    },

    getEqualityOperator(value) {
      switch (value) {
        case ":":
          return "like"
        case "!=":
          return "neq"
        default:
          throw "UnknownOperation"
      }
    },

    childKey(string) {
      if (!string || string.length === 0) return ""

      const keys = string.split(".")

      return keys[keys.length - 1]
    },
  },

  created() {
    // TODO: Fix searching not applying recent unchecked then checked header
    this.filterable = this.headers
      .filter(
        (header) =>
          header.filterable || typeof header.filterable === "undefined"
      )
      .map((header) => this.childKey(header.value))

    this.selectedHeaderIndices = this.headers
      .map((header, index) => {
        if (typeof header.visible === "undefined" || header.visible === true)
          return parseInt(index)
      })
      .filter((key) => key >= 0)

    // Place router query into search text field for execution on refresh
    this.search = this.customSearch
    // this.search = window.location.search.substring(1).replaceAll('=', ':').split('&').join(' ')
    this.refresh()
  },
}
</script>
