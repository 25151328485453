<template>
  <v-dialog v-bind="$attrs" v-model="show">
    <v-card :loading="loading">
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-file-input
          v-model="file"
          :accept="accept"
          prepend-icon="mdi-paperclip"
          outlined
          show-size
          hide-details="auto"
          :error-messages="errorMessages.file"
        >
        </v-file-input>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('click:close')" text color="error"> Close </v-btn>
        <v-btn
          :loading="loading"
          :disabled="!file"
          @click="$emit('click:upload', file)"
          text
          color="primary"
        >
          <v-icon left>mdi-cloud-upload</v-icon>Upload
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FileUploadDialog",

  props: {
    errorMessages: {
      type: Object,
      default: function () {
        return {}
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },

    accept: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      file: null,
    }
  },

  watch: {
    value(newValue) {
        if (newValue) {
            this.file = null
        }
    }
  },

  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
  },
}
</script>
