<template>
  <v-dialog :value="show" max-width="290" persistent>
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
        <!--<v-spacer></v-spacer>
        <v-icon>{{ icon }}</v-icon>-->
      </v-card-title>

      <v-card-text>
        <component
          v-if="component"
          :is="component"
          v-bind="data"
          @change:extra="extraData = $event"
        >
        </component>
        <div v-else>
          {{ message }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn @click="affirm" :loading="loading" text>
          <v-icon color="primary" left>mdi-check</v-icon>
          {{ affirmText }}
        </v-btn>

        <v-btn @click="deny" text>
          <v-icon color="warning" left>mdi-cancel</v-icon>
          {{ denyText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "TheConfirmationDialog",
  data() {
    return {
      affirmText: "Yes",
      denyText: "No",
      extraData: {},
    }
  },

  computed: {
    ...mapGetters("prompt", [
      "show",
      "title",
      "message",
      "loading",
      "component",
      "data",
      "icon",
    ]),
  },

  methods: {
    close() {
      this.$store.dispatch("prompt/close")
    },

    affirm() {
      this.$store.dispatch("prompt/agree", this.extraData)
    },

    deny() {
      this.$store.dispatch("prompt/deny")
    },
  },
}
</script>
