<template>
  <AppContent
    v-bind="$props"
    v-on="$listeners"
    item-key="name"
    :form-dialog-props="{ maxWidth: 700 }"
    :table-props="{
      headers,
    }"
    @error-response="errorMessages = $event"
    ref="appContent"
  >
    <template #table="{ attrs, on }">
      <AppTable v-bind="{ ...attrs, ...$props }" v-on="on"> </AppTable>
    </template>

    <template #formDialog="formDialogSlotProps">
      <AppFormDialog
        v-bind="formDialogSlotProps.attrs"
        v-on="formDialogSlotProps.on"
      >
        <template #form="slotProps">
          <PaymentV2Form v-bind="slotProps" :error-messages="errorMessages">
          </PaymentV2Form>
        </template>
      </AppFormDialog>
    </template>
  </AppContent>
</template>

<script>
import AppContent from "./AppContent"
import AppTable from "./AppTable"
import AppFormDialog from "./AppFormDialog"
import PaymentV2Form from "./payment/PaymentV2Form"
import moment from "moment"

// import { DeleteItemAction } from "./base/table"

export default {
  name: "Payments",

  components: {
    AppContent,
    AppTable,
    AppFormDialog,
    PaymentV2Form,
    // DeleteItemAction,
  },

  props: {
    resourcePath: {
      type: String,
      default: "payments",
    },
    title: {
      type: String,
      default: "Payments",
    },
    subject: {
      type: String,
      default: "Payment",
    },
    headers: {
      type: Array,
      default: function () {
        return [
          {
            text: "Id",
            value: "id",
          },
          {
            text: "Invoice No",
            value: "invoice_no",
          },
          {
            text: "Payor Name",
            value: "payor_name",
          },
          {
            text: "Description",
            value: "description",
          },
          {
            text: "Payment Type",
            value: "type",
          },
          {
            text: "Amount",
            value: "amount",
            align: "right",
            filterable: false,
            customValue: (item) => {
              return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(item.amount)
            },
          },
          {
            text: "Medium",
            value: "medium",
          },
          {
            text: "Paid Date",
            value: "paid_datetime",
            customValue: (item) => {
              return moment.parseZone(item.paid_datetime).format("LLLL (UTC Z)")
            },
          },
          {
            text: "Actions",
            value: "action",
            align: "center",
            sortable: false,
            filterable: false,
          },
        ]
      },
    },
  },

  watch: {
    search(value) {
      console.log(value)
    },
  },

  data() {
    return {
      tableItemSlotProps: {
        on: {},
      },
      errorMessages: {},
    }
  },

  computed: {
    permissions() {
      return JSON.parse(localStorage.getItem("permissions"))
    },
  },
}
</script>
