import Meeting, { key } from '../../api/meeting'
import { AppString } from '../../strings'
import { Pagination } from '../common.js'

const pagination  = new Pagination(Meeting)

export const state = {
    ...pagination.state,
}

export const getters = {
    ...pagination.getters
}

export const actions = {
    ...pagination.actions,

        fetchPagination({ commit }, payload) {
            return Meeting.search(payload).then(response => {
                let data = response.data

                commit('SET_ALL', data.meetings)

                return response
            }).catch(error => {
                if (error.response.status === 422)
                    // TODO: Refactor
                    commit('SET_PAGINATION', {
                        page: 0,
                        pageCount: 0,
                        itemsLength: 0,
                        itemsPerPage: 0
                    })

                return error
            })
        },

    update({ commit, dispatch }, payload) {
        // TODO: add failed notification on all api request
        return Meeting.update(payload[key], payload.params).then(response => {
            if (response.status === 200) {
                commit('UPDATE_EMPLOYER', {
                    [key]: payload[key],
                    employer: response.data
                })
                dispatch('notifications/addSuccessNotification', AppString.employerUpdated, { root: true })
            }

            return response
        })
    },

    save({ commit, dispatch }, payload) {
        return Meeting.create(payload).then(response => {
            
            if (response.status === 201) {
                commit('ADD_EMPLOYER', response.data)
                dispatch('notifications/addSuccessNotification', AppString.employerCreated, { root: true })
            }

            return response
        })
    },

    delete({ commit, dispatch }, employer) {
        return Meeting.delete(employer[key]).then(response => {
            if (response.status === 204) {
                commit('DELETE_EMPLOYER', employer)
                dispatch('notifications/addSuccessNotification', AppString.employerDeleted, { root: true })
            }

            return response
        })
    },

    view(_, employer) {
        return Meeting.view(employer[key]).then(response => {
            if (response.status === 200) {
                return response.data 
            }
        })
    },

    // REVIEW: is this still used?
    searchByName({ dispatch }, payload) {
        return dispatch('fetchPagination', {
            'filter[or][][name][like]': payload,
            expand: 'profile'
        })
    },

    searchByCompany({ dispatch }, payload) {
        return dispatch('fetchPagination', {
            'filter[or][][company][like]': payload
        })
    }
}

export const mutations = {
    ...pagination.mutations,
    CHANGE_STATUS(state, { applicant, status }) {
        applicant.profile.status = status
    },

    ADD_EMPLOYER(state, employer) { state.all.unshift(employer) },

    UPDATE_EMPLOYER(state, payload) {
        const employer = state.all.find(employer => 
            employer[key].toString().toLowerCase() === payload[key].toString().toLowerCase())

        if (employer)
            Object.assign(employer, payload.employer)
    },

    DELETE_EMPLOYER(state, employer) {
        const index = state.all.findIndex(item => item === employer)

        if (index >= 0) {
            state.all.splice(index, 1)
            // update pagination items length
            // --state.pagination.itemsLength
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
