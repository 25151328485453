import DeniedApplicant, { key } from '../../api/deniedApplicant'
import { AppString } from '../../strings'
import { Pagination } from '../common.js'

const pagination  = new Pagination(DeniedApplicant)

export const state = {
    ...pagination.state,
}

export const getters = {
    ...pagination.getters
}

export const actions = {
    ...pagination.actions,

    fetchPagination(context, payload) {
        return pagination.actions.fetchPagination(context, {
            ...payload,
            expand: 'profile.rating,profile.education,profile.ielts,profile.application,profile.address,profile.referrer,profile.immigration'
        })
    },

    download({ state }) {
        return DeniedApplicant.download(state.filter)
    },

    restore({ commit, dispatch }, registrant) {
        return DeniedApplicant.restore(registrant[key]).then(response => {
            if (response.status === 200) {
                commit('RESTORE_REGISTRANT', registrant)
                dispatch('notifications/addSuccessNotification', AppString.registrantRestored, { root: true })
            }

            return response
        })
    },


    // NOTE: Must be the same with modules/applicants.js
    // TODO: Refactor, call view from modules/applicants.js perhaps?
    view(_, registrant) {
        return DeniedApplicant.view(registrant[key]).then(response => {
            if (response.status === 200) {
                const data = response.data
                return data

                // return {
                //     id: data.id,
                //     profiledId: data.profile?.id,
                //     firstName: data.profile?.first_name,
                //     middleName: data.profile?.middle_name,
                //     lastName: data.profile?.last_name,
                //     gender: data.profile?.gender,
                //     phoneNumber: data.profile?.phone_number,
                //     birthdate: data.profile?.birthdate,
                //     code: data.profile?.code,
                //     email: data.profile?.email,
                //     street: data.profile?.address?.street,
                //     city: data.profile?.address?.city,
                //     state: data.profile?.address?.state,
                //     zip: data.profile?.address?.zip,
                //     country: data.profile?.address?.country,
                //     dateRegistered: data.created_at,

                //     resume: data.profile?.application?.resume,
                //     transcriptOfRecords: data.profile?.application?.transcript_of_record,
                //     license: data.profile?.application?.license,

                //     policeClearance: data.profile?.immigration?.police_clearance, 
                //     medicalClearance: data.profile?.immigration?.medical_clearance, 
                //     vaccinationCard: data.profile?.immigration?.vaccination_card,
                //     statementOfAccount: data.profile?.immigration?.statement_of_account,
                //     birthCertificate: data.profile?.immigration?.birth_certificate,
                //     passportPhoto: data.profile?.immigration?.passport_photo,
                //     visaApplicationPhoto: data.profile?.immigration?.visa_application_photo,
                //     idPicture: data.profile?.immigration?.id_picture,

                //     workPermit: data.profile?.immigration?.work_permit,
                //     familyInfo: data.profile?.immigration?.family_info,
                //     statutoryDeclaration: data.profile?.immigration?.statutory_declaration,
                //     useOfRepresentative: data.profile?.immigration?.use_of_representative,
                //     authReleasePersonalInfo: data.profile?.immigration?.auth_release_personal_info,

                //     howDidYouHeardAboutUs: data.profile?.application?.how_did_you_heard_about_us,
                //     desiredPosition: data.profile?.application?.desired_position,
                //     availableStartDate: data.profile?.application?.available_start_date,

                //     institution: data.profile?.education?.institution,
                //     diplomaCertificate: data.profile?.education?.diploma,
                //     diplomaFirstName: data.profile?.education?.first_name,
                //     diplomaMiddleName: data.profile?.education?.middle_name,
                //     diplomaLastName: data.profile?.education?.last_name,
                //     fieldOfStudy: data.profile?.education?.field_of_study,
                //     yearAwarded: data.profile?.education?.year_awarded,
                //     yearAwardedFrom: data.profile?.education?.year_awarded_from,
                //     yearAwardedTo: data.profile?.education?.year_awarded_to,
                //     trainingsCertificates: data.profile?.education?.trainings_or_certificates,


                //     ieltsTestType: data.profile?.ielts?.test_type,
                //     ieltsTestFormat: data.profile?.ielts?.test_format,
                //     ieltsTestDate: data.profile?.ielts?.test_date,
                //     ieltsTestReferenceId: data.profile?.ielts?.reference_id,
                //     ieltsListening: data.profile?.ielts?.listening_result,
                //     ieltsReading: data.profile?.ielts?.reading_result,
                //     ieltsWriting: data.profile?.ielts?.writing_result,
                //     ieltsSpeaking: data.profile?.ielts?.speaking_result,

                //     processingTime: data.profile?.processing_time,
                // } 
            }
        })
    },

    searchByName({ dispatch }, payload) {
        return dispatch('fetchPagination', {
            'filter[or][][name][like]': payload,
            expand: 'profile'
        })
    },
}

export const mutations = {
    ...pagination.mutations,

    // REVIEW: do we really need this?
    RESTORE_REGISTRANT(state, registrant) {
        registrant.denied_at = null

        const app = state.all.find(item => item.id === registrant.id)
        if (!app)
            app.denied_at = null

        const index = state.all.findIndex(item => item === registrant)

        if (index >= 0) {
            state.all.splice(index, 1)
            // update pagination items length
            // --state.pagination.itemsLength
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
