import { mapMethods } from './api'

const ENDPOINT = 'employer' // TEMP: 

export const key = 'id' 

export default {
    ENDPOINT,
    key,
    ...mapMethods(ENDPOINT, [
        'view',
        'search',
        'delete',
        'update',
        'create'
    ]),
}
