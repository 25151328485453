<template>
  <AppForm
    v-bind="$props"
    v-on="$listeners"
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-switch
          v-model="item.darkMode"
          hide-details="auto"
          :persistent-placeholder="true"
          >
          <template #label>
            <v-chip
            small
            disabled>Dark mode</v-chip>
          </template>
        </v-switch>
      </v-col>
      <v-col
        cols="12"
      >
        <v-select
          v-model="item.timezone"
          :error-messages="errorMessages.timezone"
          label="Timezone"
          :items="timezones"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-select>
      </v-col>
    </v-row>
  </AppForm>
</template>

<script>

import AppForm from '../AppForm'

export default {
  name: 'UserSettingsForm',
  extends: AppForm,
  components: {
    AppForm,
  },

  data() {
    return {
      timezones: [
        {
          text: 'America/Toronto',
          value: 'America/Toronto'
        },
        {
          text: 'UTC (UTC+0)',
          value: 'UTC',
        },
        {
          text: 'Asia/Manila',
          value: 'Asia/Manila'
        }
      ],
    }
  },
  created() {
    // TODO: request timezones
    // code here...
  }
}

</script>

