<script>
import { VForm } from 'vuetify/lib'
import { Mode } from './AppFormDialog'

export default {
  name: 'AppForm',
  extends: VForm,

  props: {
    item: {
      type: Object,
      default: function() {
        return {}
      }
    },

    errorMessages: {
      type: Object,
      default: function () {
        return {}
      }
    },

    mode: Number
  },

  computed: {
    isModeCreate() {
      return this.mode === Mode.CREATE
    },
    isModeView() {
      return this.mode === Mode.VIEW
    },
    isModeEdit() {
      return this.mode === Mode.EDIT
    }
  }
}
</script>

