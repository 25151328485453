<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col>
          <ReferrerTable 
      />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ReferrerTable from './ReferrerTable.vue'

export default {
  name: 'Referral',
  components: { 
    ReferrerTable
  }
}
</script>

