import Role, { key } from '../../api/role'
import { AppString } from '../../strings'
import { Pagination } from '../common.js'

const pagination  = new Pagination(Role)

const state = {
    ...pagination.state,
}

const getters = {
    ...pagination.getters
}

const actions = {
    ...pagination.actions,

    update({ commit, dispatch }, payload) {
        return Role.update(payload[key], payload.params).then(response => {
            if (response.status === 200) {
                commit('UPDATE_ROLE', {
                    [key]: payload[key],
                    role: response.data
                })
                dispatch('notifications/addSuccessNotification', AppString.roleUpdated, { root: true })
            }

            return response
        })
    },

    save({ commit, dispatch }, payload) {
        return Role.create(payload).then(response => {
            
            if (response.status === 201) {
                commit('ADD_ROLE', response.data)
                dispatch('notifications/addSuccessNotification', AppString.roleCreated, { root: true })
            }

            return response
        })
    },

    delete({ commit, dispatch }, role) {
        return Role.delete(role[key]).then(response => {
            if (response.status === 204) {
                commit('DELETE_ROLE', role)
                dispatch('notifications/addSuccessNotification', AppString.roleDeleted, { root: true })
            }

            return response
        })
    },

    view(_, role) {
        // REVIEW: should this have have a VIEW mutation?
        return Role.view(role[key]).then(response => {
            if (response.status === 200) {
                return response.data
            }
        })
    }
}

const mutations = {
    ...pagination.mutations,

    ADD_ROLE(state, role) { 
        state.all.unshift(role) 
        ++state.pagination.itemsLength
    },

    UPDATE_ROLE: (state, payload) => {
        const role = state.all.find(role => 
            role[key].toLowerCase() === payload[key].toLowerCase())

        if (role)
            Object.assign(role, payload.role)
    },

    DELETE_ROLE: (state, role) => {
        const index = state.all.findIndex(item => item === role)

        if (index >= 0)
            state.all.splice(index, 1)
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
