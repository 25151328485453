<template>
  <AppForm
    v-bind="$props"
    v-on="$listeners"
    :readonly="readonly"
  >
    <v-row>
      <v-col>
        <span class="subtitle-2">
        * required
        </span>
      </v-col>
    </v-row>

    <v-row>

      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="item.first_name"
          :error-messages="errorMessages.first_name"
          :readonly="readonly"
          label="First name *"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>

      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="item.last_name"
          :error-messages="errorMessages.last_name"
          :readonly="readonly"
          label="Last name *"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>

    </v-row>

    <v-row>

      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="item.username"
          :error-messages="errorMessages.username"
          :readonly="readonly"
          label="Username *"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>

      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="item.password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :error-messages="errorMessages.password"
          :readonly="readonly"
          label="Password *"
          :type="showPassword ? 'text' : 'password'"
          hide-details="auto"
          :disabled="readonly"
          :persistent-placeholder="true"
          @click:append="toggleShowPassword"
          >
        </v-text-field>
      </v-col>


    </v-row>

    <v-row>
      
     <v-col
        cols="12"
        md="4"
      >
      <v-select
        v-model="item.gender"
        :items="['Male', 'Female', 'Unspecified']"
        :error-messages="errorMessages.gender"
        :readonly="readonly"
        label="Gender"
        hide-details="auto"
        :persistent-placeholder="true"
        >
      </v-select>
    </v-col>

     <v-col
        cols="12"
        md="8"
      >
        <v-text-field
          v-model="item.email"
          :error-messages="errorMessages.email"
          :readonly="readonly"
          label="E-mail *"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>
      </v-col>

    </v-row>

    <v-row>
      <v-col
        cols="6"
      >
        <v-text-field
          v-model="item.phone_number"
          :error-messages="errorMessages.phone_number"
          :readonly="readonly"
          label="Phone Number"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-text-field>

      </v-col>

      <v-col
        cols="6"
      >
        <v-select
          v-model="item.role"
          :items="roles"
          :error-messages="errorMessages.role"
          :readonly="readonly"
          label="Role"
          hide-details="auto"
          :persistent-placeholder="true"
        >
        </v-select>

      </v-col>
    </v-row>

  </AppForm>

</template>

<script>
import AppForm from './AppForm'

export default {
  name: 'UserAccountForm',
  extends: AppForm,
  components: {
    AppForm,
  },

  props: {
    // TODO: this should be already in AppForm
    readonly: {
      type: Boolean,
      default: false
    },

  },

  data() {
    return {
      showPassword: false,
      oldValue: {},
    }
  },

  computed: {
    roles() {
      return this.$store.getters['roles/getAll'].map(role => ({
        text: role.name,
        value: role.name
      }))
    }
  },

  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },

    validate() {
      Object.assign(this.oldValue, this.item)
      return this.validate()
    },
  },

  created() {
    this.$store.dispatch('roles/fetchPagination')
  },

}
</script>
