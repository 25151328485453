import { actions, mapMethods } from './api'

const ENDPOINT = 'loan-eligibles'

export const key = 'profile_id' 

export default {
    ENDPOINT,
    key,
    ...mapMethods(ENDPOINT, [
        'delete',
    ]),
    search(params) {
        return actions(ENDPOINT).search({
            ...params,
            expand: 'profile,user'
        })
    },
    view(id, params) {
        return actions(ENDPOINT).view(id, {
            ...params,
            expand: 'profile,user'
        })
    },
    update(id, params) {
        return actions(ENDPOINT).update(id, {
            ...params,
            expand: 'profile,user'
        })
    },
    create(params) {
        return actions(ENDPOINT).create({
            ...params,
            expand: 'profile,user'
        })
    },
}
