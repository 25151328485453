<!-- TODO: extends AppForm perharps? -->
<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    transition="dialog-top-transition"
    max-width="400"
  >

    <template #activator="slotProps">
      <slot 
        name="activator" 
        :slotProps="slotProps"/>
    </template>

    <v-card>
      <v-toolbar>User Preferences</v-toolbar>

      <v-card-text>
        <v-container>
          <UserSettingsForm
            :item="item"
            :errorMessages="errorMessages"
          />
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>

        <v-btn
          color="primary"
          @click="onSave"
          text
          >
          Save
        </v-btn>

        <v-btn
          color="red"
          @click="onClose"
          text
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserSettingsForm from './user/UserSettingsForm'
import User from '../api/user'
import AppString from '../strings'

export default {
  name: 'TheUserSettingsDialog',
  components: {
    UserSettingsForm
  },

  data() {
    return {
      value: false,
      item: {},
      errorMessages: {}
    }
  },
  watch: {
    'item.darkMode'(value) {
      this.$vuetify.theme.dark = value
      localStorage.setItem('darkMode', value)
    }
  },

  methods: {
    
    close() {
      this.value = false
    },

    onClose(event) {
      this.close()
      this.$emit('input', event.target.value)
    },

    onSave() {
      User.savePreferences(this.item).then(response => {
        if (response.status === 200) {
          this.$store.dispatch('notifications/addSuccessNotification', "User preference saved.")

          // Refresh the page to apply timezone
          setTimeout(() => {
            this.$router.go();
          }, 1000);
        }
      }).catch(error => {
        console.log(error)
        this.$store.dispatch('notifications/addWarningNotification', AppString.serverError)
      })
    },

    loadUserSettings() {
      const darkModeEnabled = JSON.parse(localStorage.getItem('darkMode'))

      if (darkModeEnabled) {
        this.$vuetify.theme.dark = darkModeEnabled
      }
    },
  },

  created() {
    User.getPreferences().then(response => {
      if (response.status === 200) {
        this.item = response.data
      }
    })
  },

  mounted() {
    this.loadUserSettings()
  }
}
</script>

