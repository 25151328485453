<template>
  <AppForm v-bind="$props" v-on="$listeners">
    <v-row>
      <v-col>
        <span class="title">
          {{ hasDownloadables ? "" : "No " }}Submitted Forms</span
        >
      </v-col>
    </v-row>

    <v-row v-if="hasDownloadables">
      <v-col
        v-for="[label, value] in downloadableItems"
        :key="label"
        cols="12"
        sm="6"
      >
        <v-badge
          v-if="value"
          offset-x="228px"
          offset-y="20px"
          tile
          :content="value.split('.').pop()"
        >
          <v-item-group class="v-btn-toggle">
            <v-btn
              @click.stop="show(value)"
              :disabled="!isReadableFile(value)"
              width="180px"
            >
              {{ label }}
            </v-btn>

            <v-btn @click.stop="download(value, label)">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-item-group>
        </v-badge>
      </v-col>

      <v-col>
        <viewer> </viewer>
      </v-col>

      <v-col>
        <v-dialog v-model="showPdfViewer" scrollable max-width="1000px">
          <v-card>
            <v-card-text color="gray">
              <v-container>
                <v-row>
                  <v-col>
                    <vue-pdf-embed :source="source1" />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer />
              <v-btn @click.stop="showPdfViewer = false"> Close </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </AppForm>
</template>

<script>
import AppForm from "../AppForm"
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed"

export default {
  name: "ImmigrationForm",
  extends: AppForm,
  components: {
    AppForm,
    VuePdfEmbed,
  },

  data() {
    return {
      showPdfViewer: false,
      source1: "",
      downloadableItemKeys: {
        workPermit: "IMM 1295",
        familyInfo: "IMM 5707",
        statutoryDeclaration: "IMM 5409",
        useOfRepresentative: "IMM 5476",
        authReleasePersonalInfo: "IMM 5475",
        detailOfEducationEmploymentTravel: "IMM 0104",
        familyInfo5645: "IMM 5645",
      },
    }
  },

  computed: {
    downloadableItems() {
      /* return Object.entries(this.downloadableItemKeys) */
      /*   .filter(([key]) => this.item[key]) */

      const {
        work_permit,
        family_info,
        statutory_declaration,
        use_of_representative,
        auth_release_personal_info,
        detail_of_education_employment_travel,
        family_info_5645,
      } = this.item.profile?.immigration ?? {}

      const data = [
        ["IMM 1295", work_permit],
        ["IMM 5707", family_info],
        ["IMM 5409", statutory_declaration],
        ["IMM 5476", use_of_representative],
        ["IMM 5475", auth_release_personal_info],
        ["IMM 0104", detail_of_education_employment_travel],
        ["IMM 5645", family_info_5645],
      ].filter(([, value]) => value)

      return data
    },

    hasDownloadables() {
      return this.downloadableItems.length > 0
    },
  },

  methods: {
    download(fileUrl, label) {
      console.log(fileUrl, label)
      this.$axios({
        url: fileUrl,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/octet-stream" })
          )
          const extension = fileUrl.split(".").pop()
          const link = document.createElement("a")
          const filename = label + "." + extension

          link.href = url
          link.download = filename

          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addWarningNotification",
            "Server error. Unable to fetch file."
          )
        })
    },
    getExtensionIfNotImage(file) {
      const extension = this.getFileExtension(file)
      return this.isReadableFile(file) ? "" : `(.${extension})`
    },

    getFileExtension(file) {
      return file.split(".").pop()
    },

    isReadableFile(file) {
      const extension = this.getFileExtension(file).toLowerCase()
      const imageExtensions = ["jpeg", "jpg", "png", "gif", "pdf"]

      return imageExtensions.includes(extension)
    },

    show(file) {
      this.$axios
        .get(file, {
          responseType: "blob",
        })
        .then((response) => {
          const extension = file.split(".").pop()

          const validImgExtensions = ["jpg", "jpeg", "png", "gif"]

          if (validImgExtensions.includes(extension.toLowerCase())) {
            const pic = URL.createObjectURL(response.data)
            this.$viewerApi({
              options: {
                focus: false,
              },
              images: [pic],
            })
          } else if (extension.toLowerCase() === "pdf") {
            this.source1 = URL.createObjectURL(response.data)
            this.showPdfViewer = true
          } else {
            console.log(`${extension} cannot be read.`)
          }
        })
        .catch(console.error)
    },
  },
}
</script>
