import { mapMethods } from './api'

const ENDPOINT = 'countries'

export const key = 'id'

export default {
    ...mapMethods(ENDPOINT, [
        'search',
        'delete',
        'update',
        'create',
        'view'
    ])
}
