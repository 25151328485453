<template>
  <v-menu
    :close-on-content-click="false"
    bottom
    left
    offset-y
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
        v-on="on"
        v-bind="attrs"
        fab
        small
      >
        <AppAvatar>
        </AppAvatar>
      </v-btn>
    </template>

    <v-list
      dense>
      <v-list-item-group>
        <v-list-item 
          v-for="(item, i) in items"
          :key="i"
          @click.native="item.action"
          inactive
          >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import AppAvatar from './AppAvatar'
  export default {
    name: 'AccountMenu',

    components: {
        AppAvatar
    },

    data() {
      return {
        items: [
          {
            title: "Settings",
            icon: 'mdi-cog',
            action: this.settings
          },
          {
            title: "Logout",
            icon: 'mdi-logout',
            action: this.logout
          }
        ]
      }
    },
    methods: {
      settings() {
        this.$emit('click:user-settings')
      },
      logout() {
        this.$axios.post('user/logout').then(() => {
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          localStorage.clear()
          this.$router.push({ name: 'Login' })
        })
      }
    },
  }
</script>
