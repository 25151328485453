<template>
  <AppContent
    v-bind="{ ...$props, ...$attrs }"
    v-on="$listeners"
    item-key="name"
    hide-create-button
    :form-dialog-props="{ maxWidth: 790 }"
    :table-props="{
      headers,
      itemActions: ['view'],
    }"
  >
    <template #table="{ attrs, on }">
      <AppTable ref="appTable" v-bind="{ ...attrs, ...$props }" v-on="on">
        <template #append-buttons>
          <v-tooltip bottom v-if="!$props.hideAppendButtons">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="downloadItems"
                color="blue"
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>Csv Download</span>
          </v-tooltip>
        </template>

        <template v-slot:header.submissions="{ header }">
          {{ header.text }}
          <v-menu open-on-hover top offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
            </template>

            <v-card>
              <v-subheader class="text-overline">LEGENDS:</v-subheader>
              <v-list dense subheader disabled>
                <v-list-item :key="index" v-for="(legend, index) in legends">
                  <v-list-item-icon>
                    <v-icon :color="legend.color">{{ legend.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ legend.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>

        <template v-slot:header.profile.status="{ header }">
          {{ header.text }}
          <v-menu open-on-hover top offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
            </template>

            <v-card>
              <v-subheader class="text-subtitle-2">Deny reasons:</v-subheader>
              <v-list dense subheader disabled>
                <v-list-item
                  :key="index"
                  v-for="(legend, index) in statusLegends"
                >
                  <v-list-item-icon>
                    <v-icon :color="legend.color">{{ legend.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ legend.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </template>

        <template v-slot:item.id="{ item }">
          <v-btn
            small
            color="purple"
            dark
            v-if="hasDownloadables(item) && $props.csvDownloadable"
            @click="download(item)"
          >
            <v-icon left>mdi-folder-zip</v-icon>
            {{ item.id }}
          </v-btn>
          <span v-else>
            {{ item.id }}
          </span>
        </template>

        <template v-slot:item.name="{ item }">
          <v-chip small color="primary" @click="on['click:view-item'](item)">
            {{ item.profile.last_name }}, {{ item.profile.first_name }}
          </v-chip>
        </template>

        <template v-slot:item.submissions="{ item }">
          <v-icon v-if="hasLicense(item)" color="teal">{{
            $icons.license
          }}</v-icon>
          <v-icon v-if="hasResume(item)" color="deep-orange">{{
            $icons.resume
          }}</v-icon>
          <v-icon v-if="hasTranscriptOfRecords(item)" color="indigo">{{
            $icons.transcriptOfRecords
          }}</v-icon>
        </template>

        <template v-slot:item.profile.status="{ item, value }">
          <v-chip :color="item.denied_at ? 'orange' : 'light-green'" dark small
            >{{ item.denied_at ? "denied" : value.toLowerCase() }}
          </v-chip>

          <v-icon
            class="ml-1"
            v-if="item.deny_reason"
            :color="getStatusLegendIcon(item.deny_reason).color"
            >{{ getStatusLegendIcon(item.deny_reason).icon }}</v-icon
          >
        </template>

        <template #item.action="slotProps">
          <slot
            name="table.item.action"
            v-bind="{
              ...slotProps,
              on: {
                ...slotProps.on,
                ...tableItemSlotProps.on,
              },
            }"
          >
            <ViewItemAction v-bind="slotProps" v-on="slotProps.on" />

            <ArchiveItemAction
              v-if="canArchiveApplicant"
              v-bind="slotProps"
              v-on="{
                'click:archive-item': onArchiveItem,
              }"
            />

            <UndenyItemAction
              v-if="canArchiveApplicant && slotProps.item.denied_at"
              v-bind="slotProps"
              v-on="{
                'click:undeny-item': onUndenyItem,
              }"
            />

            <DenyItemAction
              v-else
              v-bind="slotProps"
              v-on="{
                'click:deny-item': onDenyItem,
              }"
            />

            <ProgressBackwardItemAction
              v-if="
                canProgressBackwardApplicant &&
                slotProps.item.profile.status.toLowerCase() !==
                  statusProgression[0]
              "
              v-bind="slotProps"
              v-on="tableItemSlotProps.on"
            />

            <ProgressForwardItemAction
              v-if="
                canProgressForwardApplicant &&
                slotProps.item.profile.status.toLowerCase() !==
                  statusProgression[statusProgression.length - 1]
              "
              v-bind="slotProps"
              v-on="tableItemSlotProps.on"
            />
          </slot>
        </template>

        <template #item.category="slotProps">
          <slot
            name="table.item.category"
            v-bind="{
              ...slotProps,
              on: {
                ...slotProps.on,
                ...tableItemSlotProps.on,
              },
            }"
          >
            <v-select
              :value="slotProps.item.profile.opportunity_id"
              :items="categoryList"
              item-text="title"
              solo
              flat
              hide-details
              item-value="id"
              dense
              clearable
              @change="onChangeCategory(slotProps.item, $event)"
            >
            </v-select>
          </slot>
        </template>
      </AppTable>
    </template>

    <!-- TODO: use #formDialog template -->
    <template #formDialog="formDialogSlotProps">
      <AppFormDialog
        v-bind="formDialogSlotProps.attrs"
        v-on="formDialogSlotProps.on"
        max-width="900"
        @update:mode="onAppFormDialogModeChanged"
      >
        <!--<template #title="{ item, text }">
            {{ text }} 

            -

            <v-chip
              color="light-green"
              label
            >
                {{ item?.profile?.first_name }} {{ item?.profile?.last_name }}
            </v-chip>
          </template>-->

        <template #toolbar="slotProps">
          <v-toolbar-title>
            {{ slotProps.title }}

            -

            <v-chip color="light-green" label>
              {{ slotProps.item?.profile?.first_name }}
              {{ slotProps.item?.profile?.last_name }}
            </v-chip>
          </v-toolbar-title>

          <v-spacer></v-spacer>

          <v-tooltip left>
            <template #activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-icon left>mdi-pulse</v-icon>
                <span class="text-overline">{{
                  slotProps.item?.profile?.status.toUpperCase()
                }}</span>
              </div>
            </template>
            <span>Status</span>
          </v-tooltip>
        </template>

        <template #form="slotProps">
          <v-tabs v-model="tab" active-class="white black--text" vertical>
            <v-tooltip
              v-for="tab in tabItems"
              :key="tab.name"
              :disabled="tab?.meta?.disableFn(slotProps.item) ?? true"
              color="orange"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <v-tab
                    @change="onTabChange(tab, slotProps.item)"
                    :disabled="!(tab?.meta?.disableFn(slotProps.item) ?? true)"
                  >
                    <!--<v-badge
                                                      v-if="tab.name.toLowerCase() === 'Job Offers'.toLowerCase()"
                                                      color="green"
                                                      content="1"
                                                      overlay>
                                                  {{ tab.name }}
                                              </v-badge>-->
                    <v-icon left>{{ tab.icon }}</v-icon>
                    <span>
                      {{ tab.name }}
                    </span>
                  </v-tab>
                </div>
              </template>
              <span>
                {{ tab?.meta?.tooltip }}
              </span>
            </v-tooltip>

            <v-tabs-items v-model="tab">
              <v-tab-item v-for="tab in tabItems" :key="tab.name">
                <v-card flat>
                  <v-card-text>
                    <component
                      :is="tab.component"
                      v-bind="{
                        ...slotProps,
                        downloadableItemKeys,
                      }"
                    >
                    </component>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </template>
      </AppFormDialog>
    </template>
  </AppContent>
</template>

<script>
import AppContent from "./AppContent"
import AppTable from "./AppTable"
import ProfileForm from "./applicant/ProfileForm"
import EducationForm from "./applicant/EducationForm"
import IELTSForm from "./applicant/IELTSForm"
import ApplicationForm from "./applicant/ApplicationForm"
import ImmigrationForm from "./applicant/ImmigrationForm"
import InterviewForm from "./applicant/InterviewForm"
import NoteForm from "./applicant/NoteForm"
import JobOfferForm from "./applicant/JobOfferForm"
import ArchiveApplicantMessage from "./applicant/ArchiveApplicantMessage"
import DenyApplicantMessage from "./applicant/DenyApplicantMessage"

import AppFormDialog from "./AppFormDialog"

import {
  ViewItemAction,
  ArchiveItemAction,
  DenyItemAction,
  ProgressForwardItemAction,
  ProgressBackwardItemAction,
  UndenyItemAction,
} from "./base/table"

import lodash from "lodash"
import moment from "moment"

export const DOWNLOADABLE_ITEM_KEYS = {
  resume: "Resume",
  license: "License",
  transcriptOfRecords: "Transcript",
  policeClearance: "Police Clearance",
  medicalClearance: "Medical Clearance",
  vaccinationCard: "Vaccination Card",
  statementOfAccount: "SOA",
  birthCertificate: "Birth Certificate",
  passportPhoto: "Passport Photo",
  visaApplicationPhoto: "Visa Application Photo",
  idPicture: "Id Picture",
  marriageLicense: "Marriage License",
  financialMeans: "Financial Means",
  workPermit: "IMM 1295",
  familyInfo: "IMM 5707",
  statutoryDeclaration: "IMM 5409",
  useOfRepresentative: "IMM 5476",
  authReleasePersonalInfo: "IMM 5475",
  detailOfEducationEmploymentTravel: "IMM 0104",
  familyInfo5645: "IMM 5645",
}

export default {
  name: "Applicants",

  extends: AppContent,

  components: {
    AppContent,
    AppTable,
    ViewItemAction,
    ArchiveItemAction,
    DenyItemAction,
    ProgressForwardItemAction,
    ProgressBackwardItemAction,
    AppFormDialog,
    UndenyItemAction,
  },

  props: {
    hideAppendButtons: {
      type: Boolean,
      default: false,
    },
    csvDownloadable: {
      type: Boolean,
      default: true,
    },
    customSearch: {
      type: String,
      default: "",
    },
    resourcePath: {
      type: String,
      default: "applicants",
    },
    title: {
      type: String,
      default: "All Applicants",
    },
    subject: {
      type: String,
      default: "Applicant",
    },
    headers: {
      type: Array,
      default: function () {
        return [
          {
            text: "Id",
            value: this.$config("applicant.id"),
          },
          {
            text: "Name",
            value: "name",
            customValue: function (item) {
              return `${item.profile.last_name}, ${item.profile.first_name}`
            },
          },
          {
            text: "Email",
            value: "email",
          },
          {
            text: "Date Registered",
            value: "created_at",
            customValue: function (item) {
              return moment.unix(item.created_at).format("MMM. D, YYYY")
            },
          },
          {
            text: "Country",
            value: "profile.address.country",
          },
          {
            text: "Code",
            value: "profile.code",
            align: "center",
          },
          {
            text: "Desired Position",
            value: "profile.application.desired_position",
            align: "center",
          },
          {
            text: "Referrer",
            value: "profile.referrer",
            align: "center",
          },
          {
            text: "Submission(s)",
            value: "submissions",
            align: "center",
            filterable: false,
          },
          {
            text: "Status",
            value: "profile.status",
            align: "center",
          },
          {
            text: "Action",
            value: "action",
            align: "center",
            filterable: false,
          },
          {
            text: "Category",
            value: "category",
            align: "center",
          },
        ]
      },
    },
  },
  watch: {
    search(value) {
      console.log(value)
    },
  },

  data() {
    return {
      categoryList: [],
      downloadableItemKeys: DOWNLOADABLE_ITEM_KEYS,
      tableItemSlotProps: {
        on: {
          "click:progress-forward-item": this.progressForward,
          "click:progress-backward-item": this.progressBackward,
        },
      },
      tab: null,
      legends: [
        {
          text: "License",
          icon: this.$icons.license,
          color: "teal",
        },
        {
          text: "Resume",
          icon: this.$icons.resume,
          color: "deep-orange",
        },
        {
          text: "Transcript of Records",
          icon: this.$icons.transcriptOfRecords,
          color: "indigo",
        },
      ],
      statusLegends: [
        {
          text: "No Response",
          icon: this.$icons.noResponse,
          color: "blue-grey",
        },
        {
          text: "Qualifications",
          icon: this.$icons.qualitfication,
          color: "cyan darken-2",
        },
        {
          text: "Interview Result",
          icon: this.$icons.interviewResult,
          color: "teal accent-4",
        },
      ],
      // TODO: Should this be fetch from the server?
      statusProgression: [
        "new",
        "for initial interview",
        "for final interview",
        "approved",
        "paid and approved",
        "legal process",
      ],
      mode: 0,
    }
  },
  methods: {
    viewItem(data) {
      this.$refs.appTable.onView({
        id: data.registrant_id,
      })

      setTimeout(() => {
        const index = this.tabItems.findIndex((item) => item.name == "Notes")
        const tab = this.tabItems[index]
        this.tab = index
        this.onTabChange(tab, { id: data.registrant_id })
        console.log(this.tab, "tab changed")
      }, 3000)
    },
    //getCategory(id) {
    //  return this.categoryList.find((item) => item.value == id)?.text
    //},
    getStatusLegendIcon(denyReason) {
      if (!denyReason) return

      const legend = this.statusLegends.find(
        (i) => i.text.toLowerCase() === denyReason.toLowerCase()
      )

      return legend
    },

    onAppFormDialogModeChanged(mode) {
      this.mode = mode
      this.tab = 0
    },

    onTabChange(tab, item) {
      if (typeof tab.onChange !== "undefined") {
        tab.onChange(item)
      }
    },

    onArchiveItem(item) {
      const event = "click:archive-item"

      if (event in this.$listeners) {
        this.$emit(event, item)

        return
      }

      this.$store
        .dispatch("prompt/confirm", {
          title: "Archive confirmation",
          component: ArchiveApplicantMessage,
          data: item,
        })
        .then((response) => {
          if (response) {
            this.$store.dispatch(`${this.resourcePath}/archive`, item)
          }
        })
    },

    onDenyItem(item) {
      const event = "click:deny-item"

      if (event in this.$listeners) {
        this.$emit(event, item)

        return
      }

      this.$store
        .dispatch("prompt/confirm", {
          title: "Deny confirmation",
          component: DenyApplicantMessage,
          data: item,
        })
        .then((response) => {
          if (response) {
            this.$store.dispatch(`${this.resourcePath}/deny`, {
              ...item,
              deny_reason: response.denyReason,
            })
          }
        })
    },

    onUndenyItem(item) {
      const event = "click:undeny-item"

      if (event in this.$listeners) {
        this.$emit(event, item)

        return
      }

      this.$store
        .dispatch("prompt/confirm", {
          title: "Restore denied",
          data: item,
        })
        .then((response) => {
          if (response) {
            this.$store.dispatch(`deniedApplicants/restore`, item)
          }
        })
    },

    onChangeCategory(item, event) {
      console.log(item, "-", event)

      this.$store
        .dispatch("prompt/confirm", {
          title: "Update Category",
          data: item,
        })
        .then((response) => {
          if (response) {
            const value = event
            console.log(value, event)
            this.$store.dispatch(`applicants/category`, {
              id: item.id,
              params: {
                category_id: value,
              },
            })
          }
        })
    },

    downloadItems() {
      this.$store
        .dispatch(this.resourcePath + "/download")
        .then((response) => {
          const { data } = response
          const url = window.URL.createObjectURL(data)
          const link = document.createElement("a")

          link.href = url
          link.setAttribute("download", this.subject.toLowerCase() + ".csv")
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    downloadableItems(subject, keys) {
      return keys.filter((key) => subject[key])
    },

    // TODO: should be helper function for $store.applicants or any other models!
    hasDownloadables(item) {
      const application = item?.profile?.application
      const immigration = item?.profile?.immigration

      const downloadables =
        (application &&
          this.downloadableItems(application, [
            "resume",
            "license",
            "transcript_of_record",
          ]).length) +
        (immigration &&
          this.downloadableItems(immigration, [
            "police_clearance",
            "medical_clearance",
            "vaccination_card",
            "statement_of_account",
            "birth_certificate",
            "passport_photo",
            "visa_application_photo",
            "id_picture",
            "marriage_license",
            "financial_means",
          ]).length)

      return downloadables > 0
    },

    download(item) {
      this.$axios({
        url: `all-applicants/download-applicant/${item.id}`,
        method: "GET",
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement("a")
          link.href = url

          const filename =
            lodash.startCase(
              lodash.toLower(
                `${item.profile.first_name} ${item.profile.last_name}`
              )
            ) + ".zip"

          link.setAttribute("download", filename)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          this.$store.dispatch(
            "notifications/addWarningNotification",
            "Server error. Unable to fetch file."
          )
        })
    },
    hasLicense(item) {
      const license = item.profile.application?.license
      return license !== null && typeof license !== "undefined"
    },
    hasResume(item) {
      const resume = item.profile.application?.resume
      return resume !== null && typeof resume !== "undefined"
    },
    hasTranscriptOfRecords(item) {
      const transcriptOfRecords = item.profile.application?.transcript_of_record
      return (
        transcriptOfRecords !== null &&
        typeof transcriptOfRecords !== "undefined"
      )
    },
    progressForward(registrant) {
      let index = this.statusProgression.indexOf(
        registrant.profile.status.toLowerCase()
      )
      const status = this.statusProgression[++index]

      this.$store
        .dispatch("prompt/confirm", {
          title: lodash.startCase(status.toLowerCase()),
          message: `Are you sure you want to progress this applicant to "${status}"?`,
        })
        .then((response) => {
          if (response) {
            this.$store.dispatch(
              `${this.resourcePath}/progressForward`,
              registrant
            )
          }
        })
    },
    progressBackward(registrant) {
      let index = this.statusProgression.indexOf(
        registrant.profile.status.toLowerCase()
      )
      const status = this.statusProgression[--index]

      this.$store
        .dispatch("prompt/confirm", {
          title: lodash.startCase(status.toLowerCase()),
          message: `Are you sure you want to revert this applicant to "${status}"?`,
        })
        .then((response) => {
          if (response) {
            this.$store.dispatch(
              `${this.resourcePath}/progressBackward`,
              registrant
            )
          }
        })
    },
  },
  computed: {
    tabItems() {
      return [
        {
          name: "Personal",
          component: ProfileForm,
        },
        {
          name: "Education",
          component: EducationForm,
        },
        {
          name: "IELTS",
          component: IELTSForm,
        },
        {
          name: "Application",
          component: ApplicationForm,
        },
        {
          name: "Immigration",
          component: ImmigrationForm,
        },
        {
          name: "Interviews",
          component: InterviewForm,
          meta: {
            disableFn: (item) => {
              return item.profile?.status.toLowerCase() !== "new"
            },
            tooltip: "Applicant status must be for interview",
          },
          onChange: ({ profile }) => {
            // REVIEW: Could be improved?. Must request from all-applicants/{id}/interviews.
            this.$store.dispatch("interviews/fetchPagination", {
              "filter[and][][interviewee_id][eq]": profile.id,
            })
          },
        },
        {
          name: "Job Offers",
          component: JobOfferForm,
          meta: {
            disableFn: (item) => {
              return item.profile?.status.toLowerCase() === "approved"
            },
            tooltip: "Applicant status must be approved",
          },
          onChange: ({ profile }) => {
            this.$store.dispatch("jobOffers/fetchPagination", {
              "filter[and][][profile_id][eq]": profile.id,
            })
          },
        },
        {
          name: "Notes",
          component: NoteForm,
          visible: true,
          icon: "mdi-notebook",
          /*
           * @param id registrant id.
           */
          onChange: ({ id }) => {
            this.$store.dispatch("applicantNotes/fetchAll", {
              id,
            })
          },
        },
      ].filter((e) => e.visible ?? true)
    },

    permissions() {
      return JSON.parse(localStorage.getItem("permissions"))
    },
    canArchiveApplicant() {
      return this.permissions?.includes("archiveApplicant")
    },
    canProgressBackwardApplicant() {
      return this.permissions?.includes("progressBackwardApplicant")
    },
    canProgressForwardApplicant() {
      return this.permissions?.includes("progressForwardApplicant")
    },
  },

  created() {
    this.$axios.get("opportunities/all").then((response) => {
      this.categoryList = response.data
    })
  },
}
</script>

<style scoped>
.v-tooltip__content {
  opacity: 1 !important;
}
</style>
