<template>
  <AppForm
    v-bind="$props"
    v-on="$listeners"
  >

    <v-row>

      <v-col
        cols="12"
      >
        <v-text-field
          v-model="item.name"
          :error-messages="errorMessages.name"
          :rules="nameRule"
          :readonly="readonly"
          label="Permission name"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-text-field>

      </v-col>


    </v-row>

    <v-row>
      <v-col
        cols="12"
      >
        <v-textarea
          v-model="item.description"
          :error-messages="errorMessages.description"
          label="Description"
          hide-details="auto"
          :persistent-placeholder="true"
          >
        </v-textarea>
      </v-col>

    </v-row>

  </AppForm>

</template>

<script>
import AppForm from './AppForm'

export default {
  name: 'PermissionForm',
  extends: AppForm,
  components: {
    AppForm
  },

  data() {
    return {
      nameRule: [
        v => !!v || 'Permission name is required',
      ]
    }
  }

}
</script>
