import Interviewer, { key } from '../../api/interviewer'
import { AppString } from '../../strings'
import { Pagination } from '../common.js'

const pagination  = new Pagination(Interviewer)

export const state = {
    ...pagination.state,
}

export const getters = {
    ...pagination.getters
}

export const actions = {
    ...pagination.actions,

    update({ commit, dispatch }, payload) {
        // TODO: add failed notification on all api request
        return Interviewer.update(payload[key], payload.params).then(response => {
            if (response.status === 200) {
                commit('UPDATE_INTERVIEWER', {
                    [key]: payload[key],
                    interviewer: response.data
                })
                dispatch('notifications/addSuccessNotification', AppString.interviewerUpdated, { root: true })
            }

            return response
        })
    },

    save({ commit, dispatch }, payload) {
        return Interviewer.create(payload).then(response => {
            
            if (response.status === 201) {
                commit('ADD_INTERVIEWER', response.data)
                dispatch('notifications/addSuccessNotification', AppString.interviewerCreated, { root: true })
            }

            return response
        })
    },

    delete({ commit, dispatch }, interviewer) {
        return Interviewer.delete(interviewer[key]).then(response => {
            if (response.status === 204) {
                commit('DELETE_INTERVIEWER', interviewer)
                dispatch('notifications/addSuccessNotification', AppString.interviewerDeleted, { root: true })
            }

            return response
        })
    },

    view(_, interviewer) {
        return Interviewer.view(interviewer[key]).then(response => {
            if (response.status === 200) {
                return response.data 
            }
        })
    },

    // REVIEW: is this still used?
    searchByName({ dispatch }, payload) {
        return dispatch('fetchPagination', {
            'filter[or][][name][like]': payload,
            expand: 'profile'
        })
    },

}

export const mutations = {
    ...pagination.mutations,

    ADD_INTERVIEWER(state, interviewer) { state.all.unshift(interviewer) },

    UPDATE_INTERVIEWER(state, payload) {
        const interviewer = state.all.find(interviewer => 
            interviewer[key].toString().toLowerCase() === payload[key].toString().toLowerCase())

        if (interviewer)
            Object.assign(interviewer, payload.interviewer)
    },

    DELETE_INTERVIEWER(state, interviewer) {
        const index = state.all.findIndex(item => item === interviewer)

        if (index >= 0) {
            state.all.splice(index, 1)
            // update pagination items length
            // --state.pagination.itemsLength
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
