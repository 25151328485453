import Api, { actions, mapMethods } from './api'

const ENDPOINT = 'denied-applicants' // TEMP: 

export const key = 'id' 

export default {
    ENDPOINT,
    key,
    ...mapMethods(ENDPOINT, [
        'search',
    ]),
    download(params) {
        return Api.get(`${ENDPOINT}/download`, {
            responseType: 'blob',
            params
        })
    },
    view(id) {
        return actions(ENDPOINT).view(id, {
            expand: 'profile.rating,profile.education,profile.ielts,profile.application,profile.address,profile.immigration'
        
        })
    },
    restore(id) {
        return Api.put(`${ENDPOINT}/${id}/restore`)
    },
}
