<template>

  <v-form
    ref="form"
    :readonly="readonly"
  >

    <v-row>

      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="item.firstName"
          :error-messages="errorMessages.firstName"
          :rules="firstNameRule"
          :readonly="readonly"
          label="First name"
          hide-details="auto"
          >
        </v-text-field>

      </v-col>

      <v-col
        cols="12"
        md="6"
      >

        <v-text-field
          v-model="item.lastName"
          :error-messages="errorMessages.lastName"
          :rules="lastNameRule"
          label="Last name"
          hide-details="auto"
        >
        </v-text-field>

      </v-col>

    </v-row>

    <v-row>

      <v-col
        cols="12"
        md="7"
      >

        <v-text-field
          v-model="item.email"
          :error-messages="errorMessages.email"
          :rules="emailRule"
          label="Email"
          hide-details="auto">
        </v-text-field>

      </v-col>

      <v-col
        cols="12"
        md="5"
      >

        <v-text-field
          v-model="item.phone"
          :error-messages="errorMessages.phoneNumber"
          :rules="phoneNumberRule"
          label="Phone Number"
          hide-details="auto">
        </v-text-field>

      </v-col>

    </v-row>

  </v-form>

</template>

<script>

export default {
  name: 'InterviewerForm',

  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    },

    readonly: {
      type: Boolean,
      default: false
    },

    errorMessages: {
      type: Object,
      default: function () {
        return {}
      }
    },
  },

  data() {
    return {
      oldValue: {},

      firstNameRule: [
        v => !!v || 'First name is required',
        v => /^[a-zA-Z\s]+$/.test(v) || 'Must only contain letters(a-Z) and spaces.'
      ],

      lastNameRule: [
        v => !!v || 'Last name is required',
        v => /^[a-zA-Z\s]+$/.test(v) || 'Must only contain letters(a-Z) and spaces.'
      ],

      emailRule: [
        v => !!v || 'Email is required',
        v => /^.+@.+\..+/.test(v) || 'Email must be valid'
      ],

      phoneNumberRule: [
        v => !!v || 'Phone Number is required',
        // See: https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch04s03.html
        v => /^\+(?:[0-9] ?){6,14}[0-9]$/.test(v) || 'Must be a valid phone number',
      ],
    }
  },

  methods: {
    clearForm() {
      this.resetToDefault()
    },

    resetToDefault() {
      this.$set(this.item, 'id', null)
      this.$set(this.item, 'firstName', '')
      this.$set(this.item, 'lastName', '')
      this.$set(this.item, 'email', '')
      this.$set(this.item, 'phone', '')

      this.$refs.form.resetValidation()
    },

    resetValidation() {
      Object.assign(this.oldValue, {})
      this.$refs.form.resetValidation()
    },

    validate() {
      Object.assign(this.oldValue, this.item)
      return this.$refs.form.validate() 
    },

  },

}
</script>
