<template>
  <v-card>
    <v-toolbar flat>
      <v-btn @click="setToday"> Today </v-btn>

      <v-btn fab text x-small @click="prev">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn fab text x-small @click="next">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>

      <v-toolbar-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on="on" v-bind="attrs">
            <span>{{ calendarTypes[type].label }}</span>
            <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(calendarType, index) in calendarTypes"
            :key="calendarType.key"
            @click="type = index"
          >
            <v-list-item-title>{{ calendarType.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-card-text>
      <v-sheet height="700">
        <v-calendar
          ref="calendar"
          v-model="focus"
          :type="calendarTypes[type].key"
          color="primary"
          :events="meetings"
          :event-color="getEventColor"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        >
        </v-calendar>

        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col>
                  <span
                    ><v-icon>mdi-calendar</v-icon>
                    {{ getMeetingDate(selectedEvent) }}</span
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span>
                    <v-icon>mdi-clock-outline</v-icon>

                    {{ getMeetingDatetimeSpanLabel(selectedEvent) }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div>{{ selectedEvent.description }}</div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text color="secondary" @click="selectedOpen = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment"

export default {
  name: "MeetingsCalendar",
  data() {
    return {
      type: 0,
      selectedOpen: false,
      selectedEvent: {},
      selectedElement: null,
      calendarTypes: [
        {
          key: "month",
          label: "Month",
        },
        {
          key: "day",
          label: "Day",
        },
        {
          key: "week",
          label: "Week",
        },
        {
          key: "4day",
          label: "4 days",
        },
      ],

      focus: "",
      /* events: [], */
      colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange"],
      names: [
        "Meeting",
        "Holiday",
        "PTO",
        "Travel",
        "Test",
        "Training",
        "Conference",
        "Party",
        "Spuddle",
      ],
    }
  },
  watch: {
    focus(value) {
      console.log(value)
    },
  },
  computed: {
    meetings() {
      return this.$store.getters["meetings/getAll"].map((meeting) => {
        const startDatetime = moment(meeting.start_time, moment.ISO_8601)
        const start = startDatetime.toDate()
        const end = startDatetime.add(meeting.duration, "minutes").toDate()
        return {
          description: meeting.topic,
          name: meeting.topic,
          start,
          end,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: true,
        }
      })
    },
    events() {
      return this.$store.getters["interviews/getAll"].map((interview) => {
        const startDatetime = moment(
          interview.start_datetime,
          "YYYY-MM-DD HH:mm:ss"
        )
        const start = startDatetime.toDate()
        const end = startDatetime.add(interview.duration, "minutes").toDate()

        return {
          description: interview.description,
          name: `${interview.type} - ${interview.interviewee.full_name}`,
          start,
          end,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: true,
          interview,
        }
      })
    },
  },
  methods: {
    getMeetingDate(meeting) {
      if (!meeting.start) return
      return moment(meeting.start).format("MMMM DD, YYYY")
    },

    getMeetingDatetimeSpanLabel(meeting) {
      if (!meeting.start) return
      return (
        moment(meeting.start).format("hh:mm A") +
        " - " +
        moment(meeting.end).format("hh:mm A")
      )
    },

    getInterviewDatetimeSpanLabel(interview) {
      const startDatetime = moment(
        interview.start_datetime,
        "YYYY-MM-DD HH:mm:ss"
      )
      const start = startDatetime.format("ddd MMM. D, YYYY hh:mm A")
      const end = startDatetime.add(interview.duration, "minutes")
      return start + " - " + end.format("hh:mm A")
    },
    viewDay({ date }) {
      this.focus = date
      this.type = 1
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ""
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        )
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange({ start, end }) {
      const events = []

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        })
      }

      /* this.events = events */
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
  created() {
    this.$store.dispatch("interviews/fetchPagination")
    this.$store.dispatch("meetings/fetchPagination")
  },
  mounted() {
    this.$refs.calendar.checkChange()
  },
}
</script>
