import ApplicantNote, { key } from '../../api/applicantNote'

export const state = {
    all: []
}

export const getters = {
    getAll: state => state.all
}

export const actions = {
        fetchAll({ commit }, payload) {
            return ApplicantNote.search(payload).then(response => {
                let data = response.data
                commit('SET_ALL', data)

                return response
            }).catch(error => {
                return error
            })
        },
    save({ commit, dispatch }, payload) {
        return ApplicantNote.create(payload).then(response => {
            
            if (response.status === 201) {
                commit('ADD_APPLICANT_NOTE', response.data)
                dispatch('notifications/addSuccessNotification', 'Note added', { root: true })
            }

            return response
        })
    },

    delete({ commit, dispatch }, applicantNote) {
        return ApplicantNote.delete(applicantNote[key]).then(response => {
            if (response.status === 204) {
                commit('DELETE_APPLICANT_NOTE', applicantNote)
                dispatch('notifications/addSuccessNotification', 'Delete note failed', { root: true })
            }

            return response
        })
    }
}

export const mutations = {
    SET_ALL(state, items) {
        state.all = items },
    ADD_APPLICANT_NOTE(state, applicantNote) { state.all.unshift(applicantNote) },
    DELETE_APPLICANT_NOTE(state, applicantNote) {
        const index = state.all.findIndex(item => item === applicantNote)

        if (index >= 0) {
            state.all.splice(index, 1)
            // update pagination items length
            // --state.pagination.itemsLength
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
