import Api, { actions } from './api'

const ENDPOINT = 'payments' // TEMP: 

export const key = 'id' 

export default {
    ENDPOINT,
    key,
    ...actions(ENDPOINT),

    uploadScreenshot(file) {
        const data = new FormData()
        data.append('screenshot', file)

        return Api.post(`${ENDPOINT}/upload-screenshot`, data)
    }
}
