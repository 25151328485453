export const Type = {
    SUCCESS: 'success',
    WARNING: 'warning'
}

export default {
    namespaced: true,

    state: () => ({
        all: []
    }),

    getters: {
        getAllNotifications: state => state.all.filter(notification => notification.show)
    },

    actions: {

        addSuccessNotification({ commit }, message) {
            commit('ADD_NOTIFICATION', {
                message,
                show: true,
                type: Type.SUCCESS,
                color: 'green'
            })
        },

        addWarningNotification({ commit }, message) {
            commit('ADD_NOTIFICATION', {
                message,
                show: true,
                type: Type.WARNING,
                color: 'red'
            })
        },

        addNotification({ commit }, notification) {
            commit('ADD_NOTIFICATION', notification)
        },

        removeNotification: ({ commit }, notification) => {
            commit('REMOVE_NOTIFICATION', notification)
        },

        updateNotification: ({ commit }, payload) => {
            commit('UPDATE_NOTIFICATION', payload)
        }

    },

    mutations: {

        ADD_NOTIFICATION(state, notification) {
            state.all.unshift(notification)
        },

        REMOVE_NOTIFICATION(state, notificationToRemove) {
            state.all = state.all.filter(notification => notification !== notificationToRemove)
        },

        UPDATE_NOTIFICATION(state, payload) {
            const notification = state.all.find(notification => notification === payload.notification)

            Object.assign(notification, payload.value)
        }
    }
}
