var render = function render(){var _vm=this,_c=_vm._self._c;return _c('AppContent',_vm._g(_vm._b({attrs:{"item-key":"name","form-dialog-props":{ maxWidth: 700 },"table-props":{
    headers: _vm.headers,
  }},on:{"error-response":function($event){_vm.errorMessages = $event}},scopedSlots:_vm._u([{key:"table",fn:function({ attrs, on }){return [_c('AppTable',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"item.action",fn:function(slotProps){return [_vm._t("table.item.action",function(){return [_c('ViewItemAction',_vm._g(_vm._b({},'ViewItemAction',slotProps,false),slotProps.on)),(slotProps.item.status != 'approved')?_c('v-btn',{attrs:{"icon":"","plain":""},on:{"click":function($event){return _vm.onApproveLoanRequestItem(slotProps.item)}}},[_vm._t("default",function(){return [_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-check")])]})],2):_vm._e(),(slotProps.item.status != 'denied')?_c('v-btn',{attrs:{"icon":"","plain":""},on:{"click":function($event){return _vm.onDenyLoanRequestItem(slotProps.item)}}},[_vm._t("default",function(){return [_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-cancel")])]})],2):_vm._e(),_c('EditItemAction',_vm._g(_vm._b({},'EditItemAction',slotProps,false),slotProps.on)),_c('DeleteItemAction',_vm._g(_vm._b({},'DeleteItemAction',slotProps,false),slotProps.on))]},null,{
            ...slotProps,
            on: {
              ...slotProps.on,
              ..._vm.tableItemSlotProps.on,
            },
          })]}}],null,true)},'AppTable',{ ...attrs, ..._vm.$props },false),on))]}},{key:"formDialog",fn:function(formDialogSlotProps){return [_c('AppFormDialog',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"form",fn:function(slotProps){return [_c('LoanRequestForm',_vm._b({},'LoanRequestForm',slotProps,false))]}}],null,true)},'AppFormDialog',formDialogSlotProps.attrs,false),formDialogSlotProps.on))]}}])},'AppContent',_vm.$props,false),_vm.$listeners),[_c('AppFormDialog',_vm._b({attrs:{"subject":"Loan Agreement Offer","max-width":"690","hide-clear":""},on:{"close":function($event){_vm.formDialogPropsValue.value = false},"save":_vm.onSaveAgreementOffer},scopedSlots:_vm._u([{key:"form",fn:function(slotProps){return [_c('LoanAgreementForm',_vm._b({},'LoanAgreementForm',slotProps,false))]}}])},'AppFormDialog',_vm.formDialogPropsValue,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }