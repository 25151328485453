import LoanEligible, { key } from '../../api/loanEligible'
import { AppString } from '../../strings'
import { Pagination } from '../common.js'

const pagination  = new Pagination(LoanEligible)

export const state = {
    ...pagination.state,
}

export const getters = {
    ...pagination.getters
}

export const actions = {
    ...pagination.actions,

    update({ commit, dispatch }, payload) {
        // TODO: add failed notification on all api request
        return LoanEligible.update(payload[key], payload.params).then(response => {
            if (response.status === 200) {
                commit('UPDATE_LOAN_ELIGIBLE', {
                    [key]: payload[key],
                    loanEligible: response.data
                })
                dispatch('notifications/addSuccessNotification', AppString.loanRequestUpdated, { root: true })
            }

            return response
        })
    },

    save({ commit, dispatch }, payload) {
        return LoanEligible.create(payload).then(response => {
            
            if (response.status === 201) {
                commit('ADD_LOAN_ELIGIBLE', response.data)
                dispatch('notifications/addSuccessNotification', AppString.loanRequestCreated, { root: true })
            }

            return response
        })
    },

    delete({ commit, dispatch }, loanEligible) {
        return LoanEligible.delete(loanEligible[key]).then(response => {
            if (response.status === 204) {
                commit('DELETE_LOAN_ELIGIBLE', loanEligible)
                dispatch('notifications/addSuccessNotification', AppString.loanRequestDeleted, { root: true })
            }

            return response
        })
    },

    view(_, loanEligible) {
        return LoanEligible.view(loanEligible[key]).then(response => {
            if (response.status === 200) {
                return response.data 
            }
        })
    },

    // REVIEW: is this still used?
    searchByName({ dispatch }, payload) {
        return dispatch('fetchPagination', {
            'filter[or][][name][like]': payload,
            expand: 'profile'
        })
    },

    approve({ commit, dispatch }, loanEligible) {
        return LoanEligible.approve(loanEligible[key]).then(response => {
            if (response.status === 200) {
                commit('CHANGE_STATUS', { loanEligible, status: response.data.status })
                dispatch('notifications/addSuccessNotification', "Loan request approved", { root: true })
            }

            return response
        })
    },

    deny({ commit, dispatch }, loanEligible) {
        return LoanEligible.deny(loanEligible[key]).then(response => {
            if (response.status === 200) {
                commit('CHANGE_STATUS', { loanEligible, status: response.data.status })
                dispatch('notifications/addSuccessNotification', "Loan request denied", { root: true })
            }

            return response
        })
    },
}

export const mutations = {
    ...pagination.mutations,
    CHANGE_STATUS(state, { loanEligible, status }) {
        loanEligible.status = status
    },


    ADD_LOAN_ELIGIBLE(state, loanEligible) { state.all.unshift(loanEligible) },

    UPDATE_LOAN_ELIGIBLE(state, payload) {
        const loanEligible = state.all.find(loanEligible => 
            loanEligible[key].toString().toLowerCase() === payload[key].toString().toLowerCase())

        if (loanEligible)
            Object.assign(loanEligible, payload.loanEligible)
    },

    DELETE_LOAN_ELIGIBLE(state, loanEligible) {
        const index = state.all.findIndex(item => item === loanEligible)

        if (index >= 0) {
            state.all.splice(index, 1)
            // update pagination items length
            // --state.pagination.itemsLength
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
