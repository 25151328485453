import { actions, mapMethods } from './api'

const ENDPOINT = 'interviews' // TEMP: 

export const key = 'id' 

export default {
    ENDPOINT,
    key,
    ...mapMethods(ENDPOINT, [
        'view',
        'delete',
        'update',
        'create'
    ]),
    search(params) {
        return actions(ENDPOINT).search({
            ...params,
            expand: 'interviewee'
        })
    },
}
