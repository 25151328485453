<template>
  <v-time-picker
    :value="value"
    @input="onInput"
    format="ampm"
    ampm-in-title
    full-width
    >
    <v-btn
      color="error"
      @click="onClickCancel"
      text
      >
      <v-icon 
        left>
        {{ $icons.timePickerCancel }}
      </v-icon>
      Cancel
    </v-btn>

    <v-spacer></v-spacer>

    <v-btn
      color="primary"
      @click="onClickOk"
      text
      >
      <v-icon 
        left>
        {{ $icons.timePickerOk }}
      </v-icon>
      Ok
    </v-btn>
  </v-time-picker>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'AppTimePicker',
    props: {
      value: String
    },

    methods: {
      onInput(value) {
        const time = moment(value, 'HH:mm').format('h:mma')
        this.$emit('input', time)
      },

      onClickCancel(event) {
        this.$emit('click:cancel', event)
      },

      onClickOk() {
        this.$emit('click:ok', this.value)
      },
    }
  }
</script>
