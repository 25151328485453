<template>
  <AppContent
    v-bind="$props"
    v-on="$listeners"
    item-key="name"
    :form-dialog-props="{ maxWidth: 700 }"
    :table-props="{ 
      headers,
      }"
    >

    <template #form="slotProps">
      <JobBankForm
        v-bind="slotProps"
      />
    </template>

    <template #table="{ attrs, on }">
        <AppTable
            v-bind="attrs"
            v-on="on"
        >
            <template #item.viewable="{ item }">
                <v-checkbox
                    :input-value="item.viewable"
                    @change="toggleJobViewability(item, $event)"
                    >
                </v-checkbox>
            </template>
        </AppTable>
    </template>

  </AppContent>
</template>

<script>
import AppContent from './AppContent'
import AppTable from './AppTable'
import JobBankForm from './job/JobBankForm'

export default {
  name: 'Jobs',

  components: {
    AppContent,
    AppTable,
    JobBankForm
  },

  props: {
    resourcePath: {
      type: String,
      default: 'jobs'
    },
    title: {
      type: String,
      default: 'Jobs'
    },
    subject: {
      type: String,
      default: 'Job'
    },
    headers: {
      type: Array,
      default: function() {
        return [
          {
            text: 'Id',
            value: 'id',
          },
          {
            text: 'Title',
            value: 'title',
          },
          {
            text: 'Job Bank #',
            value: 'bank_id',
          },
          {
            text: 'Date Posted',
            value: 'posted',
          },
          {
              text: 'Viewable',
              value: 'viewable',
          },
          /* { */
          /*   text: 'Employer', */
          /*   value: 'employer', */
          /*   customValue: function (item) { */
          /*     return `${item.employer.last_name}, ${item.employer.first_name}` */
          /*   }, */
          /* }, */
          {
            text: 'Company',
            value: 'employer.company',
          },
          {
            text: 'Contact #',
            value: 'employer.contact_number',
          },
          /* { */
          /*   text: 'Status', */
          /*   value: 'status', */
          /*   align: 'center' */
          /* }, */
          {
            text: 'Action',
            value: 'action',
            align: 'center',
            sortable: false,
            filterable: false
          }
        ]
      }
    }
  },

data() {
    return {
        selected: []
    }
},

  methods: {
      toggleJobViewability(item, value) {
             this.$store.dispatch('jobs/update', {
                id: item.id,
                params: {
                    viewable: value
                }
             })

          /* this.$store.dispatch('prompt/confirm', { */
          /*   title: `Update viewability`, */
          /*   message: 'Are you sure you want to mark this as viewable?' */
          /* }).then(response => { */
          /*   if (response) { */
          /*   } */
          /*   else { */
          /*       console.log('qwe') */
          /*       /1* this.$store.commit('jobs/VIEWABLE_JOB', { *1/ */
          /*       /1*     job: item, *1/ */
          /*       /1*     value: value ? 0 : 1 *1/ */
          /*       /1* }) *1/ */
          /*   } */
          /* }).catch((error) => { */
          /*     console.log(error) */
          /* }) */
      }
  }


}
</script>
