<template>
  <v-card>
    <v-card-text>
      <v-data-table
        :footer-props="footerOptions"
        :headers="headers"
        :items="items"
        :loading-text="$strings.tableLoadingText"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsTotalCount"
        :show-expand="showExpand"
        @page-count="pageCount = $event"
        single-expand
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Partners</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              label="Search"
              prepend-inner-icon="mdi-magnify"
              solo
              dense
              @change="onSearch"
              hide-details
            >
            </v-text-field>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon plain @click="downloadCsv" v-bind="attrs" v-on="on">
                  <v-icon color="blue">mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download CSV</span>
            </v-tooltip>
          </v-toolbar>
        </template>

        <template v-slot:item.progress="{}">
          <div class="d-flex justify-center">
            <v-tooltip
              color="green"
              v-for="(item, index) in steps"
              :key="index"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  :color="`green lighten-${steps.length - index - 1}`"
                >
                  {{ item.icon }}
                </v-icon>
              </template>
              {{ item.title }}
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item.status="{ item }">
          <v-switch
            v-model="item.status"
            false-value="inactive"
            true-value="active"
            :label="item.status"
            @change="toggleStatus(item)"
          >
          </v-switch>
        </template>

        <template v-slot:item.action="{ item }">
          <div class="d-flex justify-center">
            <!-- <v-btn -->
            <!--   icon -->
            <!--   plain -->
            <!--   @click="view(item)" -->
            <!--   > -->
            <!--   <v-icon color="blue">mdi-file-eye-outline</v-icon> -->
            <!-- </v-btn> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  plain
                  @click="downloadCsv(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="blue">mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download CSV</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td></td>
          <td :colspan="headers.length - 1">
            <v-data-table
              :headers="[
                {
                  text: 'First Name',
                  value: 'profile.first_name',
                },
                {
                  text: 'Last Name',
                  value: 'profile.last_name',
                },
                {
                  text: 'Email',
                  value: 'email',
                },
                {
                  text: 'Email',
                  value: 'email',
                },
                {
                  text: 'Phone',
                  value: 'profile.phone',
                },
                {
                  text: 'Desired Position',
                  value: 'profile.application.desired_position',
                },
              ]"
              :items="item.registrants"
            >
            </v-data-table>
          </td>
        </template>
      </v-data-table>
      <v-pagination v-model="options.page" :length="pageCount"> </v-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ReferrerTable",
  data() {
    return {
      footerOptions: {
        itemsPerPageOptions: [5, 10, 20, 50],
      },
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      pageCount: 0,
      itemsTotalCount: 0,
      loading: false,
      showExpand: true,
      expanded: [],
      headers: [
        {
          text: "First name",
          value: this.$config("referrer.firstName"),
        },
        {
          text: "Last name",
          value: this.$config("referrer.lastName"),
        },
        {
          text: "Email",
          value: this.$config("referrer.email"),
        },
        {
          text: "Code",
          value: this.$config("referrer.code"),
        },
        {
          text: "Country",
          value: this.$config("referrer.country"),
        },
        {
          text: "Other Countries",
          value: "other_countries",
        },
        {
          text: "Referee Count",
          value: "referee_count",
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          align: "center",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          sortable: false,
        },
      ],
      items: [],
      search: "",
    }
  },
  watch: {
    options: {
      handler() {
        this.refreshTable()
      },
      deep: true,
    },
  },
  methods: {
    toggleStatus(item) {
      this.$axios
        .patch(`referrals/${item.id}`, {
          status: item.status,
        })
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.log(error)
        })
    },

    downloadReferral() {},

    downloadCsv(item) {
      console.log(item)
      let data = '"First Name","Last Name","Email","Phone","Desired Position"'
      data += "\n"
      data = item.registrants.reduce((acc, registrant) => {
        const firstName = this.$config("applicant.firstName", registrant)
        const lastName = this.$config("applicant.lastName", registrant)
        const email = this.$config("applicant.email", registrant)
        const phone = this.$config("applicant.phoneNumber", registrant) ?? ""
        const desiredPosition =
          this.$config("applicant.desiredPosition", registrant) ?? ""
        let row = `"${firstName}","${lastName}","${email}","${phone}","${desiredPosition}"`
        return acc + row + "\n"
      }, data)

      const url = window.URL.createObjectURL(
        new Blob([data], { type: "text/csv" })
      )
      const link = document.createElement("a")
      const filename =
        this.getFullname(item).toLowerCase().replaceAll(" ", "_") +
        "_referral.csv"

      link.href = url
      link.setAttribute("download", filename)
      link.click()
    },
    onSearch() {
      this.refreshTable()
    },
    getFullname(item) {
      return (
        this.$config("referrer.firstName", item) +
        " " +
        this.$config("referrer.lastName", item)
      )
    },
    view(item) {
      this.$emit("view", item)
    },
    edit(item) {
      this.$emit("edit", item)
    },
    trash(item) {
      this.$emit("trash", item, this.items)
    },
    refreshTable() {
      this.loading = true

      // TODO: Encapsulate
      const filterables = [
        "first_name",
        "last_name",
        "email",
        "code",
        "country",
      ]

      const filter = this.search
        ? filterables.reduce((str, field) => {
            return `${str}filter[or][][${field}][like]=${this.search}&`
          }, "")
        : ""

      const sort = (this.options.sortDesc[0] ? "" : "-") + this.options.sortBy

      this.$axios
        .get(`referrals?${filter}`, {
          params: {
            expand: "registrants.profile.application",
            sort,
            page: this.options.page,
            "per-page": this.options.itemsPerPage,
          },
        })
        .then(({ data }) => {
          this.items = data.items
          this.options.page = data._meta.currentPage
          this.pageCount = data._meta.pageCount
          //this.itemsPerPage = data._meta.perPage
          this.itemsTotalCount = data._meta.totalCount
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  mounted() {
    this.refreshTable()
  },
}
</script>
