<template>
  <div>
    <div
      class="text-caption text-center"
      >
      <span>Rows per page:</span>

      <v-select
        v-model="options.itemsPerPage"
        class="d-inline-block text-caption mx-6"
        :items="itemsPerPageOptions"
        :disabled="loading"
        hide-details="auto"
        >
      </v-select>

      {{ pageText }}
    </div>

    <v-pagination
      :value="pagination.page"
      :length="pagination.pageCount"
      :disabled="loading"
      :total-visible="totalVisible"
      @input="options.page = $event"
      >
    </v-pagination>
  </div>
</template>

<script>
// TODO: Refactor this garbage code
  export default {
    name: 'AppTableFooter',

    props: {
      loading: Boolean,
      pagination: Object,
      options: Object,
      itemsPerPageOptions: {
        type: Array,
        default: function () {
          return [ 10, 20, 50, 100 ]
        }
      }
    },

    data() {
      return {
        totalVisible: 16
      }
    },

    computed: {
      pageText() {
        //const { 
        //  pageStart,
        //  pageStop,
        //  itemsLength
        //} = this.pagination
        //return  `${pageStart + (itemsLength > 0 ? 1 : 0)}-${pageStop} of ${itemsLength}`
        let { page, itemsPerPage, itemsLength } = this.pagination
        let pageStart = ((page - 1) * itemsPerPage) + (itemsLength > 0 ? 1 : 0)
        let pageStop = (page * itemsPerPage)

        if (pageStop > itemsLength)
          pageStop = itemsLength

        return `${pageStart}-${pageStop} of ${itemsLength}`
      }
    }
  }
</script>

<style scoped>
.v-select {
  width: min-content;
}
.v-select  .v-select__selection--comma {
  text-overflow: unset;
}
</style>
