const defaultState = {
    title: 'Confirmation',
    message: 'Are you sure?',
    show: false,
    resolve: null,
    loading: false,
    icon: '',
    component: null,
    data: null,
}

export default {
    namespaced: true,

    state: {
        ...defaultState
    },

    getters: { 
        show: state => state.show,
        title: state => state.title,
        message: state => state.message,
        icon: state => state.icon,
        loading: state => state.loading,
        component: state => state.component,
        data: state => state.data,
    },

    actions: {
        confirm({ commit }, option) {
            commit('INITIALIZE', option)
            commit('SET_SHOW', true)

            return new Promise((resolve) => {
                commit('SET_RESOLVE', resolve)
            }).finally(() => {
                commit('SET_SHOW', false)
                commit('SET_LOADING', false)
            })
        },

        close({ commit }) {
            commit('SET_SHOW', false)
        },

        agree({ state }, value = true) {
            state.resolve(value)
        },
        
        deny({ state }, value = false) {
            state.resolve(value)
        },
    },

    mutations: {
        INITIALIZE(state, option) {
            state.component = option?.component || defaultState.component
            state.data = option?.data || defaultState.data
            state.title = option?.title || defaultState.title
            state.message = option?.message || defaultState.message
            state.resolve = option?.resolve || defaultState.resolve
            state.loading = option?.loading || defaultState.loading
            state.icon = option?.icon || defaultState.icon
        },

        SET_SHOW: (state, show) => state.show = show,

        SET_RESOLVE: (state, resolve) => state.resolve = resolve,

        SET_LOADING: (state, loading) => state.loading = loading,
    }
}
