import Interview, { key } from '../../api/interview'
import { AppString } from '../../strings'
import { Pagination } from '../common.js'

const pagination  = new Pagination(Interview)

export const state = {
    ...pagination.state,
}

export const getters = {
    ...pagination.getters
}

export const actions = {
    ...pagination.actions,

    update({ commit, dispatch }, payload) {
        // TODO: add failed notification on all api request
        return Interview.update(payload[key], payload.params).then(response => {
            if (response.status === 200) {
                commit('UPDATE_INTERVIEW', {
                    [key]: payload[key],
                    interview: response.data
                })
                dispatch('notifications/addSuccessNotification', AppString.interviewUpdated, { root: true })
            }

            return response
        })
    },

    save({ commit, dispatch }, payload) {
        return Interview.create(payload).then(response => {
            
            if (response.status === 201) {
                commit('ADD_INTERVIEW', response.data)
                dispatch('notifications/addSuccessNotification', AppString.interviewCreated, { root: true })
            }

            return response
        })
    },

    delete({ commit, dispatch }, interview) {
        return Interview.delete(interview[key]).then(response => {
            if (response.status === 204) {
                commit('DELETE_INTERVIEW', interview)
                dispatch('notifications/addSuccessNotification', AppString.interviewDeleted, { root: true })
            }

            return response
        })
    },

    view(_, interview) {
        return Interview.view(interview[key]).then(response => {
            if (response.status === 200) {
                return response.data 
            }
        })
    },
}

export const mutations = {
    ...pagination.mutations,

    ADD_INTERVIEW(state, interview) { state.all.unshift(interview) },

    UPDATE_INTERVIEW(state, payload) {
        const interview = state.all.find(interview => 
            interview[key].toString().toLowerCase() === payload[key].toString().toLowerCase())

        if (interview)
            Object.assign(interview, payload.interview)
    },

    DELETE_INTERVIEW(state, interview) {
        const index = state.all.findIndex(item => item === interview)

        if (index >= 0) {
            state.all.splice(index, 1)
            // update pagination items length
            // --state.pagination.itemsLength
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
