<template>
  <v-navigation-drawer v-model="drawer" app clipped>
    <v-list dense nav>
      <div v-for="item in viewableNavigationItems" :key="item.title">
        <v-list-group v-if="item.children" :prepend-icon="item.icon">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="subItem in item.children"
            :key="subItem.title"
            :to="subItem.to"
            class="pl-4"
          >
            <v-list-item-icon>
              <v-icon>{{ subItem.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <!-- <v-list-item-title>{{ subItem.title }} <span v-if="subItem.count">({{ subItem.count }})</span></v-list-item-title> -->
              <v-list-item-title>
                {{ subItem.title }}
                <v-chip
                  v-if="subItem.count"
                  color="teal"
                  class="white--text"
                  x-small
                  label
                  pill
                >
                  {{ subItem.count }}
                </v-chip>
                <!-- <span class="teal--text text--darken-4">({{ subItem.count }})</span> -->
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-else :to="item.to">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
let refreshTimeout

export default {
  name: "TheNavigationDrawer",
  data() {
    return {
      drawer: true,
      items: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard",
          to: { name: "Dashboard" },
        },
        {
          title: "Account Management",
          icon: "mdi-account-box",
          can: "viewAccountManagement",
          children: [
            {
              title: "User Accounts",
              icon: "mdi-shield-account",
              to: { name: "Users" },
              can: "viewAccountManagement",
            },
            {
              title: "Roles",
              icon: "mdi-account-key",
              to: { name: "Roles" },
              can: "viewRole",
            },
            {
              title: "Permissions",
              icon: "mdi-key-link",
              to: { name: "Permissions" },
              can: "viewPermission",
            },
          ],
        },
        {
          title: "Applicants",
          icon: this.$icons.applicantsPortal,
          can: "viewApplicantModule",
          children: [
            {
              title: "All",
              count: 0,
              icon: this.$icons.all,
              to: { name: "Applicants" },
              can: "viewApplicant",
            },
            {
              title: "New Registrants",
              count: 0,
              icon: this.$icons.registrants,
              to: { name: "Registrants" },
              can: "viewRegistrant",
            },
            {
              title: "Initial Interview",
              count: 0,
              icon: this.$icons.initialInterview,
              to: { name: "InitialInterview" },
              can: "viewInitialInterview",
            },
            {
              title: "Final Interview",
              count: 0,
              icon: this.$icons.finalInterview,
              to: { name: "FinalInterview" },
              can: "viewFinalInterview",
            },
            {
              title: "Approved",
              count: 0,
              icon: this.$icons.approvedApplicants,
              to: { name: "Approved" },
              can: "viewApproved",
            },
            {
              title: "Paid and Approved",
              count: 0,
              icon: "mdi-cash-check",
              to: { name: "Processed" },
              can: "viewProcessed",
            },
            {
              title: "Legal Process",
              count: 0,
              icon: "mdi-briefcase-account",
              to: { name: "LegalProcess" },
              can: "viewLegalProcess",
            },
            {
              title: "Archived",
              count: 0,
              icon: this.$icons.applicantArchive,
              to: { name: "ArchivedApplicants" },
              can: "viewArchivedApplicants",
            },
            {
              title: "Denied",
              count: 0,
              icon: this.$icons.applicantDeny,
              to: { name: "DeniedApplicants" },
              can: "viewArchivedApplicants",
            },
            {
              title: "Domestic",
              count: 0,
              icon: "mdi-leaf-maple",
              to: { name: "DomesticApplicants" },
              can: "viewDomesticApplicants",
            },
          ],
        },
        {
          title: "Interviews",
          icon: "mdi-forum-outline",
          to: { name: "Interview" },
          can: "viewInterview",
        },
        {
          title: "Meetings",
          icon: "mdi-calendar-clock",
          to: { name: "Meetings" },
          can: "viewMeeting",
        },
        {
          title: "Opportunities",
          count: 0,
          icon: "mdi-list-status",
          to: { name: "OpportunitiesApplicants" },
          can: "viewOpportunitiesApplicants",
        },
        {
          title: "Jobs",
          icon: "mdi-office-building",
          to: { name: "Jobs" },
          can: "viewJob",
        },
        {
          title: "Job Offers",
          icon: "mdi-offer",
          to: { name: "JobOffer" },
          can: "viewJob",
        },
        {
          title: "Interviewers",
          icon: "mdi-account-tie",
          to: { name: "Interviewers" },
          can: "viewInterviewer",
        },
        {
          title: "Company",
          icon: "mdi-office-building",
          children: [
            {
              title: "Employers",
              icon: "mdi-handshake",
              to: { name: "Employers" },
              can: "viewEmployer",
            },
            {
              title: "Loan Eligible",
              icon: "mdi-bank",
              to: { name: "Loan Eligible" },
              can: "viewEmployer",
            },
            {
              title: "Loan Requests",
              icon: "mdi-bank",
              to: { name: "Loan Requests" },
              can: "viewEmployer",
            },
            {
              title: "Loan Agreements",
              icon: "mdi-file-sign",
              to: { name: "Loan Agreements" },
              can: "viewEmployer",
            },
          ],
        },
        {
          title: "Queue",
          icon: "mdi-alpha-q-circle",
          to: { name: "Queue" },
          can: "viewQueue",
        },
        {
          title: "Referral",
          icon: "mdi-account-box",
          to: { name: "Referral" },
          can: "viewReferral",
        },
        {
          title: "Payments",
          icon: "mdi-cash",
          to: { name: "Payments" },
          can: "viewPayment",
        },
        /* { */
        /*   title: 'Reports', */
        /*   icon: 'mdi-clipboard-file', */
        /*   to: { name: 'Reports' } */
        /* }, */
      ],
    }
  },

  computed: {
    viewableNavigationItems() {
      const permissions = this.$store.getters["user/data"].permissions

      const items = Object.assign([], this.items)

      if (typeof permissions === "undefined") return items

      return items.filter((item) => {
        if (item.children) {
          item.children = item.children.filter((child) => {
            return permissions.includes(child.can)
          })
        }

        return permissions.includes(item.can) || !item?.can
      })
    },
  },

  methods: {
    toggle() {
      this.drawer = !this.drawer
    },

    findItemWithTitle(items, title) {
      return items.find((item) => {
        return item.title.indexOf(title) >= 0
      })
    },

    fetchCounters() {
      // TODO: Update with server push update & Refactor
      this.$axios
        .get("dashboard/counters")
        .then(({ data }) => {
          const applicantsTab = this.findItemWithTitle(this.items, "Applicants")
          const allTab = this.findItemWithTitle(applicantsTab.children, "All")
          const registrantsTab = this.findItemWithTitle(
            applicantsTab.children,
            "Registrants"
          )
          const interviewTab = this.findItemWithTitle(
            applicantsTab.children,
            "Initial Interview"
          )
          const finalInterviewTab = this.findItemWithTitle(
            applicantsTab.children,
            "Final Interview"
          )
          const approvedTab = this.findItemWithTitle(
            applicantsTab.children,
            "Approved"
          )
          const processedTab = this.findItemWithTitle(
            applicantsTab.children,
            "Paid and Approved"
          )
          const legalProcessTab = this.findItemWithTitle(
            applicantsTab.children,
            "Legal Process"
          )
          const archivedTab = this.findItemWithTitle(
            applicantsTab.children,
            "Archived"
          )
          const deniedTab = this.findItemWithTitle(
            applicantsTab.children,
            "Denied"
          )
          const domesticTab = this.findItemWithTitle(
            applicantsTab.children,
            "Domestic"
          )

          allTab.count = data.all ?? 0
          registrantsTab.count = data.new_registrants ?? 0
          interviewTab.count = data.initial_interviewees ?? 0
          finalInterviewTab.count = data.final_interviewees ?? 0
          approvedTab.count = data.approved ?? 0
          processedTab.count = data.processed ?? 0
          legalProcessTab.count = data.legal_process ?? 0
          archivedTab.count = data.archived ?? 0
          deniedTab.count = data.denied ?? 0
          domesticTab.count = data.domestic ?? 0
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          clearTimeout(refreshTimeout)
          this.refreshCounters()
        })
    },

    refreshCounters() {
      refreshTimeout = setTimeout(this.fetchCounters, 5000)
    },
  },

  mounted() {
    if (process.env.NODE_ENV !== "development") {
      this.refreshCounters()
    }
  },
}
</script>
