<template>
  <v-btn
    v-bind="$props"
    v-on="$listeners"
    icon
    plain
    @click="$emit('click:view-item', $attrs.item)"
    >
    <slot>
    <v-icon color="blue">{{ $icons.view }}</v-icon>
    </slot>
  </v-btn>
</template>

<script>
import { VBtn } from 'vuetify/lib'

export default {
  name: 'ViewItemAction',
  extends: VBtn,
}
</script>

