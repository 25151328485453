import BaseViewItemAction from './ViewItemAction'
import BaseEditItemAction from './EditItemAction'
import BaseDeleteItemAction from './DeleteItemAction'
import BaseProgressForwardItemAction from './ProgressForwardItemAction'
import BaseProgressBackwardItemAction from './ProgressBackwardItemAction'
import BaseArchiveItemAction from './ArchiveItemAction'
import BaseDenyItemAction from './DenyItemAction'
import BaseUnarchiveItemAction from './UnarchiveItemAction'
import BaseUndenyItemAction from './UndenyItemAction'

// TODO: how to make this better?
export const ViewItemAction = BaseViewItemAction
export const EditItemAction = BaseEditItemAction
export const DeleteItemAction = BaseDeleteItemAction
export const ArchiveItemAction = BaseArchiveItemAction
export const DenyItemAction = BaseDenyItemAction
export const UnarchiveItemAction = BaseUnarchiveItemAction
export const UndenyItemAction = BaseUndenyItemAction
export const ProgressForwardItemAction = BaseProgressForwardItemAction
export const ProgressBackwardItemAction = BaseProgressBackwardItemAction
