import Api, { actions, mapMethods } from './api'

const ENDPOINT = 'loan-requests'

export const key = 'id' 

export default {
    ENDPOINT,
    key,
    ...mapMethods(ENDPOINT, [
        'delete',
    ]),

    approve(id) {
        return Api.put(`${ENDPOINT}/${id}/approve`)
    },

    deny(id) {
        return Api.put(`${ENDPOINT}/${id}/deny`)
    },

    search(params) {
        return actions(ENDPOINT).search({
            ...params,
            expand: 'profile'
        })
    },
    view(id, params) {
        return actions(ENDPOINT).view(id, {
            ...params,
            expand: 'profile'
        })
    },
    update(id, params) {
        return actions(ENDPOINT).update(id, {
            ...params,
            expand: 'profile'
        })
    },
    create(params) {
        return actions(ENDPOINT).create({
            ...params,
            expand: 'profile'
        })
    },
}
