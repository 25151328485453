<template>
  <v-app>
    <v-main>
      <v-container 
        class="my-n16"
        fill-height>
        <v-row>
          <v-col>

            <v-sheet 
              elevation="12" 
              class="pa-8 mx-auto"
              min-height="440" 
              max-width="400"
              shaped
              >
              <v-img
                :src="require('./assets/Axima_logo-2048x843.png')"
                height="140"
                class="mb-6"
                transition="slide-y-transition"
                contain
                />

                <div class="mx-8">
                  <v-alert
                    v-model="accountIsValid"
                    border="left"
                    type="error"
                    transition="slide-y-transition"
                    dense
                    >{{ alertMessage }}</v-alert>
                  <v-form
                    @submit.prevent="">
                    <v-text-field
                      v-model="loginParam.username"
                      label="Username"
                      :error="accountIsValid"
                      prepend-inner-icon="mdi-account"
                      autofocus
                      outlined
                      dense
                      required>
                    </v-text-field>

                    <v-text-field
                      v-model="loginParam.password"
                      label="Password"
                      :error="accountIsValid"
                      prepend-inner-icon="mdi-lock"
                      type="password"
                      outlined
                      dense
                      required>
                    </v-text-field>

                    <v-row 
                      align="center" 
                      no-gutters
                      >
                      <!-- <v-col> -->
                      <!--   <v-checkbox -->
                      <!--     v-model="loginParam.rememberMe" -->
                      <!--     label="Remember me" -->
                      <!--     class="float-left" -->
                      <!--     dense -->
                      <!--     ></v-checkbox> -->
                      <!-- </v-col> -->

                      <v-col>
                        <v-btn 
                               :loading="loading"
                               class="primary"
                               type="submit"
                               width="100%"
                               large
                               @click="login">
                          Log In <v-icon>mdi-login</v-icon> 
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-form>
                </div>

            </v-sheet></v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  export default {
    name: 'Login',
    data() {
      return {
        loading: false,
        accountIsValid: false,
        alertMessage: '',
        loginParam: {
          username: '',
          password: '',
          rememberMe: true,
        },
      }
    },
    methods: {
      login() {
        this.loading = true
        this.$axios.post('user/login', this.loginParam).then(({ data }) => {
          localStorage.setItem('accessToken', data.results.access_token)
          localStorage.setItem('expiresAt', data.results.expires_at)
          // TODO: Refactor this
          this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')
          this.$router.push('/home')
        }).catch(error => {
          if (error.response) {
            if (error.response.data.errors) {
              this.alertMessage = 'Username / password is incorrect'
              this.accountIsValid = this.alertMessage ? true : false
            }
          }
        }).finally(() => {
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped>
#app {
  background-color: #296190;
}
</style>
