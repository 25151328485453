<template>
  <AppContent
    v-bind="$props"
    v-on="$listeners"
    item-key="name"
    :form-dialog-props="{ maxWidth: 700 }"
    :table-props="{
      headers,
    }"
    @error-response="errorMessages = $event"
  >
    <template #table="{ attrs, on }">
      <AppTable v-bind="{ ...attrs, ...$props }" v-on="on">
        <template
          #item.action="slotProps"
          v-bind="{
            ...slotProps,
            on: {
              ...slotProps.on,
              ...tableItemSlotProps.on,
            },
          }"
        >
          <slot
            name="table.item.action"
            v-bind="{
              ...slotProps,
              on: {
                ...slotProps.on,
                ...tableItemSlotProps.on,
              },
            }"
          >
            <ViewItemAction v-bind="slotProps" v-on="slotProps.on" />

            <v-btn
              v-if="slotProps.item.status != 'approved'"
              icon
              plain
              @click="onApproveLoanRequestItem(slotProps.item)"
            >
              <slot>
                <v-icon color="purple">mdi-check</v-icon>
              </slot>
            </v-btn>

            <v-btn
              v-if="slotProps.item.status != 'denied'"
              icon
              plain
              @click="onDenyLoanRequestItem(slotProps.item)"
            >
              <slot>
                <v-icon color="purple">mdi-cancel</v-icon>
              </slot>
            </v-btn>

            <EditItemAction v-bind="slotProps" v-on="slotProps.on" />

            <DeleteItemAction v-bind="slotProps" v-on="slotProps.on" />
          </slot>
        </template>
      </AppTable>
    </template>

    <template #formDialog="formDialogSlotProps">
      <AppFormDialog
        v-bind="formDialogSlotProps.attrs"
        v-on="formDialogSlotProps.on"
      >
        <template #form="slotProps">
          <LoanRequestForm v-bind="slotProps"> </LoanRequestForm>
        </template>
      </AppFormDialog>
    </template>

    <AppFormDialog
      subject="Loan Agreement Offer"
      max-width="690"
      hide-clear
      v-bind="formDialogPropsValue"
      @close="formDialogPropsValue.value = false"
      @save="onSaveAgreementOffer"
    >
      <template #form="slotProps">
        <LoanAgreementForm v-bind="slotProps"> </LoanAgreementForm>
      </template>
    </AppFormDialog>
  </AppContent>
</template>

<script>
import AppContent from "./AppContent"
import AppTable from "./AppTable"
import AppFormDialog from "./AppFormDialog"
import LoanRequestForm from "./employer/LoanRequestForm"
import LoanAgreementForm from "./employer/LoanAgreementForm"

import { ViewItemAction, DeleteItemAction, EditItemAction } from "./base/table"

export default {
  name: "LoanRequest",

  components: {
    AppContent,
    AppTable,
    AppFormDialog,
    LoanRequestForm,
    LoanAgreementForm,
    ViewItemAction,
    DeleteItemAction,
    EditItemAction,
  },

  props: {
    resourcePath: {
      type: String,
      default: "loanRequests",
    },
    title: {
      type: String,
      default: "Loan Requests",
    },
    subject: {
      type: String,
      default: "Loan Request",
    },
    headers: {
      type: Array,
      default: function () {
        return [
          {
            text: "Id",
            value: "profile_id",
          },
          {
            text: "Name",
            value: "profile.full_name",
          },
          {
            text: "Amount",
            value: "amount",
          },
          {
            text: "Repayment Option",
            value: "repayment_option",
          },
          {
            text: "Date Requested",
            value: "date_requested",
            align: "center",
          },
          {
            text: "Status",
            value: "status",
            align: "center",
          },
          {
            text: "Action",
            value: "action",
            align: "center",
            sortable: false,
            filterable: false,
          },
        ]
      },
    },
  },

  watch: {
    search(value) {
      console.log(value)
    },
  },

  data() {
    return {
      tableItemSlotProps: {
        on: {},
      },
      formDialogPropsValue: {
        item: {},
        editedItem: null,
        mode: 3,
        value: false,
      },
    }
  },

  computed: {
    permissions() {
      return JSON.parse(localStorage.getItem("permissions"))
    },
  },

  methods: {
    onSaveAgreementOffer(item) {
      this.$store
        .dispatch("prompt/confirm", {
          title: "Loan Approval",
          data: item,
        })
        .then((response) => {
          if (response) {
            item.document_type = "test"
            this.$store.dispatch("loanAgreements/save", item)
          }
        })
    },

    onApproveLoanRequestItem(item) {
      item.currency = "CAD"
      item.amount_proposed = item.amount
      item.amount_approved = item.amount
      item.repayment_option_approved = item.repayment_option
      item.interest = 20
      this.formDialogPropsValue.value = true
      this.formDialogPropsValue.item = JSON.parse(JSON.stringify(item))

      // console.log(item)
      // this.$store
      //   .dispatch("prompt/confirm", {
      //     title: "Loan Approval",
      //     data: item,
      //   })
      //   .then((response) => {
      //     if (response) {
      //       this.$store.dispatch(`${this.resourcePath}/approve`, item)
      //     }
      //   })
    },

    onDenyLoanRequestItem(item) {
      console.log(item)
      this.$store
        .dispatch("prompt/confirm", {
          title: "Loan Request Deny",
          data: item,
        })
        .then((response) => {
          if (response) {
            this.$store.dispatch(`${this.resourcePath}/deny`, item)
          }
        })
    },
  },
}
</script>
